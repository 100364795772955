import React, { useEffect, useRef, useState } from 'react';
import profilering from '../assets/profilering.png';
import face from '../assets/profile.png';
import CloseIcon from '@mui/icons-material/Close';
import redcircle from '../assets/redcircle.png';
import pause from '../assets/Pause.png';
import play from '../assets/play.png';
import stop from '../assets/ion_stop.png';
import wave from '../assets/wave.png';
import useModalStore from 'zustand/zustandstore';
import AudioOverlayToast from 'alerts/recordingscreen/audioOverlayToast';
import { useLocation, useParams } from 'react-router-dom';
import ReactiveWaves from './reactivewaves';

const AudioOverlay = ({ Audiocollecter,mergeaudio }) => {
    const { isOpen, openModal, closeModal, resetModal, setModalValue, recordtime } = useModalStore();
    const mydata = useModalStore();
    const location = useLocation(); 
    const { patientid} = location.state || {};
    const { id, } = useParams(); 
    const [recordingTime, setRecordingTime] = useState("00:00");
    const animationFrameRef = useRef(null);
    const startTimeRef = useRef(null);
    const elapsedTimeRef = useRef(0);
    const [mediaStream, setMediaStream] = useState(null);
    const [showsavedtime, setSavedTime] = useState(false);
    const [persistRecordTime, setPersistRecordTime] = useState('');
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const[isModalOpen,setIsModalOpen]=useState(false)
     const [text,setText]=useState('')
     const[mode,setMode]=useState('cancel')
     const test=useRef(false)
     const patientIdRef = useRef(patientid); 
     const username = localStorage.getItem("user");
  useEffect(() => {
    patientIdRef.current = patientid;    
}, [patientid]);
    // Function to convert mm:ss string to total seconds
    const convertToSeconds = (timeString) => {
        const [minutes, seconds] = timeString.split(':').map(Number);
        return minutes * 60 + seconds;
    };
    const isBackToRecord = sessionStorage.getItem("isbacktorecord"); 
useEffect(()=>{ 
      if(mydata.patId==patientid&&isBackToRecord==="a"){ 
        test.current=false
       setModalValue({ continueSave :true}) 
      handleStop()     
    }   
},[isBackToRecord])

   useEffect(() => {
        if (mydata.isOpen) {
            const handleSuccess = (stream) => {             
                setMediaStream(stream)
                mediaRecorderRef.current = new MediaRecorder(stream);
                          mediaRecorderRef.current.ondataavailable = (event) => {                    
                    if (event.data.size > 0) {
                        audioChunks.current.push(event.data);
                    }
                };
                mediaRecorderRef.current.onstop = () => {
                    const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                    //handlemysave()   
                                                  
                    Audiocollecter(audioBlob,test);
                    audioChunks.current = []; // Clear the chunks after stopping
                };
                          // Start recording
                mediaRecorderRef.current.start();
                           // Set start time based on the existing recording time
            const time=mydata.ispaused?mydata.pausetime:recordtime
                const existingSeconds = convertToSeconds(time);
                elapsedTimeRef.current = existingSeconds; // Set elapsed time from Zustand
                startTimeRef.current = Date.now(); // Initialize start time
                updateRecordingTime();
                if (mydata.ispaused) {
                    setModalValue({ispaused:false})
                    console.log('pausetime',mydata.pausetime)
                    console.log(recordingTime,'recordtimeeee')  
                                
                    setModalValue({overlayispaused:true})//added--
                   handlePause()
                   setModalValue({overlaypausetime:mydata.pausetime}) //added  
                   setPersistRecordTime(mydata.pausetime)
                   setSavedTime(true)
                  
        }

            };
                      navigator.mediaDevices.getUserMedia({ audio: true })
                .then(handleSuccess)
                .catch((error) => console.error('Error accessing audio devices:', error));
        }

        return () => {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
            }
        };
    }, [mydata.isOpen, recordtime]); // Add recordtime to dependencies

 
    const updateRecordingTime = async() => {
        
        const now = Date.now();
        const totalElapsedTime = Math.floor((now - startTimeRef.current + elapsedTimeRef.current * 1000) / 1000);
        const minutes = Math.floor(totalElapsedTime / 60);
        const seconds = totalElapsedTime % 60;
        setRecordingTime(
            `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
        );
        animationFrameRef.current = requestAnimationFrame(updateRecordingTime);
      
    };

    useEffect(() => {
        if (mydata.isRunning) {
            updateRecordingTime();            
        }
        return () => cancelAnimationFrame(animationFrameRef.current);
    }, [mydata.isRunning]);

    // useEffect(() => {
    
    // const timer = setTimeout(() => {
    //    if(mydata.ispaused){
    //     setModalValue({isPaused:false})
   
    //   }
    //   }, 1000); // 1000 milliseconds = 1 second
  
    //   // Cleanup function to clear the timeout when the component unmounts
    //   return () => clearTimeout(timer);

    //   }, []);
        if (!mydata.isOpen) {
        return null;
    }
    const handlePause = (e) => { 
        setModalValue({ isRunning: false });
        if (mediaRecorderRef.current) {
                 mediaRecorderRef.current.pause();
            setModalValue({overlayispaused:true})
            setModalValue({pausetime:recordingTime})
       
            // Update elapsed time
            elapsedTimeRef.current += Math.floor((Date.now() - startTimeRef.current) / 1000);
                     setModalValue({overlaypausetime:recordingTime})//pausefunction
            setPersistRecordTime(recordingTime);
            setSavedTime(true);
            cancelAnimationFrame(animationFrameRef.current);
        }
    };

    const handlePlay = (e) => {      
        setModalValue({ isRunning: true });
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.resume();
            // Adjust start time to account for elapsed time
            startTimeRef.current = Date.now(); // Set current time
            setSavedTime(false);
            updateRecordingTime(); // Start updating time
        }
    };

    const handleStop = (e) => {        
        setModalValue({ isCancel: false})
        if (mediaRecorderRef.current) {
            setModalValue({recordtime:recordingTime})  
            mediaRecorderRef.current.stop();
            cancelAnimationFrame(animationFrameRef.current);
            elapsedTimeRef.current = 0; 
        }
        setModalValue({ isRunning: true,isOpen:false });
        // resetModal();
    };
    const handleClose = (e) => {
        handlePause()
    setText('Recording in progress would you like to save before closing?')
      setIsModalOpen(true)
        // setModalValue({ isCancel: true})
        // setModalValue({ isRunning: true, isOpen: false });
    };
const handleModal=(action)=>{
    setMode('cancel')
    if(action==='close'){
    sessionStorage.removeItem('audioBlob');
    setModalValue({ isCancel: true})
    setModalValue({ isRunning: true, isOpen: false });
    }
    if(action=='save'){         
    setModalValue({ continueSave :false})  
       handleStop()
   
    }
}

const SaveAudioFile=()=>{
    test.current=true
    setModalValue({ continueSave :false})       
      handleStop();      
}




const handleStopRecord=()=>{
if(mydata.isRunning){
     handlePause()
}       
    setMode('save')
    setText('Your recording has stopped would you like to save or delete?')
    setIsModalOpen(true) 
}

    return (
        <>
        <div className="absolute left-0 right-0 top-[70px] mt-4 w-full flex justify-center md:top-0 md:left-[30%] md:right-0 md:mt-4 lg:w-[70%] xl:w-[50%]" style={{ zIndex: 99999999 }}>
            <div style={{ borderRadius: "4px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }} className="bg-white rounded-[15px] p-4 h-[53px] flex items-center justify-between w-full">
                {/* Profile icon section */}
                <div className='flex items-center gap-4'>
                    <div className="relative">
                        <img src={profilering} alt="Profile Ring" className="w-[44px] h-[45px]" />
                        <img src={face} alt="Face" className="absolute inset-0 w-[31px] h-[43px] m-auto rounded-full" />
                    </div>
                    <div className='font-semibold text-black text-sm'>
                       {mydata.patientname||''}
                    </div>
                </div>

                {/* Audio section */}
                <div className='flex gap-4 items-center'>
                    {mydata.isRunning ? (
                        <div className="relative cursor-pointer" onClick={
                            ()=>{
                                setModalValue({  overlayispaused: true })
                              handlePause() 
                            }
                           }>
                            <img src={redcircle} alt="Red Circle" className="w-[35px] h-[35px] cursor-pointer" />
                            <img src={pause} alt="Pause" className="absolute inset-0 m-auto w-[20px] h-[20px]" />
                        </div>
                    ) : (
                        <div className="relative cursor-pointer" onClick={
                            ()=>{
                                setModalValue({  overlayispaused: false })
                               handlePlay()
                            }
                            
                            }>
                            <img src={redcircle} alt="Red Circle" className="w-[35px] h-[35px] cursor-pointer" />
                            <img src={play} alt="Play" className="absolute inset-0 m-auto w-[20px] h-[20px]" />
                        </div>
                    )}

                    <div className='flex flex-col items-center h-[90%] justify-between '>
                        {/* <img src={wave} alt="Red wave" className="w-[20px] h-[20px] cursor-pointer" /> */}
                       {/* {mydata.isRunning? <div className='h-4 overflow-hidden'>
                        
                        <ReactiveWaves isPaused={mydata.isRunning}  mediaStream={mediaStream}/></div>:
                        <img src={wave} alt="Red wave" className="w-[24px] h-[25px]  cursor-pointer" /> 
                       } */}
                      <div className='h-4 overflow-hidden'> <ReactiveWaves isPaused={mydata.isRunning}  mediaStream={mediaStream}/></div>
                        <div
                        style={{fontStyle:"normal",fontFamily:'inter'}}
                        className='bg-#1C2A3A h-3 '>{recordingTime.length !== 5 ? "00:00" : (showsavedtime ? persistRecordTime : recordingTime)}
                        
                        </div>
                    </div>

                    <div className="relative cursor-pointer" onClick={handleStopRecord}>
                        <img src={redcircle} alt="Red Circle" className="w-[35px] h-[35px] cursor-pointer" />
                        <img src={stop} alt="Stop" className="absolute inset-0 m-auto w-[20px] h-[20px]" />
                    </div>
                </div>

                {/* Close icon */}
                <div onClick={handleClose}>
                    <CloseIcon style={{ color: "#7B7575" }} />
                </div>
            </div>
        </div>
       <AudioOverlayToast  SaveAudioFile={SaveAudioFile} mode={mode} text ={text}isModalOpen={isModalOpen} setModalOpen={setIsModalOpen} handleModal={handleModal}/>
        </>
    );
};

export default AudioOverlay;
