// Required imports
import React, { useState, useRef } from "react";

const VoiceRecordingStream = () => {
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const [isRecording, setIsRecording] = useState(false);
  const audioStreamRef = useRef(null);
  const wsRef = useRef(null);
  const token = sessionStorage.getItem('auth'); 
  const startRecording = async () => {
    try {
      const filename = crypto.randomUUID(); // Generate a unique filename
      const practiceid = "9928"; // Hardcoded practice ID
      const departmentid = "1"; // Hardcoded department ID
      const patientid = "2336"; // Hardcoded patient ID
      const appointmentid = "576131"; // Optional, hardcoded appointment ID
      const notes = "Sample notes"; // Optional, hardcoded notes
      const tags = "Sample tags"; // Optional, hardcoded tags
      const patientname = "Test Patient"; // Optional, hardcoded patient name
      
      const ws = new WebSocket(
        `wss://api.prd.brainymed.com/bs/v1/recording/streaming?token=${token}&filename=${filename}&practiceid=${practiceid}&departmentid=${departmentid}&patientid=${patientid}&appointmentid=${appointmentid}&notes=${encodeURIComponent(
          notes
        )}&tags=${encodeURIComponent(tags)}&patientname=${encodeURIComponent(patientname)}`,
        ["access_token", token]
      );
      

      ws.onopen = () => {
        setConnectionStatus("Connected");
        console.log("WebSocket connection established.");
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        setConnectionStatus("Error");
      };

      ws.onclose = () => {
        setConnectionStatus("Disconnected");
        console.log("WebSocket connection closed.");
      };

      wsRef.current = ws;

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStreamRef.current = stream;

      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const processor = audioContext.createScriptProcessor(4096, 1, 1);

      source.connect(processor);
      processor.connect(audioContext.destination);

      processor.onaudioprocess = (event) => {
        if (ws.readyState === WebSocket.OPEN) {
          const audioData = event.inputBuffer.getChannelData(0);
          const float32ToInt16 = (float32Array) => {
            const int16Array = new Int16Array(float32Array.length);
            for (let i = 0; i < float32Array.length; i++) {
              int16Array[i] = Math.min(1, float32Array[i]) * 0x7fff;
            }
            return int16Array;
          };
          ws.send(float32ToInt16(audioData).buffer);
        }
      };

      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
      setConnectionStatus("Error");
    }
  };

  const stopRecording = () => {
    if (audioStreamRef.current) {
      const tracks = audioStreamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      audioStreamRef.current = null;
    }

    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }

    setIsRecording(false);
    setConnectionStatus("Disconnected");
  };

  return (
    <div>
      <h1>Voice Recording Stream</h1>
      <p>Connection Status: {connectionStatus}</p>

      <div>
      {isRecording ? (
  <button 
    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200" 
    onClick={stopRecording}
  >
    Stop Recording
  </button>
) : (
  <button 
    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200" 
    onClick={startRecording}
  >
    Start Recording
  </button>
)}

      </div>
    </div>
  );
};

export default VoiceRecordingStream;
