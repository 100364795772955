import React, { useState } from "react";
import { Backdrop, Card, CardContent, CircularProgress } from "@mui/material";
import blinkscribelogo from "../../../assets/blinkscribe 4.svg";
import Select from "react-select";
import Flag from "react-world-flags";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../api/api";
import arrowleft from '../../../assets/arrowleft.png';

const countries = [
  { code: "US", prefix: "+1", label: "United States", value: "US", minLength: 10 },
  { code: "IN", prefix: "+91", label: "India", value: "IN", minLength: 10 },
  { code: "GB", prefix: "+44", label: "United Kingdom", value: "GB", minLength: 10 },
  { code: "DE", prefix: "+49", label: "Germany", value: "DE", minLength: 11 },
  { code: "FR", prefix: "+33", label: "France", value: "FR", minLength: 9 },
];

const ForgotPassword = () => {
  const [selectedCountry, setSelectedCountry] = useState(countries.find((country) => country.value === "US"));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();
  const {nav} = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => {
    if (error) {
      setError('');
    }
    const value = e.target.value;
    const onlyNumbers = value.replace(/\D/g, ""); // Replace non-numeric characters
    setPhoneNumber(onlyNumbers);
  };

  const handleSubmit = async () => {
    const fullNumber = selectedCountry.prefix + phoneNumber;

    if (phoneNumber.length < selectedCountry.minLength) {
      setError(`Phone number must be at least ${selectedCountry.minLength} digits long.`);
      return;
    }

    setError('');
    console.log('Full Phone Number:', fullNumber);

    setIsLoading(true);

    try {
      const response = await api.post('/v1/auth/otp/forgot_password', {
        "mobilephonenumber":phoneNumber,
        "country_code": selectedCountry.prefix
      });
      if(response.status==200){
        navigate("/verify", { state: { phoneNumber: phoneNumber, country_code :selectedCountry.prefix,orignfrom:"forgotpassword",nav:nav } });

        // navigate("/updatepassword", { state: { phoneNumber: phoneNumber, country_code :selectedCountry.prefix,orignfrom:"forgot-password" } });


      }else if(response.status==201){
        setError(response.message)
      }
   
      // navigate("/next-step");
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      fontSize: "8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
  };

  const formatOptionLabel = ({ code, label }) => (
    <div className="flex items-center">
      <Flag code={code} className="mr-2" style={{ width: "20px", height: "15px" }} />
      <span>{label}</span>
    </div>
  );

  return (
    <>
      <div className="flex justify-around items-center min-h-screen h-auto p-0 md:p-6 sm:p-6">
        <Card className="w-full bg-white sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-0 md:p-6 sm:p-6 shadow-none sm:shadow-lg md:shadow-lg md:bg-white sm:bg-white h-screen sm:h-[500px] md:h-[600px] sm:rounded-lg md:rounded-lg">
          <CardContent className="h-full p-6">
            <div className="flex items-start">
              <img
                onClick={() => navigate("/auth1")}
                src={arrowleft}
                alt="Back Arrow"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <div className="flex flex-col items-center justify-around h-full">
              <div className="flex justify-center mt-10">
                <img
                  src={blinkscribelogo}
                  alt="BlinkScribe Logo"
                  className="h-24 sm:h-16 md:h-16 w-30 sm:w-auto md:w-auto"
                />
              </div>
              <div>
                <div className="font-semibold text-[#1c2a3a] text-2xl md:text-xl sm:text-xl tracking-[0] leading-9">
                  Forgot password
                </div>
                <div className="text-center w-full max-w-md mx-auto text-gray-500 text-sm leading-5">
                  Enter your phone number so we can send you a One Time Password for login.
                </div>
              </div>

              <div className="border border-gray-300 flex w-[90%] md:w-[60%] rounded-lg p-1 gap-2">
                <div className="w-[80px]">
                  <Select
                    value={selectedCountry}
                    onChange={setSelectedCountry}
                    options={countries}
                    styles={customStyles}
                    formatOptionLabel={formatOptionLabel}
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Enter Phone Number"
                    className="w-full h-[42px] bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                </div>
              </div>

              {/* Error Message */}
              {error && (
                <div className="text-red-500 text-sm mt-2">{error}</div>
              )}

              {/* Buttons */}
              <div className="flex flex-col items-center space-y-4 w-full mb-24">
                <button
                  className="flex w-[90%] md:w-[60%] h-15 sm:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out"
                  onClick={handleSubmit}
                >
                  <div className="font-semibold text-base">Generate One Time Password</div>
                </button>
              </div>

              {/* <div>
                <div className="font-semibold text-base text-center cursor-pointer text-gray-800">
                  Need Help?
                </div>
              </div> */}
            </div>
          </CardContent>
        </Card>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ForgotPassword;
