import { formatCurrentDate } from "utils/date";
import arrowleft from '../../assets/arrowleft.png';
import profilering from '../../assets/profilering.png';
import face from '../../assets/profile.png';
import { useEffect, useRef, useState } from "react";
import InitialRecording from "./initialrecording";
import RecordingPlayer from "./RecordingPlayer";
import axios from "axios";
import { Backdrop, CircularProgress } from '@mui/material';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import api from "./../../api/api";
import { saveAudioToIndexedDB } from "utils/indexdb";
import { deleteAudioFromIndexedDB } from "utils/indexdb";
import ToastModal from "alerts/recordingscreen/toastmodal";
import MainHeader from "header/header";
import DangerousIcon from '@mui/icons-material/Dangerous';
import SaveHandleToastModal from "alerts/recordingscreen/savehandletoast";
import { getAudioFromIndexedDB } from "utils/indexdb";
import DeleteToastModal from "alerts/recordingscreen/deletetoast";
import CombineToastModal from "alerts/recordingscreen/combineToast";
import useModalStore from "zustand/zustandstore";
const RecordingWrap = () => {
   
  const { isOpen, openModal, closeModal,setModalValue } = useModalStore();
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const[audiofile,setaudioFile]=useState('')
  const[previoustime,setPreviousTime]=useState('')
  const [isback,setIsback]=useState(false)
  const[audiouploadFailed,setAudioUploadFailed]=useState(false)
  const[pagedata,setPageData]=useState({})
  const[rowid,setRowid]=useState('')
  const childRef = useRef(null);
  const restrictSave=useRef(false)
  const showdefault=useRef(false)
  const[myfile,setMyfile]=useState()
  const[toastAlert,setToastAlert]=useState(false)
const[appointments,setAppointments]=useState([])
const[selectedapp,setSelectedApp]=useState({})
const [combineaudio,setCombineAudio]=useState(false)
const[myimgurl,setMyimgUrl]=useState('')
const mydata = useModalStore();
  const [toastobject,setToastObject]=useState({
    icon:DangerousIcon,
    text1:"Something Went Wrong",
    text2:""

  })
  const { id } = useParams(); 
 const navigate =useNavigate()
 const location=useLocation()
 const { patientid} = location.state || {};
  useEffect(()=>{
if(isRecording &&(!showdefault.current)){
  setModalValue({ resrictNavgations:true })
}else{
 setModalValue({ resrictNavgations:false })
}
  },[showdefault.current,isRecording])

 function checkImage(url) {
  const img = new Image();
  img.onload = function() {
     
      setMyimgUrl(true)
  };
  img.onerror = function() {
      console.log("Failed to load image");
      setMyimgUrl(false)
  };
  img.src = url;
}
const retrieveAudioBlob = () => {
  const base64String = sessionStorage.getItem('audioBlob');
 if (base64String) {
   // Extract the base64 data from the string
   const byteString = atob(base64String.split(',')[1]);      
   // Get the MIME type from the base64 string
   const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];

   // Create a Uint8Array to hold the binary data
   const ab = new Uint8Array(byteString.length);
   for (let i = 0; i < byteString.length; i++) {
     ab[i] = byteString.charCodeAt(i);
   }

   // Create a Blob from the Uint8Array
   const audioBlob = new Blob([ab], { type: mimeString });


   return audioBlob;
 } else {
   console.log('No audio Blob found in session storage.');
   return null;
 }
}
 useEffect(() => {
  const handleBeforeUnload = (event) => {
    // Set a message to prompt the user
    const message = "Are you sure you want to leave? Changes you made may not be saved.";
    
    // For most browsers, setting event.returnValue is sufficient
    event.preventDefault();
    event.returnValue = message; // This triggers the prompt

    // Return the message for compatibility with older browsers
    return message;
  };

  // Attach the event listener
  window.addEventListener('beforeunload', handleBeforeUnload);

  // Clean up the event listener
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);
const FetchRecorderData= async()=>{
  if(!id){
    navigate('/landing')
    return
  }
  try {
    setLoading(true)
    const response = await api.get(`/v1/appointments/get/${id}`)  
    let appointmentsdata =await api.post(`/v1/patient/${response?.data?.patientid}/recording/details`,{
      "filter_array": {
    
        "date_filter": {
         
        }
      },
     
    })  
    console.log(appointmentsdata.status,'appou')
    if(appointmentsdata.status==200){
      console.log('setvalue',appointmentsdata.data)
setAppointments(appointmentsdata?.data,'dataaaa')
    }else{
      setAppointments([])
    }
    
    if(response.status==200){
      setPageData(response?.data)
      checkImage(response?.data?.profile_photo) 
      return [response?.data,appointmentsdata?.data]   
    }
  } catch (error) {
    console.log(error)
    setToastObject({
      icon:DangerousIcon,
      text1:"Something Went Wrong",
      text2:"Refresh The Screen",
      from:""    
    })
    setToastAlert(true)
  }finally{
setLoading(false)

  }
}
useEffect(()=>{
FetchRecorderData()
},[])

const getAudio =async()=>{
  const audioBlob = await getAudioFromIndexedDB(`audio-${id}`);
  if(audioBlob){
    setAudioUploadFailed(true)
  } 
}
useEffect(()=>{
getAudio()
},[])
const continueSubmit= async(audio, state)=>{
  setLoading(true);  
  const audioId = `audio-${id}`; 
  // await saveAudioToIndexedDB(audioId, audio);
  try {  
   const data= await FetchRecorderData()
    const formData = new FormData();
    formData.append("file", audio);
    formData.append("departmentid",data[0]?.departmentid);
    formData.append("patientid", data[0]?.patientid);
    formData.append("appointmentid", id);
    formData.append("notes", "testing data");
    formData.append("practiceid", data[0]?.practiceid);

    const response = await api.post('v1/ehr/recording/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        
      },
    });

  if(response.status==200){
    setLoading(false);
    setToastObject({
      icon:CheckCircleIcon,
      text1:"Your Recording is Successfully Completed!",
      text2:"",
      from:"save"
    })
    setToastAlert(true)
  // If API call is successful, delete the audio from IndexedDB
 // await deleteAudioFromIndexedDB(audioId);
    }  
  } catch (error) {
    console.error('Error uploading audio:', error);
    setAudioUploadFailed(true)
  } finally {
    sessionStorage.removeItem('audioBlob');
    setLoading(false);
  }
}

const submitAudio = async (audiofile, state) => {  
if(mydata.isOpen){
  return
}
  if(isRecording&&(!restrictSave.current)){
       return
  } 

  const audioBlob = sessionStorage.getItem('audioBlob');
  let audio=audiofile
  if (audioBlob !== null) {
 const combinedAudioBlob = new Blob([retrieveAudioBlob(), audio],{ type: 'audio/webm' });
    audio=combinedAudioBlob
  } 
  setMyfile(audio)
  const response =await FetchRecorderData()

  if(response[1] && Array.isArray(response[1]) &&response[1].length){    
    
      const currentappontments=response[1].filter((x)=>x.appointmentid==id) 

      if(!currentappontments.length){
        continueSubmit(audio,state)
        return 
      }
      const getLatestAppointment = (appointments) => {
        return appointments.reduce((latest, appointment) => {
            return new Date(appointment.ts) > new Date(latest.ts) ? appointment : latest;
        });
    };
    const latestAppointment = getLatestAppointment(currentappontments);
    const appointmentDate = new Date(latestAppointment.ts);
    const today = new Date();
    
    // Reset the time part for comparison
    appointmentDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // if (appointmentDate.getTime() === today.getTime()) {
     if (true) {
      setSelectedApp(latestAppointment);
      setCombineAudio(true);
  } else {
    continueSubmit(audio,state)
  }
// setCombineAudio(true)
showdefault.current=true
  }else{
  continueSubmit(audio,state)
  showdefault.current=true
};
}
const reuploadAudio=(file)=>{
  submitAudio(file)
}

const handlecombine= async(toggle)=>{

if(toggle=='new'){
  continueSubmit(myfile)
  setCombineAudio(false)
}else{
  try {
    setLoading(true)
    const formData = new FormData();
    formData.append("file", myfile);
    formData.append("departmentid", pagedata?.departmentid);
    formData.append("category", "combine");
    formData.append("patientname", selectedapp?.patient_name);
    formData.append("patientid", pagedata?.patientid);
    formData.append("appointmentid", id);
    formData.append("notes", "testing data");
    formData.append("practiceid", pagedata?.practiceid);
    formData.append("filename", selectedapp?.audiolink);
    formData.append("rowid", selectedapp?.id);
    formData.append("tags", 'tagsdata');    
    const response = await api.post('v1/ehr/recording/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  
    if (response.status == 200) {     
      setToastObject({
        icon:CheckCircleIcon,
        text1:"Your Recording is Successfully Completed!",
        text2:"",
        from:"save"
      })
      setToastAlert(true)
    
    }
   
    
  } catch (error) {
    console.error('Error uploading audio:', error);
  }finally{ 
    sessionStorage.removeItem('audioBlob');
    setLoading(false);
    setCombineAudio(false)
  }
}
}
useEffect(()=>{
if(mydata.triggerHome){
  handleback()
  setModalValue({ triggerHome:false })
}
},[mydata])



const handleback=async()=>{

if(isRecording){ 
  //setIsRecording(false)
 await childRef.current.stopRecording();

if(appointments.length){  
const currentappontments=appointments.filter((x)=>x.appointmentid==id) 

  if(!currentappontments.length){
    setModalValue({ isCombine:false })
    // return 
  }else{
    const getLatestAppointment = (appointments) => {
      return appointments.reduce((latest, appointment) => {
          return new Date(appointment.ts) > new Date(latest.ts) ? appointment : latest;
      });
  };
  const latestAppointment = getLatestAppointment(currentappontments);


  const appointmentDate = new Date(latestAppointment.ts);
  const today = new Date(); //22
  appointmentDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);  
  
  // if (appointmentDate.getTime() === today.getTime()) { //21  6.30 === 22  
  if (true) { //21  6.30 === 22  
    setModalValue({ isCombine:true })
    setModalValue({ 
      filename:latestAppointment.audiolink,
      rId:latestAppointment.id,
      patName:latestAppointment.patient_name,
  
    });
  
  } else {

    setModalValue({ isCombine:false })
  }
  }
}else{
  setModalValue({ isCombine:false })
};
  setModalValue({
    type: 'new',
    depId:pagedata?.departmentid,
    patId: pagedata?.patientid,
    appId: id,
    notes: "testing data",
    practId: pagedata?.practiceid,
    tags: 'tagsdata',
    patientname:pagedata?.patient_name,
    });
openModal()
sessionStorage.setItem("isbacktorecord", 'b');
navigate('/landing')
}else{
   navigate('/landing')
} 
}

useEffect(() => {
  const isBackToRecord = sessionStorage.getItem("isbacktorecord");
  const audioBlob = sessionStorage.getItem('audioBlob');
  if (isBackToRecord === "a"&&audioBlob!==null) {  
    setPreviousTime(mydata.recordtime)
     setIsRecording(true);  
  }else{
   
  }
}, [mydata.recordtime]);

  return (
    <>

      <div className="mt-2 flex flex-col gap-8 items-start p-2 px-5 sm:px-20 ">
        <div className="flex flex-col items-start">
          <div className="text-base [font-family:'Poppins-Medium',Helvetica] font-normal text-black tracking-[0] leading-[normal] whitespace-nowrap">
            {formatCurrentDate()}
          </div>
        </div>
        <div className="flex items-center justify-center w-full lg:min-h-[70vh] md:min-h-[80vh] sm:h-auto h-[80vh] ">
          <div className="w-[100%] md:w-[428px] h-min-[450px] h-auto p-5 bg-white rounded-[35px] shadow-[0px_4px_13px_#00000040]">
            <div onClick={()=>{
            handleback()
          
            }
              }className="flex items-start cursor-pointer">
              <img src={arrowleft} alt="Down Arrow" className="w-4 h-4" />
            </div>
            <div className="relative flex items-center justify-center">
  {!loading&&(myimgurl ? (
   <img
   src={pagedata?.profile_photo}
   alt="Profile"
   className={`${isRecording ? "w-[60px] h-[60px]" : "w-[100px] h-[95px]"} rounded-full`}
 />
  ) : (
    <>
      <img
        src={profilering}
        alt="Profilering"
        className={`${isRecording ? "w-[60px] h-[60px]" : "w-[100px] h-[95px]"}`}
      />
      <img
        src={face}
        alt="Face"
        className={`absolute ${isRecording ? "w-[40px] h-[60px]" : "w-[69px] h-[90px]"}`}
      />
    </>
  ))}
</div>

            <div className="flex justify-center items-center mt-4">
              <div className="text-xs [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-[#121212] tracking-[0] leading-[0.1px]">
                {pagedata?.patient_name||'-'}
              </div>
            </div>

            {isRecording &&(!showdefault.current) ? (
              <RecordingPlayer 
             restrictSave={restrictSave}
              previoustime={previoustime}
              myref={childRef} 
              setBackScreen={setIsRecording} 
              setToastAlert={setToastAlert}
              setToastObject={setToastObject}
              submitAudio={submitAudio} 
              setPreviousTime={setPreviousTime}/>
            ) : (
              <InitialRecording 
              setIsRecording={setIsRecording} 
              setAppointments={setAppointments}
              patient_id={pagedata?.patientid}
              pagedata={pagedata}/>
            )}
          </div>
        </div>
      </div>
    
      <SaveHandleToastModal reuploadAudio={reuploadAudio} isModalOpen={audiouploadFailed} id={id} setModalOpen={setAudioUploadFailed}/>
      <CombineToastModal isModalOpen={combineaudio} handlecombine={handlecombine} setModalOpen={setCombineAudio}/>
      <ToastModal toastobject={toastobject} isModalOpen={toastAlert} setModalOpen={setToastAlert}/>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
      <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default RecordingWrap;
