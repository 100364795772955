import React from 'react';

const ForceLogoutPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
<div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 animate-fadeIn">
  <div className="bg-white p-8 rounded-lg w-80 shadow-xl border border-gray-300 transform transition-all duration-300">
    <h2 className="text-center text-2xl font-semibold mb-4 text-blue-600">
      <span className="inline-block mr-2">
        <i className="fas fa-check-circle text-green-500"></i>
      </span>
      Plan Change
    </h2>
    <p className="text-center text-gray-700 mb-6">
      Your plan has been successfully changed. Please log out and log in again to apply the changes.
    </p>
    <div className="flex justify-center">
      <button 
        className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transform transition-all duration-200 hover:scale-105"
        onClick={onClose}
      >
        Logout
      </button>
    </div>
  </div>
</div>

  );
};

export default ForceLogoutPopup;
