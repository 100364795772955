import info from '../../assets/Info.png'
const Saveoptions=({closeModal,})=>{
    return(<>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <div className="bg-white w-[90%] max-w-md rounded-lg shadow-lg p-6">
                                <div className='flex w-full gap-4'>
                                    <img src={info} alt="" className='w-10 h-10'/>
                                <div >
                                <div className="text-lg font-semibold text-start ">Save to template?</div>
                                <div className="text-sm opacity-50 mb-8">This will apply to all notes in the future.</div>
                               
                               <div className="flex items-center justify-start gap-4">
                               <button
                                       className="px-6 py-2 rounded-md bg-[#1C2A3A] text-white hover:bg-[#173243]"
                                       onClick={closeModal} // Save action
                                   >
                                       Save
                                   </button>
                                   <button
                                       className="px-6 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300"
                                       onClick={closeModal} // Close modal
                                   >
                                       Cancel
                                   </button>
                                  
                               </div>


                                </div>

                                </div>
                           
                              
                            </div>
                        </div>
    
    </>)
}
export default Saveoptions