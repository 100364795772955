import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Skeleton from '@mui/material/Skeleton'; // MUI Skeleton
import api from '../../api/api';

const PromptModal = ({ isModalOpen, setModalOpen, data }) => {
  const [inputText, setInputText] = useState('');
  const [promptText, setPromptText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const[templatename,setTemplateName]=useState('')
  const fetchPrompt = async () => {
    setIsLoading(true);
    setPromptText('');
    try {
      const response = await api.get(`/v1/recording/note/${data.id}/get`);
      if (response.status === 200) {
        setPromptText(response.data[0].config.post_processing_prompt);
        setTemplateName(response.data[0].config.template_name)
      }
    } catch (error) {
      console.error('Error fetching prompt:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
   
    if (data?.id&&isModalOpen) {
      fetchPrompt();
    }
  }, [data]);

  const handleSubmit = async () => {
    setSubmitMessage('');
    setIsSubmitting(true);
    try {
      const auth = await api.get('/auth/me')
      const selectedTemplateName = templatename || auth.templates.default_template;
      const response = await api.put('/v1/document/post-processing', {
        comments: inputText,
        category: 'all',
        file_name: data.audiolink,
         template_name:selectedTemplateName
      });
      if (response.status === 200) {
        setInputText('');
        setSubmitMessage('Prompt processed successfully.');
        console.log('Submitted successfully');
      }else{
        setSubmitMessage('Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      setSubmitMessage('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
     
      fetchPrompt();
    }
  };

  if (!isModalOpen) {
    return null;
  }

  return (
    <>
      {isModalOpen && (
        <div
          id="popup-modal"
          tabIndex="-1"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="relative p-6 w-full max-w-lg max-h-full">
            <div className="relative bg-white min-h-[200px] rounded-lg shadow dark:bg-gray-800">
              {/* Close Button */}
              <button
                type="button"
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-900 dark:hover:text-white"
                onClick={() =>{
                    setPromptText('')
                    setInputText('')
                    setSubmitMessage('')
                    setModalOpen(false)}}
              >
                <CloseIcon fontSize="large" />
                <span className="sr-only">Close modal</span>
              </button>

              {/* Modal Content */}
              <div className="p-6 text-center">
                <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4">
                  Enter Your Prompt
                </h2>

                {/* Text Area */}
                <textarea
                  className="w-full p-3 text-sm text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                  rows="4"
                  placeholder="Type your response here..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                ></textarea>
{submitMessage && (
              <p
                className={`mt-2 text-start text-sm ${
                  submitMessage.includes('successfully') ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {submitMessage}
              </p>
            )}
                {/* View Previous Prompt */}
                <div className="mt-4 flex items-center justify-center text-sm text-gray-500 dark:text-gray-400">
                  {isLoading ? (
                    <Skeleton variant="text" width="60%" />
                  ) : (
                    <>
                      <VisibilityIcon
                        className="cursor-pointer text-blue-500"
                        onClick={() => setIsPromptVisible(!isPromptVisible)}
                      />
                      <span
                        className="ml-2 cursor-pointer"
                        onClick={() => setIsPromptVisible(!isPromptVisible)}
                      >
                        View Previous Prompt
                      </span>
                    </>
                  )}
                </div>

                {isPromptVisible && (
                  <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
                    {promptText || 'No previous prompt available.'}
                  </p>
                )}

                {/* Submit Button */}
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting || isLoading}
                  className={`mt-4 px-6 py-2 text-sm font-semibold rounded-lg focus:outline-none focus:ring ${
                    isSubmitting || isLoading
                      ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                      : 'bg-[#1c2a3a] text-white'
                  }`}
                >
                  {isSubmitting ? 'processing...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PromptModal;
