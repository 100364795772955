import React from 'react';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

// Function to convert minutes to HH:MM format
const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const paddedMins = mins < 10 ? `0${mins}` : mins;
    return `${hours}:${paddedMins}`;
};

const RangeSlider = ({ value, setValue }) => {
    // Handle slider value changes
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: 300 }}>
            <Slider
                getAriaLabel={() => 'Time range'}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(val) => formatTime(val)}
                min={0}
                max={1434} // Total minutes in a day (24 hours * 60 minutes)
                step={10} // Set granularity of slider in 10-minute increments
            />
            {/* Displaying selected time range */}
            <div>
                {formatTime(value[0])} - {formatTime(value[1])}
            </div>
        </Box>
    );
};

export default RangeSlider;
