import React, { useState, useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetData, resetSettings, setProviders, setVisittype } from '../../../redux/store';
import TimerIcon from '@mui/icons-material/Timer';
const ForceLogModal = ({ isModalOpen, selectedplan }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3); // Initialize countdown

  const logout = () => {
    dispatch(resetSettings());
    dispatch(setProviders([]));
    dispatch(setVisittype([]));
    dispatch(resetData());
    localStorage.removeItem('mytab');
    sessionStorage.removeItem('auth');
    navigate('/auth');
  };

  useEffect(() => {
    if (isModalOpen) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer); // Clear the timer when countdown reaches 0
            logout(); // Call the logout function
            return 0; // Final countdown value
          }
          return prev - 1; // Decrease countdown by 1
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup timer on unmount
    }
  }, [isModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <>
      {isModalOpen && (
        <div
          id="popup-modal"
          tabIndex="-1"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-lg sm:max-w-[50%] max-h-full">
            <div className="relative bg-white h-auto min-h-[200px] sm:min-h-[240px] rounded-lg shadow dark:bg-gray-700">
              <div className="p-4 md:p-5 text-center">
                <div>
                  <WarningIcon style={{ width: '80px', height: '80px', color: "#1D2951" }} />
                </div>
                <div className="mb-2 mt-3 font-sans text-lg font-[400]">
                  {`Your subscription plan has been successfully updated to ${selectedplan}.`}
                </div>
              </div>
              <div className="flex items-center justify-center font-semibold mt-4 mb-2 pb-2">
                <TimerIcon sx={{marginRight:"4px"}}/>{`You will be logged out in ${countdown} seconds`}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForceLogModal;
