import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../.././api/api';
import arrowleft from '../../assets/arrowleft.png';
import redmic from '../../assets/redmic.png';
import copydark from '../../assets/copy.svg';
import printdark from '../../assets/printicon.svg';
import Copyall from '../../assets/bi_copy.svg';
import { Backdrop, CircularProgress, Snackbar } from '@mui/material';
import ArchiveRecordingScreen from '../../pages/recording/archiveRecording';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import ToastModal from 'alerts/recordingscreen/toastmodal';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ToastMessage from 'utils/muialerts/simplealert';
import expandicon from '../../assets/expand.png'
import icroundmic from'../../assets/ic_round-mic.svg'
import { Expand, NoteTwoTone } from '@mui/icons-material';
import MicIcon from '@mui/icons-material/Mic';
import IndividualRecording from '../../pages/recording/indrecording';
import useModalStore from 'zustand/zustandstore';
const GeneralEditscreen = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [indisRecording,setIndIsRecording]=useState(false)
  const[isdeletedaudio,setIsdelted]=useState(false)
  const [indisDeletedAudio,setIndIsDeletedAudio]=useState(false)
  const [open, setOpen] = useState(false);
  const[jsonData,setJsonData]=useState([])
  const [alertmessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const[isLoading,setIsLoading]=useState(false)
  const mydata = useModalStore();
  const[config,setConfig]=useState({})
  const[audiofile,setAudiofile]=useState()
  const[toast,setToast]=useState(false)
  const [isExpanded,setIsexpanded]=useState(false)
  const [expandedRows, setExpandedRows] = useState({}); 
  const [rowSizes, setRowSizes] = useState({}); 
  const [data, setData] = useState({});
  const excludedKeys = ["date"];
  const [recordingField,setRecordingField]=useState('')
  const [isIndRecord,setIsIndRecord]=useState(false)  
  const [fields, setFields] = useState({
  
  });
  
  const [toastobject,setToastObject]=useState({
    icon:DangerousIcon,
    text1:"Something Went Wrong",
    text2:""

  })
  const navigate = useNavigate();
  const { id ,name} = useParams();

const[copyFeedback,setCopyFeedback]=useState('')
const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
}; 
 const handlealertClick = (message, severity) => {
  setAlertMessage(message);
  setSeverity(severity);
  setOpen(true);
};
  const fetchData = async () => {
    setIsLoading(true)
    setFields({
      chiefcomplaint: '',
      hpi: '',
      ros: '',
      physicalexam: '',
      assessment: '',
      plan: ''
    })
    try {
         const response = await api.get(`/v1/recording/note/${id}/get`);
      if (response.status === 200) {
          const initialData =response.data[0]
        setData(initialData);
        const notesArray = Object.keys(initialData?.note_json||{})
        .filter(key => !excludedKeys.includes(key)) 
        .map((key, index) => ({
            id: index + 1,
            label: key.replace(/_/g, " "),
            key: key,
            title: key,
            value: initialData.note_json[key],
            placeholder: key,
        }));


const sortedData = Array.isArray(notesArray)
    ? notesArray.sort((a, b) => a.key.localeCompare(b.key))
    : [];

console.log(sortedData)

setJsonData(sortedData||[])
// setJsonData([{id:3,key:"Highlights",label:"Highlights",placeholder:"Highlights",title:"Highlights",value:""},
//     {id:2,key:"Tasks",label:"Tasks",placeholder:"Tasks",title:"Tasks",value:""},{id:3,key:"Summary",label:"Summary",placeholder:"Summary",title:"Summary",value:""}
// ])
        setConfig(response.data[0].config)
        setFields(initialData?.note_json)
      }
    } catch (error) {
      console.error('Error fetching data', error);
    }finally{
      setIsLoading(false)
    }
    
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  const submitdata = async (payload, text,fieldname) => {
    const a = fieldname;
    const b = text;
    const obj = { [a]: b };
    const transformedData = {
      "context_dict": obj 
    };
    setIsLoading(true); 
   
    try {
      const response = await api.put('/v1/recording/category/update',{
            ...transformedData,
        file_name:data.audiolink
      
      });
      fetchData()
      // Handle response here if needed
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
   
  };
  
  
const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      handlealertClick('Copied to clipboard!', 'success')
     // setCopyFeedback('Copied to clipboard!'); // Set feedback message
      //setTimeout(() => setCopyFeedback(''), 2000); // Hide message after 2 seconds
    })
    .catch(err => {
      console.error('Failed to copy text: ', err);
    });
};


  const handleFieldChange = (fieldName, value) => {
    setFields(prevFields => ({ ...prevFields, [fieldName]: value }));
  };

  const handleSaveIconClick = (fieldName) => {
   const fieldValue = fields[fieldName]; 
    const dataToSubmit = {
      [fieldName]: fieldValue,
      category: fieldName
    };
    submitdata(dataToSubmit,fieldValue,fieldName);  
    // Log the details to the console
    console.log(`Field: ${fieldName}, Value: ${fieldValue}`);
  };
  

  const handleSaveButtonClick = async() => {
    if (data.transcriptionstatus === 'Processing') {
      setAlertMessage('Update in process. Hold on!');
      setSeverity('info'); // Change severity as needed
      setOpen(true);
      return;
    }

       const changedFields = {};
    for (const key in fields) {
      if (fields[key] !== data[key]) {
        changedFields[key] = fields[key];
      }
    }  
    const transformedData = {
      context_dict: changedFields // Assigning changedFields directly
    };
    setIsLoading(true); 
    console.log(typeof(transformedData.context_dict))
    if( Object.keys(transformedData.context_dict).length === 0){
      setIsLoading(false)
      handlealertClick('No Updated Data Found','error')
      
      return
    }
    try {

      const response = await api.put('/v1/recording/category/update',{
            ...transformedData,
        file_name:data.audiolink
      
      });

     
      fetchData()
      // Handle response here if needed
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  
  };
  const submitIndAudio= async (blob)=>{
    if(indisDeletedAudio){
      setAudiofile('')
      handlealertClick('Recording has been Deleted','error')
      setIsIndRecord(false)
      setRecordingField('')
      setIndIsDeletedAudio(false)
    }else{
 setIsIndRecord(false)

try {
  const formData = new FormData();
  formData.append("file", blob);
  formData.append("departmentid", config.departmentid);
  formData.append("category", recordingField);
  formData.append("patientname", name);
  formData.append("patientid", config.patientid);
  formData.append("practiceid", config.practiceid);
  formData.append("filename", data.audiolink);
  formData.append("rowid", id);
  
  if (mydata.usertype === 'BUSINESS') {
    formData.append("notes", data.notes); // Take notes from data.notes
      formData.append("tags", data.tags);  // Take tags from data.tags
    }else{
          formData.append("tags", 'tagsdata');
              formData.append("notes", "testing data");
    }
  
  const response = await api.post('v1/ehr/recording/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status == 200) {
    handlealertClick('Recording Has Been Uploaded');
    navigate(-1)

  } else {
    handlealertClick('Something Went Wrong', 'error');
  }
  
} catch (error) {
  console.error('Error uploading audio:', error);
}finally{
  setRecordingField('')
}
    }
  }
const submitAudio= async (blob)=>{
  if(isdeletedaudio){
    setAudiofile('')
    handlealertClick('Recording has been Deleted','error')
  }else{
    try {
      const formData = new FormData();
      formData.append("file", blob);
      formData.append("departmentid", config.departmentid);
      formData.append("category", "combine");
      formData.append("patientname", name);
      formData.append("patientid", config.patientid);
      formData.append("appointmentid", config.appointmentid);
  
      formData.append("practiceid", config.practiceid);
      formData.append("filename", data.audiolink);
      formData.append("rowid", id);
  
      if (mydata.usertype === 'BUSINESS') {
      formData.append("notes", data.notes); // Take notes from data.notes
        formData.append("tags", data.tags);  // Take tags from data.tags
      }else{
            formData.append("tags", 'tagsdata');
                formData.append("notes", "testing data");
      }
      const response = await api.post('v1/ehr/recording/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    
      if (response.status == 200) {
        handlealertClick('Recording Has Been Uploaded');
        navigate(-1)
     
      } else {
        handlealertClick('Something Went Wrong', 'error');
      }
      
    } catch (error) {
      console.error('Error uploading audio:', error);
    }
    
    }
}
const handleCopyallText = () => {
  let textToCopy = '';

  // Iterate over the fields object and format each key-value pair as 'key: value'
  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      textToCopy += `${key}: ${fields[key]}\n`;
    }
  }
  navigator.clipboard.writeText(textToCopy).then(() => {
    handlealertClick('Copied to Clipboard')
  }).catch(err => {
    console.error('Failed to copy text: ', err);
    handlealertClick('Something Went wrong','error')
  });
};
const handleExpandClick = (key, text) => {
  console.log("Current Expanded Rows:", expandedRows); // Debug log
  console.log("Clicked Row Key:", key);

  setExpandedRows((prev) => {
    const newExpandedRows = {};
    // Loop through previous state to update expansion
    Object.keys(prev).forEach((k) => {
      newExpandedRows[k] = k === key ? !prev[k] : false; // Toggle clicked row
    });
    console.log("New Expanded Rows:", newExpandedRows); // Debug log for new state
    return newExpandedRows; // Return updated state
  });

 
  calculateRows(key, text);
};
const calculateRows = (key, text) => {
  const lineHeight = 20; // Approximate line height in pixels
  const maxHeight = 100; // Initial height in pixels
  // Calculate the number of lines based on the text length
  const rows = Math.ceil(text.length / (maxHeight / lineHeight)); // Adjusted calculrration
  const newSize = Math.max(rows, 1); // Ensure at least one row is displayed

  setRowSizes((prev) => ({
    ...prev,
    [key]: newSize, // Set the row size for the specific section
  }));
  
  return newSize; // Return the calculated size
};


  return (<>

    <div className="flex items-center justify-center w-full h-auto">
    <div className="w-[95%] mb-2 sm:w-[80%] flex flex-col justify-center items-center  relative h-min-[150px] h-auto mt-5 p-8 pb-3 bg-white rounded-[20px] shadow-[0px_2px_13px_#00000040]">
        <div onClick={() => navigate(-1)} className="w-full flex items-start ">
          <img src={arrowleft} alt="Down Arrow" className="w-5 h-5 cursor-pointer" />
        </div>


       {isIndRecord ? (
  <div>
 <IndividualRecording screenname={recordingField}
 setIndIsRecording={setIndIsRecording}
 submitIndAudio={submitIndAudio}
 setToastmsg={setToastObject}
 setToast={setToast}
 setIsdelted={setIndIsDeletedAudio}
 />
   </div>
) : isRecording ? (
  <ArchiveRecordingScreen
    setIsRecording={setIsRecording}
    submitAudio={submitAudio}
    setToastmsg={setToastObject}
    setToast={setToast}
    setIsdelted={setIsdelted}
  />
) : (
  <>
    <div
      onClick={() => {
        if(mydata.isOpen){
          setAlertMessage('Recording already in progress');
          setSeverity('info'); // Change severity as needed
          setOpen(true);
        return
        }

        setIsRecording(true);
        setIsdelted(false);
        handlealertClick('Recording Started');
      }}
      className="relative flex items-center justify-center"
    >
      <img src={redmic} className="w-[50px] h-[50px]" alt="Mic" />
    </div>
    <div className="flex justify-center items-center mt-4">
      <div className="text-sm items-center [font-family:'Poppins',Helvetica] text-[#121212] tracking-[0] leading-[0.1px]">
        Add Recording
      </div>
    </div>
  </>
)}

     
        
        {/* <div className="mt-2 flex items-start">
          <button onClick={()=>{handleCopyallText()}} className="bg-[#F6F6F6] flex text-xs border-2 font-[Tahoma] items-center py-1 px-3 rounded-full">
            <img style={{ width: '13px', height: '13px' }} src={Copyall} alt="&/" /> 
            <div className="ml-2 text-[11px]"> Copy All</div>
          </button>
        </div> */}
        
        <div className="mt-[6%] w-[90%] grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
      {jsonData.map((section, idx) => (
       <div key={idx} className="flex flex-col w-full max-w-[300px]">
          <div className="flex justify-between w-full">
          <div style={{ fontWeight: 500 }} className="text-xs">
  {section.label
    ? section.label
        .toLowerCase() // Ensure all letters are lowercase first
        .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
    : ''}
</div>

            <div className="flex items-center gap-2">
              {/* <div   onClick={()=>{
                    if(recordingField==section.key){
                      return
                    }
                    if(recordingField){
                      return
                    }
                    if (data.transcriptionstatus === 'Processing') {
                      setAlertMessage('Update in process. Hold on!');
                      setSeverity('info'); // Change severity as needed
                      setOpen(true);
                      return;
                    }
                    if(mydata.isOpen){
                      setAlertMessage('Recording already in progress');
                      setSeverity('info'); // Change severity as needed
                      setOpen(true);
                    return
                    }
                    setRecordingField(section.key)
                    setIsIndRecord(true)
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }} 
                   >
                 <MicIcon 
  fontSize="small" 
  style={{ 
    cursor: "pointer", 
    width: "20px", 
    height: "20px", 
    color: recordingField === section.key ? 'red' : '#000' 
  }} 
/>

              
              </div> */}
              <div>
                <img
                  style={{ width: "18px", height: "18px", cursor: "pointer" }}
                  src={copydark}
                  alt="Copy"
                  onClick={() => copyToClipboard(fields[section.key])}
                />
              </div>
              <div>
                <img
                  style={{ width: "13px", height: "13px", cursor: "pointer" }}
                  src={printdark}
                  alt="Print"
                  onClick={() => {
                    if (data.transcriptionstatus === 'Processing') {
                      setAlertMessage('Update in process. Hold on!');
                      setSeverity('info'); // Change severity as needed
                      setOpen(true);
                      return;
                    }
                    handleSaveIconClick(section.key)}}
                />
              </div>
            </div>
          </div>
          <div className="relative">
            <textarea
              className="w-full mt-1 max-w-md p-2 border-[1.4px] border-gray-200 rounded-lg font-inter resize-none placeholder:text-xs"
              rows={expandedRows[section.key] ? rowSizes[section.key] : "4.5"}
              value={fields[section.key]}
              onChange={(e) => handleFieldChange(section.key, e.target.value)}
              placeholder={section.placeholder}
            />
            <div className="absolute right-2 bottom-2">
            
            </div>
          </div>
        </div>
      ))}
    </div>

        <div className="flex items-center p-4 mt-6 justify-center">
          <button 
            onClick={handleSaveButtonClick} 
            className="px-16 py-2 rounded-full bg-[#1C2A3A] font-[Tahoma] text-white text-xs"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
    
      {/* Copy feedback message */}
      <Snackbar
  open={copyFeedback}
  autoHideDuration={3000}
  // onClose={handleClose}
  message="Copied to clipboard!"
  // action={action}
/>
<ToastModal toastobject={toastobject} isModalOpen={toast} setModalOpen={setToast}/>
     <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
     <CircularProgress color="inherit" />
   </Backdrop>
   <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
    </>
  );
};

export default GeneralEditscreen;
