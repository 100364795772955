import React from 'react';

const HomeIcon = ({ width = 22, height = 22, fill = "#575860" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M17.1314 3.66864C16.5065 3.0438 15.4935 3.0438 14.8686 3.66864L3.66864 14.8686C3.0438 15.4935 3.0438 16.5065 3.66864 17.1314C4.29348 17.7562 5.30654 17.7562 5.93138 17.1314L6.40001 16.6628V27.2C6.40001 28.0837 7.11636 28.8 8.00001 28.8H11.2C12.0837 28.8 12.8 28.0837 12.8 27.2V24C12.8 23.1164 13.5164 22.4 14.4 22.4H17.6C18.4837 22.4 19.2 23.1164 19.2 24V27.2C19.2 28.0837 19.9164 28.8 20.8 28.8H24C24.8837 28.8 25.6 28.0837 25.6 27.2V16.6628L26.0686 17.1314C26.6935 17.7562 27.7065 17.7562 28.3314 17.1314C28.9562 16.5065 28.9562 15.4935 28.3314 14.8686L17.1314 3.66864Z"
        fill={fill}
      />
    </svg>
  );
};

export default HomeIcon;
