import React from 'react';

const PageNotAvailable = () => {
  return (
    <div className="page-not-available">
      <h1>404 - Page Not Available</h1>
      <p>Sorry, the page you're looking for does not exist.</p>
    </div>
  );
};

export default PageNotAvailable;
