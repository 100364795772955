import React, { useState } from 'react';
import profilering from '../../assets/profilering.png';
import face from '../../assets/profile.png';
import { useNavigate } from 'react-router-dom';
import arrowleft from '../../assets/arrowleft.png';
import editIcon from '../../assets/edit.png';
import { IconButton, Snackbar } from '@mui/material';
import api from '../../api/api'; // Assuming you have an api module for API calls

const EmailUpdate = ({ screenhandler, setScreen, username, mobilenumber,email }) => {
    const navigate = useNavigate();
    
    // State for email fields
    const [currentEmail, setCurrentEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    
    const handleCloseToast = () => {
        setOpenToast(false);
    };

    const handleSubmit = async () => {
        if (!newEmail) {
            setToastMessage('Please enter a new email address.');
            setOpenToast(true);
            return; // Prevent submission if new email is empty
        }

        try {
            const response = await api.put(`/v1/users/${mobilenumber}`, {
               
                "email": newEmail,
               
              });

            if (response.status === 200) {
                setToastMessage('Email updated successfully!');
                // Optionally reset the fields or navigate
            } else {
                setToastMessage('Failed to update email. Please try again.');
            }
        } catch (error) {
            setToastMessage('Failed to update email. Please try again.');
        } finally {
            setOpenToast(true);
            setTimeout(() => {
                setScreen(0);      
            }, 3000);
        }
    };

    return (
        <>
            <div className="w-full bg-white sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-0 md:p-6 sm:p-6 shadow-none sm:shadow-lg md:shadow-lg md:bg-white sm:bg-white h-screen sm:h-[500px] md:h-[600px] sm:rounded-lg md:rounded-lg">
                <div className='flex gap-8'>
                    <div className="flex items-start">
                        <img
                            onClick={() => setScreen(0)}
                            src={arrowleft}
                            alt="Back Arrow"
                            className="w-5 h-5 cursor-pointer"
                        />
                    </div>
                    <div className="flex items-center justify-start gap-2">
                        <div className="relative flex items-center justify-center">
                        <img src={profilering} alt="Profile Ring" className="w-[75px] h-[75px]" />
              <img src={face} alt="Face" className="absolute w-[60px] h-[61px] left-2 top-3 rounded-full" />
              {/* <IconButton className="absolute bottom-0 right-6 top-5">
                <img src={editIcon} alt="Edit" className="w-[20px] h-[20px]" />
              </IconButton> */}
                        </div>
                        <div className='flex flex-col text-start'>
                            <div className="font-semibold text-black text-lg">{username}</div>
                            <div className="opacity-50 text-black text-sm">{email||''}</div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col justify-around h-[80%]'>
                    <label className="font-semibold text-black">Change Email </label>
                    <div className='w-full flex flex-col items-center justify-center gap-6 mt-4'>
                        {/* Current Email Input */}
                        <div className='w-full flex flex-col items-center'>
                            <div className='flex items-start mb-2 w-full sm:w-[50%] md:w-[60%]'>
                                <label className="text-black">Current Email Address</label>
                            </div>
                            <div className='relative w-full md:w-[60%] sm:w-[60%]'>
                                <input
                                    type="email"
                                    placeholder="Current Email Address"
                                    className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                                    value={email||''}
                                    //onChange={(e) => setCurrentEmail(e.target.value)}
                                    disabled // Disable the current email field
                                />
                            </div>
                        </div>
                        {/* New Email Input */}
                        <div className='w-full flex flex-col items-center'>
                            <div className='flex items-start mb-1 w-full sm:w-[50%] md:w-[60%]'>
                                <label className="text-black">New Email Address</label>
                            </div>
                            <div className='relative w-full md:w-[60%] sm:w-[60%] mt-2'>
                                <input
                                    type="email"
                                    placeholder="New Email Address"
                                    className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Buttons for Cancel and Save */}
                    <div className='w-full flex items-center justify-center'>
                        <div className='flex w-[60%] items-center justify-around mt-6'>
                            <button 
                                onClick={() => { setScreen(0); }}
                                className="rounded-full w-[120px] bg-black text-white py-2 px-4 transition duration-300 ease-in-out hover:bg-gray-800">
                                Cancel
                            </button>
                            <button 
                                onClick={handleSubmit}
                                className="rounded-full w-[120px] bg-black text-white py-2 px-4 transition duration-300 ease-in-out hover:bg-gray-800">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={openToast}
                autoHideDuration={6000}
                onClose={handleCloseToast}
                message={toastMessage}
            />
        </>
    );
}

export default EmailUpdate;
