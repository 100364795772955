import React, { useEffect, useState } from 'react';
import logo from '../../assets/blinkscribe 4.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import api from 'api/api';
import arrowleft from '../../assets/arrowleft.png'
import { useNavigate, useParams } from 'react-router-dom';
const PricingOptions = ({setScreen,handleVerified, setPopupOpen}) => {
  const{flow}=useParams()
  const navigate=useNavigate()
  const [role,setRole]=useState('')
  // State to track the selected option
  const [selectedOption, setSelectedOption] = useState(0);
  const [loading,setLoading]=useState(false)
  const [error,setError]=useState('')
const[dropdown,setDropDown]=useState('')
  // Handler to set selected option
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  const handleSubmit=async()=>{
let payload={ "user_type": ''}
if(selectedOption==0){
  payload.user_type='BASIC'
}else if(selectedOption=='1'){
  payload.user_type='PLUS'
}else{
  payload.user_type='PRO'
  payload.ehr_name=dropdown
}

try {
  setLoading(true)
  
  const response= await api.put('/v1/users/user-type',payload)
        if(response.status===200){
      
          if(flow=="self"){ 
             setScreen(2)
            // navigate('/manage-profile', { state: { refresh: Date.now() } });
            setPopupOpen(true)
            return
          }
          handleVerified()
        }else{
          setError('something went wrong')
        }
} catch (error) {
  setError('something went wrong')
}finally{
setLoading(false)
}


  }
  
  const fetchUser = async () => {
    try {
      const response = await api.get('/auth/me');  
      // Strict validation
      if (response?.config?.user_type && flow === 'self') {
        setRole(response.config.user_type);
      } else {
        setRole(''); // Assign empty string if validation fails
      }
    } catch (error) {
      console.error('Error fetching user:', error); // Log the error for debugging
      setRole(''); // Default to empty in case of error
    }
  };
  useEffect(()=>{
    fetchUser()
    },[])

  // Box shadow style for the selected option
  const selectedShadow = "shadow-[0px_0px_8px_8px_rgba(133,_182,_255,_0.35)]";

  return (
    <>
      {/* Overlay Background - Covers the entire screen */}
      <div className="fixed inset-0 bg-black bg-opacity-50 z-10 min-h-screen min-w-full"></div>

      {/* Overlay Card - Centered, updated styles */}
      <div className=" flex flex-col items-center justify-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-[12.781px] 
      shadow-[0px_0px_8px_8px_rgba(0,_0,_0,_0.04)] py-4   min-w-[160px] h-full w-full max-h-[95vh]   md:h-auto sm:h-auto overflow-auto custom-scrollbar sm:w-[80%] md:w-[70%] min-h-[600px] z-20">
        <div className=" flex w-full flex-col items-center justify-center px-2 ">
        <div className="flex w-[90%] mt-2 items-start">
            <img
              onClick={() => {
                setScreen(0)
               }}
              src={arrowleft}
              alt="Back Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <div className="flex justify-center mb-2 md:mt-0 sm:mt-0">
            <img
              src={logo}
              alt="BlinkScribe Logo"
              className="h-20 sm:h-14 md:h-14 w-24 sm:w-auto md:w-auto"
            />
          </div>

          <h2 className="text-lg font-semibold mb-4">Choose your Experience</h2>

          {/* Cards Container - Row direction on medium and larger screens */}
          <div className="flex flex-col flex-wrap md:flex-row gap-4 w-full justify-center items-center">
            {/* Medical Card */}
            <div
              onClick={() => handleOptionClick(0)}
              className={`flex justify-between flex-col flex-1 p-6  sm:min-w-[240px] overflow-auto max-w-[300px] bg-white rounded-[12.781px] shadow-[0px_0px_12px_8px_rgba(0,0,0,0.10)] text-start cursor-pointer h-[400px] ${selectedOption === 0? selectedShadow : ''}`}
            >
              <div>
              <h3 className="text-sm sm:text-lg font-bold text-start">blinkScribe</h3>
              <h6 className="text-[9px]  font-semibold text-start">Medical</h6>
              <h3 className="text-xl sm:text-lg font-bold text-start">Free</h3>
              <ul className="mt-4 text-xs sm:text-md text-gray-600 space-y-2 pl-6">
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">100 Free Visit Minutes</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Real-Time Transcription</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Dictation Transcription</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Automated Chart Note Generation</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Context Aware AI</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Supports 30+ Languages</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">30-Day Document Archive</span>
      </li>
    </ul>
    </div>
    {role==='BASIC'?<div className='w-full mt-2 mb-2 flex items-center justify-center'>
<div className='bg-[#B0CDFB] tracking-wide text-sm font-bold text-[#FFFFFF] px-6 py-1'>
ACTIVE
</div>
           </div>:<></>}
            </div>

           {/* card-2 */}
           <div
              onClick={() => handleOptionClick(1)}
              className={`flex justify-between flex-col flex-1 p-6 sm:min-w-[240px] overflow-auto max-w-[300px] bg-white rounded-[12.781px] shadow-[0px_0px_12px_8px_rgba(0,0,0,0.10)] text-start cursor-pointer h-[400px] ${selectedOption === 1 ? selectedShadow : ''}`}
            >
              <div>
              <h3 className="text-sm sm:text-lg font-bold text-start">blinkScribe Plus</h3>
              <h6 className="text-[9px]  font-semibold text-start">Medical</h6>
            <div className="flex items-center space-x-2">
  <h3 className="text-lg sm:text-[28px] font-bold">$59.99</h3>
  <h6 className="text-sm font-normal">/month</h6>
</div>
              <ul className="mt-4 text-xs sm:text-md text-gray-600 space-y-2 pl-6">
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">All blinkScribe Basic Features</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Unlimited Visits</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Document Archive</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Custom Templates</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">White Labeling</span>
      </li>
    
    </ul>
    </div>
    {role==='PLUS'?<div className='w-full mt-2 mb-2 flex items-center justify-center'>
<div className='bg-[#B0CDFB] tracking-wide text-sm font-bold text-[#FFFFFF] px-6 py-1'>
ACTIVE
</div>
           </div>:<></>}
            </div>
            {/* card-3 */}
           <div
  onClick={() => handleOptionClick(2)}
  className={`flex justify-between flex-col flex-1 p-6 sm:min-w-[240px] overflow-auto max-w-[310px] bg-white rounded-[12.781px] shadow-[0px_0px_12px_8px_rgba(0,0,0,0.10)] text-start cursor-pointer h-[400px] ${selectedOption === 2 ? selectedShadow : ''}`}
>
  <div>
    <h3 className="text-sm sm:text-lg font-bold text-start">blinkScribe Medical Pro</h3>
    <div className="flex items-center space-x-2">
      <h3 className="text-lg sm:text-[28px] font-bold">$199.99</h3>
      <h6 className="text-sm font-normal">/month</h6>
    </div>
    <ul className="flex-grow mt-4 text-xs sm:text-md text-gray-600 space-y-2 pl-6 overflow-hidden">
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">All blinkScribe Basic Features</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Unlimited Visits</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Document Archive</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">Custom Templates</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCheck} className="text-[#156CFE] mt-1" />
        <span className="ml-2 text-[#000] font-semibold">White Labeling</span>
      </li>
    </ul>
  </div>
  <div className="mt-4">
  {role==='PRO'?<div className='w-full mt-2 mb-2 flex items-center justify-center'>
<div className='bg-[#B0CDFB] tracking-wide text-sm font-bold text-[#FFFFFF] px-6 py-1'>
ACTIVE
</div>
           </div>:<></>}
    <select
      className="block w-full border border-gray-300 rounded-md p-2 text-sm"
      onChange={(e) => setDropDown(e.target.value)}
      defaultValue=""
      value={dropdown}
    >
      <option value="" disabled>
        Select EMR
      </option>
      {/* <option value="ATHENA_PREVIEW">ATHENA_PREVIEW</option> */}
      <option value="ATHENA">ATHENA</option>
      
     
    </select>
  </div>
</div>

          </div>
          {error?<p className='text-red-600 text-sm'>{error}</p>:<></>}
          {/* Continue Button */}
          <div className="flex items-center justify-center w-full mt-6">
          <button
  onClick={() => handleSubmit()}
  disabled={loading||(selectedOption===2&&!dropdown)} // Disable button when loading is true
  className={`flex w-[100%] sm:w-[45%] md:w-[35%] h-12 md:h-10 sm:h-8 items-center justify-center gap-2.5 px-6 py-4 rounded-full border border-neutral-500 transition duration-300 ease-in-out ${
    loading||(selectedOption===2&&!dropdown) ? 'bg-gray-400 cursor-not-allowed' : 'bg-black text-white'
  }`}
>
  <div className="font-semibold text-sm">
    {loading ? 'Submitting...' : 'Continue'}
  </div>
</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingOptions;
