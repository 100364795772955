import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const SimpleModal = ({ isModalOpen, setModalOpen, data }) => {
  
  // Function to check if emr_posted_status has at least one key
  const checkEmrPostedStatus = () => {
    // Ensure data is defined and has emr_posted_status key
    if (data && data.emr_posted_status) {
      const { emr_posted_status } = data;
      return Object.keys(emr_posted_status).length > 0;
    }
    return false; // Return false if data is invalid or doesn't contain emr_posted_status
  };

  // Get the items that are NOT boolean true
  const getNonTrueStatuses = () => {
    if (data && data.emr_posted_status) {
      const { emr_posted_status } = data;
      return Object.entries(emr_posted_status).filter(
        ([key, value]) => value !== true
      );
    }
    return [];
  };

  // Close modal if there are no keys in emr_posted_status
  useEffect(() => {
    if (!checkEmrPostedStatus()) {
      setModalOpen(false);
    }
  }, [data, setModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  // Get the entries that are boolean true for the general list
  const trueStatuses = Object.entries(data.emr_posted_status).filter(
    ([key, value]) => value === true
  );

  // Get the entries that are not boolean true for the special section
  const nonTrueStatuses = getNonTrueStatuses();

  return (
    <>
      {isModalOpen && (
        <div
          id="popup-modal"
          tabIndex="-1"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="relative p-6 w-full max-w-lg max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* Close Button */}
              <button
                type="button"
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-900 dark:hover:text-white"
                onClick={() => setModalOpen(false)}
              >
                <CloseIcon fontSize="large" />
                <span className="sr-only">Close modal</span>
              </button>

              {/* Modal Content */}
              <div className="p-6 text-center">
                <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4">
                  EMR Posting Status
                </h2>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">
                  Check the status of your EMR postings below.
                </p>
                
                {/* EMR Status List */}
                <div className="space-y-3">
                  {/* Show only true statuses in the general list */}
                  {trueStatuses.map(([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between items-center p-2 rounded-md border dark:border-gray-600 bg-gray-50 dark:bg-gray-700"
                    >
                      <span className="font-medium text-gray-700 dark:text-gray-300">
                        {key}
                      </span>
                      <span className="flex items-center text-sm font-semibold text-green-600">
                        <CheckCircleIcon className="mr-1" /> Posted to EMR
                      </span>
                    </div>
                  ))}

                  {/* Display non-true statuses at the bottom */}
                  {nonTrueStatuses.length > 0 && (
                    <div className="p-3 mt-4 bg-gray-200 dark:bg-gray-700 rounded-md text-left">
                      <strong className="text-gray-800 dark:text-gray-200">Encounter Issues:</strong>
                      {nonTrueStatuses.map(([key, value]) => (
                        <div key={key} className="mt-2">
                          <p className="text-gray-600 dark:text-gray-400 break-words">
                            <strong>{key}:</strong> {typeof value === 'string' ? value : 'Not posted'}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SimpleModal;
