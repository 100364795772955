import { useState, useEffect, useRef } from "react";

const ReactiveWaves = ({ mediaStream, isPaused }) => {
  const [waveData, setWaveData] = useState(new Array(16).fill(10)); // Start with equal heights
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const sourceRef = useRef(null);
  
  // Define a sensitivity threshold
  const SENSITIVITY_THRESHOLD = 5; // Adjust this value to increase or decrease sensitivity
  const MAX_WAVE_HEIGHT = 20; // Maximum height of the wave bars
  const MIN_WAVE_HEIGHT = 10; // Minimum height of the wave bars

  useEffect(() => {
    if (!isPaused) {
      // Skip wave updates when paused
      return;
    }
    if (mediaStream) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      sourceRef.current = audioContextRef.current.createMediaStreamSource(mediaStream);

      analyserRef.current.fftSize = 32; // Lower fftSize gives a chunkier wave effect
      const bufferLength = analyserRef.current.frequencyBinCount;
      dataArrayRef.current = new Uint8Array(bufferLength);

      sourceRef.current.connect(analyserRef.current);
      
      const updateWaves = () => {
        analyserRef.current.getByteFrequencyData(dataArrayRef.current);
        
        // Check for the maximum value in the frequency data
        const maxValue = Math.max(...dataArrayRef.current);
        
        // Only update wave data if maxValue exceeds the sensitivity threshold
        if (maxValue > SENSITIVITY_THRESHOLD) {
          // Scale down the frequency data for better visual representation
          const normalizedData = Array.from(dataArrayRef.current).map((value) =>
            Math.max(MIN_WAVE_HEIGHT, (value / 255) * MAX_WAVE_HEIGHT) // Scale heights
          );
          setWaveData(normalizedData);
        } else {
          // If below threshold, keep wave heights at minimum
          setWaveData(new Array(16).fill(MIN_WAVE_HEIGHT));
        }

        requestAnimationFrame(updateWaves);
      };

      updateWaves();
    }

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [mediaStream, isPaused]); // Add isPaused to dependencies

  return (
    <div className="flex items-center gap-0.5"> {/* Adjusted gap for spacing */}
      {waveData.map((height, index) => (
        <div key={index} className="w-0.5"> {/* Changed width from 'w-1' to 'w-0.5' */}
          <div className="w-full bg-red-500 rounded-[34.19px]" style={{ height: `${height}px` }} />
        </div>
      ))}
    </div>
  );
};

export default ReactiveWaves;
