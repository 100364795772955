import useModalStore from "zustand/zustandstore";
import BasicRecordingModule from "./basicrecordingmodule";
import DangerousIcon from '@mui/icons-material/Dangerous';
import ToastModal from "alerts/recordingscreen/toastmodal";
import { useRef, useState } from "react";
import AlertMsg from "basic/modals/alertmsg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Backdrop, CircularProgress } from "@mui/material";
import api from '../../api/api'
import AddPatientmodal from "basic/modals/addpatient";
const BasicRecordingWrap = () => {
  const { setModalValue } = useModalStore();  
  const mydata = useModalStore();
const selectedTemplate=useRef('')
  const patientNameRef = useRef('');
  const patientIdRef = useRef('');
 const [showOverlay, setShowOverlay] = useState(true);
  const [toastAlert, setToastAlert] = useState(false);
  //  const[showpatientmodal,setPatientModal]=useState(false)
  const[loading,setLoading]=useState(false)
  const [toastObject, setToastObject] = useState({
    icon: CheckCircleIcon,
    text1: "Your Recording is Successfully Completed!",
    text2: "",
  });
  const updatePatientInfo = (name, id) => {
    patientNameRef.current = name;
    patientIdRef.current = id;
  };
  const updateSelectedTemplate=(temp)=>{
    selectedTemplate.current=temp
  }
  // Function to handle audio submission
  const submitAudio = async(file) => {
    setShowOverlay(false); // Hide overlay after audio submission
try {
  setLoading(true)
const response = await api.get('/auth/me')
const formData = new FormData();
if(mydata.usertype=='BUSINESS'){
  formData.append('file', file);
formData.append('practiceid', response?.config.practiceid);
formData.append('departmentid', response?.config.departmentid);
formData.append('patientid', 12241);
formData.append('notes', patientIdRef.current);
formData.append('tags',patientNameRef.current);
// formData.append('template_name', selectedTemplate.current);
}else if(mydata.usertype=='PLUS'&&selectedTemplate.current){
  formData.append('file', file);
formData.append('practiceid', response?.config.practiceid);
formData.append('departmentid', response?.config.departmentid);
formData.append('patientid', patientIdRef.current);
formData.append('patientname', patientNameRef.current);
formData.append('template_name', selectedTemplate.current);

}else if(mydata.usertype=='BASIC'&&selectedTemplate.current){
  formData.append('file', file);
  formData.append('practiceid', response?.config.practiceid);
  formData.append('departmentid', response?.config.departmentid);
  formData.append('patientid', patientIdRef.current);
  formData.append('patientname', patientNameRef.current);
  formData.append('template_name', selectedTemplate.current);
}else{
  formData.append('file', file);
  formData.append('practiceid', response?.config.practiceid);
  formData.append('departmentid', response?.config.departmentid);
  formData.append('patientid', patientIdRef.current);
  formData.append('patientname', patientNameRef.current);
 
}


// // Send the form data using the API
const submitted = await api.post('v1/ehr/recording/upload', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

  setToastAlert(true);
  setModalValue({trigger:true})
  setModalValue({patName:""})
} catch (error) {
  setModalValue({trigger:true})
  console.log(error)
}finally{
  setLoading(false)
  
}
   // Show success toast alert
  };

  return (
    <>
          {showOverlay && (
        <BasicRecordingModule 
        updatePatientInfo={updatePatientInfo}
        submitAudio={submitAudio} 
        updateSelectedTemplate={updateSelectedTemplate}
        />
        
      )}
      
  <AlertMsg
        toastobject={toastObject}
        isModalOpen={toastAlert}
        setModalOpen={setToastAlert}
      />
 <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" size={40} />
      </Backdrop>
      {/* <AddPatientmodal  isOpen={showpatientmodal} close={setPatientModal} /> */}
    </>
  );
};

export default BasicRecordingWrap;
