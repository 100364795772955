import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import arrowleft from '../../assets/arrowleft.png';
import tablertemplate from '../../assets/tabler_template.png';
import TemplatePreview from './templatepreview';
import Saveoptions from './templatesavemodal';
import api from 'api/api';
import { Backdrop, CircularProgress, Snackbar } from '@mui/material';
import ShowPdf from './showpdf';


const ModifyTemplate = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const[isPromptUploading,setIsPromptUploading]=useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);  // Track dropdown visibility
    const [selectedTemplate, setSelectedTemplate] = useState("");   // Store selected template
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previousTemplateName, setPreviousTemplateName] = useState('')
    const [cards, setCards] = useState([])
    const [notedata, setNoteData] = useState('')
    const [currentpdf,setCurrentPdf]=useState('')
    const { temp } = useParams();
    const [default_template, setDefaulttemplate] = useState('')
    const [inputPrompt, setInputPrompt] = useState('')
    const [selectedTemplateData, setSelectedTemplateData] = useState({})
    const [pdfFile,setPdfFile]=useState('')
    const [currentbolb,setcurrentblob]=useState('')
    const[showcurrentblob,setShowCurrentBlob]=useState(false)
    const [afterblob,setafterBlob]=useState('')
    const[ShowAfterBlob,setshowAfterblob]=useState(false)
    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const handleCloseToast = () => {
        setOpenToast(false);
    };
const fetchnote=async()=>{
    const getnote = await api.get(`/v1/recording/note/${temp}/get`)
    if(getnote.status==200){
        setNoteData(getnote)
    }
    const uniqueParam = new Date().getTime(); // or use a random number: Math.random() 
   // Fetch the PDF from the server with a unique parameter to prevent caching
   const response = await api.get(`/v1/basic/documents/PDF/${getnote.data[0].audiolink}?_=${uniqueParam}`, {
     responseType: "blob", // Ensure response type is 'blob' for binary data
   });
      const pdfBlob = new Blob([response], { type: "application/pdf" });
 setCurrentPdf(pdfBlob)

}
    const fetchdata = async () => {
        try {
            setLoading(true)
            const response = await api.get('/auth/me')
            if (response && response.templates && response.templates.default_template) {
                setDefaulttemplate(response.templates.default_template);
            }
            if (response && response.config) {
                const practiceIdString = String(response.config.practiceid);
                const departmentIdString = String(response.config.departmentid);
                const getnote = await api.get(`/v1/recording/note/${temp}/get`)

                setNoteData(getnote)
             
                const data = await api.post('/v1/user_templates/get_all_templates', {
                    practiceid: practiceIdString,
                    departmentid: departmentIdString,
                });
                if (data.status === 200) {
                    const templateName = getnote?.data?.[0]?.config?.template_name;
                    setPreviousTemplateName(templateName)
                    if (templateName && data?.file_names) {
                        const templateData = data.file_names.find(card => card[templateName]);

                        if (templateData) {
                            setSelectedTemplateData(templateData[templateName]);
                        }
                    }

                    setCards(data.file_names)
                } else {
                    setCards([])
                }

            }

        } catch (error) {
            console.log(error)

        } finally {
            setLoading(false)
        }

    }
    useEffect(() => {
        fetchdata()
        fetchnote()
    }, [])
    // Handle template selection
    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
        setIsDropdownOpen(false);  // Close dropdown after selecting
    };
    const closeModal = () => setIsModalOpen(false);
  
    const handleApply = async () => {
        setLoading(true)
        setIsPromptUploading(true)
        try {
          
const response = await api.put('/v1/document/post-processing',{
    "comments":inputPrompt,
    "category": "all",
    "file_name": notedata.data[0].audiolink,
    "template_name":selectedTemplate||previousTemplateName
  })
  if(response.status===200){
setInputPrompt('')
setToastMessage('Applied successfully!');
setOpenToast(true)
await fetchnote()
  }else{



  }
        } catch (error) {
console.log(error)
        } finally {
setIsPromptUploading(false)
setLoading(false)
        }



    }
    const handleButton=()=>{
        handleApply()
    }
    const saveModification=async()=>{
        setLoading(true)
try {
    const response=await api.put('/v1/document/save/post-processing',{
        "file_name": notedata?.data?.[0]?.config?.post_processing_docuemnt_name
      })
      if(response.status==200){
        setToastMessage('Submitted successfully!');
        setOpenToast(true)
      }
      await fetchnote()
} catch (error) {
    
}finally{
setLoading(false)


}

    }
    const handlecurrentblob = async () => {
        try {
           // Trigger loading spinner
           setLoading(true); 
           const uniqueParam = new Date().getTime(); // or use a random number: Math.random() 
         // Fetch the PDF from the server with a unique parameter to prevent caching
         const response = await api.get(`/v1/basic/documents/PDF/${notedata?.data[0].audiolink}?_=${uniqueParam}`, {
           responseType: "blob", // Ensure response type is 'blob' for binary data
         });
            const pdfBlob = new Blob([response], { type: "application/pdf" });
       
           // Generate a URL for the Blob
           const pdfUrl = URL.createObjectURL(pdfBlob);
       setcurrentblob(pdfBlob)
       setShowCurrentBlob(true)
           // Open the generated URL in a new tab
           //window.open(pdfUrl, "_blank");
       
         } catch (error) {
           console.error("Error fetching the PDF:", error);
         } finally {
           // Turn off loading spinner
           setLoading(false);
          
         }
       };
       const handleAfterblob = async () => {
        try {
           // Trigger loading spinner
           setLoading(true); 
           const uniqueParam = new Date().getTime(); // or use a random number: Math.random() 
         // Fetch the PDF from the server with a unique parameter to prevent caching
         const response = await api.get(`/v1/post-processing/documents/PDF/${notedata?.data?.[0]?.config?.post_processing_docuemnt_name}`, {
           responseType: "blob", // Ensure response type is 'blob' for binary data
         });
            const pdfBlob = new Blob([response], { type: "application/pdf" });
       
           // Generate a URL for the Blob
           const pdfUrl = URL.createObjectURL(pdfBlob);
       setafterBlob(pdfBlob)
       setshowAfterblob(true)
           // Open the generated URL in a new tab
           //window.open(pdfUrl, "_blank");
       
         } catch (error) {
           console.error("Error fetching the PDF:", error);
         } finally {
           // Turn off loading spinner
           setLoading(false);
          
         }
       };
    return (
        <>
            <div className="flex items-center justify-center w-full h-screen sm:h-auto md:h-auto">
                <div className="flex flex-col gap-2 w-[95%] sm:w-[80%] min-h-[85vh] h-auto md:mt-10 p-8 bg-white rounded-[4px] shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)]">
                    <div onClick={() => navigate(-1)} className="flex items-start cursor-pointer">
                        <img src={arrowleft} alt="Back Arrow" className="w-4 h-4" />
                    </div>
                    <div className="text-start text-sm font-medium">
                        Advanced Editing
                    </div>
                    <div className="flex flex-col  w-full gap-4 items-center justify-center relative">
                   
                        {/* <div className="flex flex-col items-start ml-4 flex-grow"> */}
                        <div
                            onClick={toggleDropdown}
                            className="inline-flex items-center cursor-pointer mt-4 h-10 p-2 rounded-md border border-gray-100 bg-white shadow-[0px_4px_16px_0px_rgba(20,25,26,0.08)]"
                        >
                            <img src={tablertemplate} alt="Choose Template" className="mr-2" />
                            <div className='text-sm font-normal'>{selectedTemplate ? selectedTemplate : (previousTemplateName ? previousTemplateName : "Choose Template")}</div>
                        </div>
                        
                        {/* Dropdown Menu */}
                        {isDropdownOpen && !loading && (
                          <div className="absolute  left-[43%] top-[30%] mt-2 max-h-[80vh] overflow-auto custom-scrollbar bg-white border border-gray-300 rounded-md shadow-md w-40 z-10">
                          <ul className="list-none p-2">
                              {cards?.map((card, index) => {
                                  const documentKey = Object.keys(card)[0];
                                  const documentData = card[previousTemplateName];
                      
                                  return (
                                      <li
                                          key={index}
                                          className={`p-2 cursor-pointer text-start text-xs truncate ${documentKey === previousTemplateName ? 'bg-gray-100' : ''
                                              } hover:bg-gray-100`}
                                          onClick={() => handleTemplateSelect(documentKey)}
                                          title={documentKey} // Tooltip for full text visibility
                                      >
                                          {documentKey}
                                      </li>
                                  );
                              })}
                          </ul>
                      </div>
                      
                        )}
<div className='w-full'> 
    <div className='text-sm sm:ml-[11%] w-full  text-start font-medium mb-2'>Additional Instructions</div>
                           
                            <textarea
                                onChange={(e) => setInputPrompt(e.target.value)}
                                value={inputPrompt}
                                className="w-full sm:w-[80%] p-3 border border-gray-300 rounded-md shadow-sm"
                                placeholder="Enter your template details here..."
                                rows="1"
                            ></textarea>
                        {/* </div> */}</div>
                    </div>

                    {/* Preview Button */}
                    <div className='flex items-center gap-4 justify-center mt-4'>
                        <button
                            onClick={() => handleButton()}
                            disabled={!(inputPrompt && !isPromptUploading) && !(selectedTemplate && selectedTemplate !== previousTemplateName)}
                            className={`px-16 py-2 rounded-md font-[Tahoma] text-xs ${(inputPrompt&& !isPromptUploading)||(selectedTemplate?(selectedTemplate!==previousTemplateName):false) ? 'bg-[#1C2A3A] text-white' : 'bg-gray-400 text-gray-700 cursor-not-allowed'
                                }`}
                        >
                            {isPromptUploading?"Generating":"Apply"}
                        </button>
                        {notedata?.data?.[0]?.config?.temp_note_json?<button
                            onClick={() =>saveModification()}
                            disabled={!notedata?.data?.[0]?.config?.temp_note_json} // Disable if inputPrompt is empty or falsy
                            className={`px-16 py-2 rounded-md font-[Tahoma] text-xs ${notedata?.data?.[0]?.config?.temp_note_json ? 'bg-[#1C2A3A] text-white' : 'bg-gray-400 text-gray-700 cursor-not-allowed'
                                }`}
                        >
                           Save
                        </button>:<></>}

                    </div>

                    {/* Before and After Previews */}
                    <div className="w-full flex items-center justify-center mt-4 flex-wrap gap-4">
  {/* Current PDF Section */}
  <div
    className={`w-full ${
      notedata?.data?.[0]?.config?.temp_note_json ? "sm:w-[45%]" : "w-full"
    } flex items-center justify-center flex-col`}
  >
    <div className="text-sm text-center mr-6 font-medium mb-2">Current</div>
    <button
      onClick={() => handlecurrentblob()}
      className="flex items-center w-[100px] mb-2 justify-center gap-2 p-2 h-10 text-white text-xs font-semibold rounded-lg bg-[#1C2A3A]"
    >
      Preview
    </button>
    {notedata?.data?.[0]?.note_json ? (
      <TemplatePreview data={notedata?.data?.[0]?.note_json} />
    ) : null}
  </div>

  {/* After PDF Section */}
  {notedata?.data?.[0]?.config?.temp_note_json && (
    <div
      className={`w-full ${
        notedata?.data?.[0]?.note_json ? "sm:w-[45%]" : "w-full"
      } flex flex-col justify-center items-center`}
    >
      <div className="text-sm text-center mr-6 font-medium mb-2">After</div>
      <button
        onClick={() => handleAfterblob()}
        className="flex items-center w-[100px] mb-2 justify-center gap-2 p-2 h-10 text-white text-xs font-semibold rounded-lg bg-[#1C2A3A]"
      >
        Preview
      </button>
      <TemplatePreview data={notedata?.data?.[0]?.config?.temp_note_json} />
    </div>
  )}
</div>

                </div>
            </div>
            <ShowPdf ispdfshow={showcurrentblob} setIspdfShow={setShowCurrentBlob} pdfBlob={currentbolb}/>
            <ShowPdf ispdfshow={ShowAfterBlob} setIspdfShow={setshowAfterblob} pdfBlob={afterblob}/>
            {isModalOpen ? <Saveoptions closeModal={closeModal} /> : <></>}
            <Snackbar
    open={openToast}
    autoHideDuration={6000}
    onClose={handleCloseToast}
    message={toastMessage}
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }}
    ContentProps={{
        style: {
            backgroundColor: 'green', // Set background color to green
        },
    }}
/>


            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" size={40} />
            </Backdrop>
           
        </>
    );
};

export default ModifyTemplate;
