import React from 'react';
import { DateRange } from 'react-date-range'; 
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import CloseIcon from '@mui/icons-material/Close';
const DatePicker = ({ datestate, handledateChange,handleDateClose, value, setValue ,setIsPickerOpen }) => {
    const RangeSlider = ({value,setValue}) => {


        const handleChangerange = (event, newValue) => {
          setValue(newValue);
        };
      
        return (
          <Box sx={{ width: 200 }}>
            <Slider
              getAriaLabel={() => 'Time range'}
              value={value}
              onChange={handleChangerange}
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => formatTime(val)}
              min={0}
              max={1440} // Total minutes in a day (24 hours * 60 minutes)
              step={10} // Adjust this to set the granularity of the slider (e.g., 10 minutes)
              marks={[
                // { value: 0, label: '00:00' },
                // { value: 360, label: '06:00' },
                // { value: 720, label: '12:00' },
                // { value: 1080, label: '18:00' },
                // { value: 1440, label: '24:00' },
              ]}
            />
            <div className='text-sm'>
              {formatTime(value[0])} - {formatTime(value[1])}
            </div>
          </Box>
        );
      };

      const formatTime = (val) => {
        const hours = Math.floor(val / 60);
        const minutes = val % 60;
        const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
        return `${hours % 24}:${formattedMinutes}`; // Format time as HH:MM
      };
  return (
    <div className="bg-white p-4 md:p-2 sm:p-2 rounded-lg shadow-lg ">
      <div className=' flex justify-end' >
      <CloseIcon  onClick={() =>handleDateClose()} style={{width:20 ,color:'#ccc',cursor:'pointer'}}  />
      </div>
      {/* Date Picker */}
      <DateRange
        editableDateInputs={true}
        onChange={(item) => handledateChange([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={datestate}
      />
      {/* Slider */}
      <div className='flex justify-center'>
      <RangeSlider value={value} setValue={setValue} />
      </div>
    </div>
  );
};

export default DatePicker;
