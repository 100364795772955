import React from "react";

const TextModal = ({ isOpen, setTextModal, notes }) => {
  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[90%] max-w-md max-h-[980vh] overflow-y-auto custom-scrollbar relative">
        {/* Close Icon */}
        <button
          onClick={() => setTextModal(false)}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 text-xl"
        >
          &#x2715; {/* Unicode for "X" */}
        </button>

        {/* Modal Content */}
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Notes</h3>
        <p className="text-base text-start text-gray-700 leading-relaxed break-words">{notes}</p>
      </div>
    </div>
  );
};

export default TextModal;
