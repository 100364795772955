import React, { useState, useMemo, useCallback, useRef } from 'react';
import searchicon from '../../../assets/search.png';
import '../../../pages/landing/scribefilter.css'; // Import the CSS file
import { debounce } from 'lodash'; // Import debounce from lodash
import api from '../../../api/api'; // Assuming you have an API instance
import { parseISO, startOfDay } from 'date-fns'; // Import from date-fns
import { toZonedTime } from 'date-fns-tz'; // Only if you need this for timezone
import Filtersdt from './filtersdt';
import useModalStore from 'zustand/zustandstore';
const BasicFilter = ({ handletimeStamp,handleScribeFilter, payload }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [result, setResult] = useState([]);
  const inputRef = useRef(null);
const mydata=useModalStore()
  // Function to fetch search results
  const fetchSearchResults = useCallback(
    debounce(async (query) => {
      if (!query) {
        setResult([]); // Clear results if query is empty
        return;
      }

      try {
        const response = await api.post(
          `/v1/${mydata.usertype === 'BUSINESS' ? 'business' : 'basic'}/recording/search/suggestions`,
          {
            search_filter: query,
            filter_array: payload,
          }
        );
        

        if (response.status === 200 && response.data) {
          setResult(response.data); // Update result state with fetched data
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }, 500), // Debounce delay of 1 second
    [payload]
  );

  // Handle search input
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query) {
      fetchSearchResults(query); // Call debounced function with the query
    } else {
           setResult([]); // Clear the result when there's no query
    }
  };

  // Close dropdown on outside click
  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setResult([]); // Hide results when clicking outside the input
    }
  };

  // Add event listener to detect clicks outside
  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative w-full items-center flex gap-4 sm:gap-0 flex-col sm:flex-row  justify-between">
      <div
        style={{
          borderRadius: "8px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        }}
        className="flex sm-w-auto min-w-[300px] sm:min-w-[400px] sm-px-1 h-11 items-center pr-0 gap-2 pl-4 sm:pr-[138px] py-2 bg-[#FFF] rounded-lg border border-solid border-[#DCDFE3]"
        ref={inputRef} // Reference for input and dropdown
      >
        <img src={searchicon} alt="Search Icon" className="w-4 h-4" />
        <input
          type="text"
          placeholder={mydata.usertype!=='BUSINESS'?"Search Patient by Name or ID":"Search Conversations"}
          value={searchQuery}
          onChange={handleSearch}
          className="flex-1 [font-family:'Inter-Regular',Helvetica] font-normal text-[#0000004c] text-sm tracking-[0] leading-5 bg-transparent outline-none"
        />
      </div>

      {/* Display the top 5 search suggestions as an overlay */}
      {(result.length > 0&&searchQuery) && (
        <ul className="custom-scrollbar absolute top-12 z-10 left-10 sm:left-0  mt-1 bg-white border border-solid border-[#DCDFE3] rounded-lg max-h-40 overflow-y-auto shadow-lg">
          {result.map((record) => (
      <li
  key={record.patientid}
  className="p-2 text-left hover:bg-gray-200 cursor-pointer"
  onClick={() => {
    const searchQueryValue = mydata.usertype === 'BUSINESS' 
      ? record.name 
      : record.patient_name;

    const filterValue = mydata.usertype === 'BUSINESS' 
      ? record.id 
      : record.patient_name;

    setSearchQuery(searchQueryValue); 
    handleScribeFilter(filterValue);              

    setSearchQuery('');  
    setResult([]);       
  }}
>
  {mydata.usertype === 'BUSINESS'
    ? `${record.name} - ${record.id}`
    : `${record.patient_name} - ${record.patientid}`}
</li>

       
          ))}
        </ul>
      )}


    <div>
<Filtersdt
handletimeStamp={handletimeStamp}
/>
    </div>
    </div>
  );
};

export default BasicFilter;
