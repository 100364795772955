import React, { useState, useEffect, useRef } from 'react';
import { FaPause, FaStop, FaPlay, FaTimesCircle } from 'react-icons/fa';
import CancelIcon from '@mui/icons-material/Cancel';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ToastModal from 'alerts/recordingscreen/toastmodal';
import DeleteToastModal from 'alerts/recordingscreen/deletetoast';
import redcircle from "../../assets/redcircle.png";
import pause from "../../assets/Pause.png";
import play from "../../assets/play.png";
import EditScreenWaves from './archivemusicwaves/editscreenwave/editscreenwave';
import ArchiveDeleteToastModal from 'alerts/recordingscreen/archeivedeletetoast';

const IndividualRecording=({screenname,setToast,setIsdelted,submitIndAudio, setToastmsg,setIndIsRecording})=>{
    const [isRecording, setIsRecordingState] = useState(false);
    const [recordingTime, setRecordingTime] = useState("00:00"); 
    const [isPaused, setIsPaused] = useState(false);
    const[mytoast,setmytoast]=useState(false)
    const mediaRecorderRef = useRef(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [showtoast,setShowtoast]=useState(false)
    const audioChunks = useRef([]);
    const startTimeRef = useRef(null); // For when recording started
    const elapsedTimeRef = useRef(0); // To store total elapsed time
    const animationFrameRef = useRef(null);
    const[persistRecordTime,setPersistRecordTime]=useState('')
    const[showsavedtime,setSavedTime]=useState(false)
    const [toastobject,setToastObject]=useState({
      icon:DangerousIcon,
      text1:"Something Went Wrong",
      text2:""
  
    })
    const updateRecordingTime = () => {
      const now = Date.now();
      const elapsedTime = Math.floor((now - startTimeRef.current + elapsedTimeRef.current) / 1000); // Add elapsed time to current time
      const minutes = Math.floor(elapsedTime / 60);
      const seconds = elapsedTime % 60;
      setRecordingTime(
        `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
      );
  
      animationFrameRef.current = requestAnimationFrame(updateRecordingTime); // Continue updating timer
    };
  
  
  
    useEffect(() => {
      updateRecordingTime();
    }, []);
  
    const startRecording = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMediaStream(stream); 
      mediaRecorderRef.current = new MediaRecorder(stream);
  
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };
  
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });
        submitIndAudio(audioBlob);     
        audioChunks.current = [];
      };
  
      mediaRecorderRef.current.start();
      startTimeRef.current = Date.now(); // Record start time
      setIndIsRecording(true);
      setIsPaused(false);
      updateRecordingTime(); 
    };
  
    const stopRecording = () => {
      if (!isRecording) return;
      mediaRecorderRef.current.stop();  
      setIsPaused(false);
      cancelAnimationFrame(animationFrameRef.current); // Stop timer
      setIndIsRecording(false);
      
    };
  
    const resumeRecording = () => {
      if (!mediaRecorderRef.current) return;
  
      if (mediaRecorderRef.current.state === "paused") {
        mediaRecorderRef.current.resume();
        setIsPaused(false);
        startTimeRef.current = Date.now(); // Reset start time
        setSavedTime(false)
        updateRecordingTime(); // Resume timer
      }
    };
  
    const pauseRecording = () => {
      if (!mediaRecorderRef.current) return;
  
      if (mediaRecorderRef.current.state === "recording") {
        mediaRecorderRef.current.pause();
        setIsPaused(true);
        elapsedTimeRef.current += Date.now() - startTimeRef.current; // Update total elapsed time
        setPersistRecordTime(recordingTime)
        setSavedTime(true)
        cancelAnimationFrame(animationFrameRef.current); // Stop timer
      }
    };
  
    useEffect(() => {
      if (!isRecording) {
        startRecording();
      }
  
      return () => {
        cancelAnimationFrame(animationFrameRef.current); // Clean up timer on component unmount
      };
    }, [isRecording]);
  
    useEffect(() => {
      // Automatically start recording when the component mounts
      setIsRecordingState(true);
      setIndIsRecording(true);
    }, [setIndIsRecording]);
  
    // Toggle pause/resume
    const handlePauseResume = () => {
      if (isPaused) {
        resumeRecording();
      } else {
        pauseRecording();
      }
    };
  
    // Stop recording
    const handleStopRecording = () => {
      stopRecording();
    };
  const handledelete= ()=>{
    setToastmsg({
      icon: CancelIcon,
      text1: "Your Recording is Successfully Cancelled!",
      text2: "",
    });
  setToast(true)
  setIndIsRecording(false)
  submitIndAudio('')
  }
  
    return (
      <>
      <div className="flex flex-col items-center justify-center">
      <div style={{ fontWeight: 500 }} className="text-sm text-center mb-4">{screenname}</div>
        <div className="flex items-center justify-center gap-8 mb-3">
  
  {isRecording&&  <button
                 onClick={()=>{
                  setIsdelted(true)
                  setmytoast(true)
                }
                }
                className="bg-[#1c2a3ae6] rounded-lg p-2 cursor-pointer"
              >
                <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-xs text-center tracking-[0] leading-[normal] whitespace-nowrap">
                  Delete
                </div>
              </button>}
  
  
          {isRecording && !isPaused ? (
          <div
          className="relative cursor-pointer"
          onClick={() => (handlePauseResume())}
        >
          <img
            src={redcircle}
            alt="Red Circle"
            className="w-[50px] h-[50px] cursor-pointer"
          />
          <img
            src={pause}
            alt="Pause/Play"
            className="absolute inset-0 m-auto w-[24px] h-[24px]"
          />
        </div>
          ) : isPaused ? (
            <div
            className="relative cursor-pointer"
            onClick={() => (handlePauseResume())}
          >
            <img
              src={redcircle}
              alt="Red Circle"
              className="w-[50px] h-[50px] cursor-pointer"
            />
            <img
              src={play}
              alt="Pause/Play"
              className="absolute inset-0 m-auto w-[24px] h-[24px]"
            />
          </div>
          ) : null}
          {isRecording&& <button
                 onClick={
                    ()=>{
                        setIsdelted(false)
                        handleStopRecording()
                    
                    }}
                className="bg-[#1c2a3ae6] cursor-pointer rounded-lg p-2"
              >
                <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-xs text-center tracking-[0] leading-[normal] whitespace-nowrap">
                  Save
                </div>
              </button>}
  
      </div>
      <div className="h-8 overflow-hidden">
       <EditScreenWaves  mediaStream={mediaStream} isPaused={isPaused} />
        </div>
        <p className="ml-2 text-sm"> {recordingTime.length !== 5 ? "00:00" : (showsavedtime ? (showsavedtime ? persistRecordTime : recordingTime) : recordingTime)}
        </p>
    
      </div>
      <ArchiveDeleteToastModal
          handledelete={handledelete}
          isModalOpen={mytoast}
          setModalOpen={setmytoast}
        />    
      
      </>
    );
}
export default IndividualRecording