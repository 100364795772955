import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faSave, faGripVertical, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { Preview } from '@mui/icons-material';
import DocPreview from './preview';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import api from 'api/api';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import ToastMessage from 'utils/muialerts/righttoptoast';
import arrowleft from '../../assets/arrowleft.png';
import ShowPdf from './showpdf';
import { set } from 'date-fns';
import PdfView from './pdfview';
import TemplateDeleteModal from './templatedeletemodal';
import WarningModal from './modals/warningmodal';
const  EditTemplate = () => {
    const [editingId, setEditingId] = useState(null);
    const { name,flow} = useParams();
    const[isSaving,setIsSaving]=useState(false)
    const location = useLocation();
    const[isWarningModal,setIsWarningModal]=useState(false)
    const { extractionresponse } = location.state || {};
    const [pId,setPId]=useState('')
    const[pdfloading,setPdfLoading]=useState(false)
    const [ispdfshow,setIspdfShow]=useState(false)
    const[deletemodal,setDeletemodal]=useState(false)
    const [visibleTextAreas, setVisibleTextAreas] = useState([]);
    const [alertmessage, setAlertMessage] = useState('');
    const [open, setOpen] = useState(false);
    const[pdfblob,setPdfBlob]=useState('')
    const[Initialdata,setInitialdata]=useState([])
    const [default_template,setDefaulttemplate]=useState('')
    const [initialtemplatename,setInitialTemplateName]=useState('')
    const[alltemplates,setAllTemplates]=useState([])
    const [severity, setSeverity] = useState('success');
    const[dId,SetDId]=useState('')
    const [loading,setLoading]=useState(false)
    const [currentfield,setcurrentField]=useState()
    const[isEdit,setIsEdit]=useState(false)
    const [expandedFields, setExpandedFields] = useState([]);
    const[data,setData]=useState([])
    const[isShowPreview,setIsShowPreview]=useState(false)
    const navigate=useNavigate()
    const [templatename,setTemplateName]=useState('')
    const [historyTexts, setHistoryTexts] = useState([]);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
      const toggleVisibility = (id) => {
        setVisibleTextAreas((prev) =>
            prev.includes(id) ? prev.filter((visibleId) => visibleId !== id) : [...prev, id]
        );
    };
    const handleAddField = () => {
      const newId = historyTexts.length > 0 
          ? Math.max(...historyTexts.map(item => item.id)) + 1 
          : 1; // Generate a new unique id
  
      const newField = {
          id: newId,
          title: '',
          text: ''
      };
  
    
      setHistoryTexts([...historyTexts, newField]); // Add the new field
      setVisibleTextAreas([...visibleTextAreas, newId]); // Ensure the new field is visible
      toggleEdit(newId)
  };
    const fetchdata=async()=>{
        try {
          setLoading(true)
          const response = await api.get('/auth/me')
          if (response && response.templates && response.templates.default_template) {
            setDefaulttemplate(response.templates.default_template);
      
  }
          if (response && response.config) {
            const practiceIdString = String(response.config.practiceid); 
            const departmentIdString = String(response.config.departmentid);
          setPId(practiceIdString)
          SetDId(departmentIdString)
            const responsedata = await api.post('/v1/user_templates/get_all_templates', {
              practiceid: practiceIdString,
              departmentid: departmentIdString,
            });
            const basictemp=await api.get('/v1/user_templates/default_templates')

if (responsedata.status === 200) {
    let basic=[]
    if(basictemp.status==200){
     basic=basictemp.file_names
  }
    const filteredData =[...responsedata.file_names, ...basic].reduce((acc, obj) => {
      const entry = Object.entries(obj).find(([key, value]) => key === name);
      if (entry) {
        acc[entry[0]] = entry[1]; // Add the key-value pair to the accumulator
      }
      return acc;
    }, {});
   
     setData(filteredData)    
    const templateData = filteredData[Object.keys(filteredData)[0]];
  
      const cleanedName = Object.keys(filteredData)[0].replace(/[^\w-]/g, "_");
    setTemplateName(cleanedName)
   setInitialTemplateName(cleanedName)
   const newEntries = Object.keys(templateData).map((key, index) => {
    // const isNested = typeof templateData[key] === 'object';
    const isNested = typeof templateData[key] === 'object' && !Array.isArray(templateData[key]);

    return {
        id: historyTexts.length + index + 1, // Generate a new unique id
        title: key,
        text: templateData[key], 
        isNested
    };
});
console.log([...newEntries],'new')
 setInitialdata([...newEntries])
setHistoryTexts([...newEntries]);
  }  
            else{
             setData([])
            }
         
          }
          
        } catch (error) {
            setData([])
          console.log(error)
    
        }finally{
          setLoading(false)
        }
    
      }
      const fetchuser=async()=>{
        const response = await api.get('/auth/me')
    
        if (response && response.config) {
          const practiceIdString = String(response.config.practiceid); 
          const departmentIdString = String(response.config.departmentid);
        setPId(practiceIdString)
        SetDId(departmentIdString)
      }
    }
    const renameKeyByIndex = (obj, index, newKey) => {
        const entries = Object.entries(obj.text); // Get all key-value pairs of the object
        if (index >= 0 && index < entries.length) { // Ensure the index is valid
          const newEntries = entries.map(([key, value], idx) => 
            idx === index ? [newKey, value] : [key, value] // Replace the key at the index
          );
          obj.text = Object.fromEntries(newEntries); // Recreate the object from updated entries
        }
        return obj;
      };
      
      const handleNestedFieldChange = (id, nestedKey, e,nestedindex) => {
        const updatedTexts = historyTexts.map((item) => {
       if (item.id === id) {
           const updatedObject = renameKeyByIndex(item, nestedindex, e);
                              
         return updatedObject
       }
  
       return item;
   });
setIsEdit(false)
setcurrentField('')
   // Set the updated history texts
   setHistoryTexts(updatedTexts);
};


useEffect(()=>{
    if(flow==='ind'){
        fetchdata()
    }else if(flow==='self'){
        setData([{}])
        const cleanedName = name.replace(/[^\w-]/g, "_");
    setTemplateName(cleanedName)
            fetchuser()
        const newEntries = Object.keys(extractionresponse).map((key, index) => ({
            id: historyTexts.length + index + 1, // Generate a new unique id
            title: key,
            text: extractionresponse[key].replace(/{{/g, '').replace(/}}/g, '').trim() // Remove {{ and }} symbols
        }));
 
     
        setHistoryTexts([...newEntries]);
    }
},[])

   

    const handleTitleChange = (id, e) => {
        const updatedTexts = historyTexts.map((item) =>
            item.id === id ? { ...item, title: e.target.value } : item
        );
        setHistoryTexts(updatedTexts);
    };
   

    const toggleEdit = (id) => {
        setEditingId((prev) => (prev === id ? null : id));
    };

    const handleSave = () => {
        setEditingId(null);
    };

    const handleDelete = (id) => {
        const updatedTexts = historyTexts.filter(item => item.id !== id);
        setHistoryTexts(updatedTexts);
    };
    const testingsave = () => {
        const finalObj = historyTexts.reduce((acc, curr) => {
            acc[curr.title] = curr.isNested && typeof curr.text === 'object' ? curr.text : curr.text;
            return acc;
        }, {});
    
        console.log("finalobj", finalObj);
    
        // Log the order of fields
        console.log("Order of fields:", Object.keys(finalObj));
    };
    

const handleFinalSave=async()=>{
setIsSaving(true)
try {
    const result = historyTexts.reduce((acc, obj) => {
        acc[obj.title] = `{{${obj.text}}}`;

        return acc;
      }, {});
      const finalObj = historyTexts.reduce((acc, curr) => {
        acc[curr.title] = curr.isNested && typeof curr.text === 'object' ? curr.text : curr.text;
        return acc;
    }, {});
    const  savetemplate=await api.post('/v1/user_templates/save_template',{
        "practiceid": pId,
        "departmentid":dId,
       "new_filename": templatename,
        "old_filename":initialtemplatename,
        "note_json": finalObj
      
      })
      if(savetemplate.status===200){
        setSeverity('success')
        setOpen(true);
        setAlertMessage('Successfully saved the template');
      }else{
        setSeverity('error')
        setOpen(true);
        setAlertMessage('Something Went Wrong')
      }

} catch (error) {
    console.log(error)
}finally { 
setIspdfShow(false)
const sanitizedTemplateName = templatename.replace(/[^\w-]/g, "_");

if (sanitizedTemplateName !== name) {
  navigate(`/edit-temp/${sanitizedTemplateName}/ind`, { replace: true });
  window.location.reload(); // Reload the page if necessary
}
if(name==='HnP'&&(sanitizedTemplateName=='HnP')){
    navigate(`/edit-temp/HnP_Custom/ind`, { replace: true });
    window.location.reload(); 
}


if((flow=='ind'||isShowPreview)){
fetchdata()
}else{
  fetchdata()
}
// fetchdata()
loadpdf()
setIsSaving(false)
if(isWarningModal){
  navigate(-1)
}
  
}

    
}
const handleShowpdf = async (filename) => {
setPdfLoading(true)
    try {
  
  
      // Append a timestamp to avoid caching
      const timestamp = new Date().getTime();
  
      const response = await api.post(
        `/v1/user_templates/sample_note/PDF?timestamp=${timestamp}`, // Add timestamp here
        {
          departmentid: dId,
          practiceid: pId,
          filename: templatename,
        },
        {
          responseType: "blob",
        }
      );
  
      const pdfBlob = new Blob([response], { type: "application/pdf" });
  
      setPdfBlob(pdfBlob);
      setIspdfShow(true);
    } catch (error) {
      console.log(error);
    } finally {
     setPdfLoading(false)
    }
  };
  const loadpdf = async (filename) => {
    try {

  
      // Append a timestamp to avoid caching
      const timestamp = new Date().getTime();
  
      const response = await api.post(
        `/v1/user_templates/sample_note/PDF?timestamp=${timestamp}`, // Add timestamp here
        {
          departmentid: dId,
          practiceid: pId,
          filename: templatename,
        },
        // {
        //   responseType: "blob",
        // }
      );
 
      if (response.status === 201) {
          // This will now trigger correctly if status !== 201
        return; // Exit early if the status isn't as expected
      }
      const pdfBlob = new Blob([response], { type: "application/pdf" });
  
    
        setIsShowPreview(true)
     
   ;
    } catch (error) {
      console.log(error);
    } finally {
     
    }
  };
  const handleDel=async()=>{
    try {
      setDeletemodal(false)
        setLoading(true)
        const response=await api.delete('/v1/user_templates/remove',{
            data:{
                "filename":templatename
            }
          
        })
        if(response.status==200){
          setSeverity('success')
        setAlertMessage('File Sucessfully Deleted');
        setOpen(true)
            setTimeout(() => {
        navigate('/temp');
    }, 2000);
        }else if(response.status==201){
          setSeverity('error')
          setAlertMessage(response.message);
          setOpen(true)
        }
        else{
          setSeverity('error')
            setAlertMessage('something went wrong');
            setOpen(true)
        }
    } catch (error) {
        
    }finally{
setLoading(false)
    }

  }
  const toggleFieldExpansion = (id) => {
    setExpandedFields((prev) =>
        prev.includes(id) ? prev.filter((fieldId) => fieldId !== id) : [...prev, id]
    );
};
   
const toggleEditNested = (id, nestedKey) => {
    setEditingId((prev) => (prev === `${id}-${nestedKey}` ? null : `${id}-${nestedKey}`));
};

  const handleback = () => {
    if (flow === 'ind' || isShowPreview) {
          const areArraysEqual = (arr1, arr2) => {
            // Check if lengths are the same
            if (arr1.length !== arr2.length) {
                console.log("Arrays have different lengths.");
                return false;
            }
if(templatename!==initialtemplatename){
  return 
}

            // Check if each corresponding object's title matches
            const titlesMatch = arr1.every((obj, index) => obj.title === arr2[index]?.title);

            if (!titlesMatch) {
                console.log("Titles do not match for all objects.");
            }

            return titlesMatch;
        };

        if (areArraysEqual(Initialdata, historyTexts)) {
          navigate(-1);
        } else {
          setIsWarningModal(true)
        }
    } else {
          setIsWarningModal(true)
    }
};

  useEffect(()=>{
if(dId&&pId&&!isShowPreview){
    loadpdf()
}
  },[dId,pId])

  const handleButtons=(x)=>{
    if(x==='unsave'){
      navigate(-1)
    }else if(x==='save'){
      handleFinalSave()
    }

  }
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("draggedIndex", index);
};

const handleDrop = (e, dropIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData("draggedIndex"), 10);
    
    if (draggedIndex !== dropIndex) {
        const updatedHistoryTexts = [...historyTexts];
        
        // Swap items
        const [draggedItem] = updatedHistoryTexts.splice(draggedIndex, 1);
        updatedHistoryTexts.splice(dropIndex, 0, draggedItem);
        
        setHistoryTexts(updatedHistoryTexts); // Update state with reordered items
    }
};


    return (<>
     <div className="flex items-center justify-center w-full h-full">
            {loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" size={40} />
                </Backdrop>
            ) : data.length === 0 ? (
                <div className="text-center text-red-500 font-medium text-lg">
                 Something went wrong, please try again.
                </div>
            ) : (
                <div
  className={`w-[95%] min-h-[500px] h-auto md:mt-10 md:mb-10 p-12 bg-white rounded-[20px] shadow-[0px_2px_13px_#00000040] flex flex-col sm:flex-row ${
    ispdfshow ? 'md:w-[90%]' : 'md:w-[50%]'
  }`}
>
                    {/* Your regular component content goes here if data is available  */}
                    <div onClick={()=>
                    handleback()                
                  
                  }
              className="flex items-start cursor-pointer relative bottom-6 right-4 ">
              <img src={arrowleft} alt="Down Arrow" className="w-6 h-6" />
            </div>
                    <div className="flex-1 pr-4">
                        
                        <div className="flex flex-col">
                        <div className="flex flex-col">
                            
                        <div className="text-base font-medium [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-start">
                            Template Name
                            <span className="label required text-red-400"> *</span>
                        </div>
                        <div className="flex flex-row flex-wrap items-center gap-3 mt-2 mb-4 w-full">
  {/* Input with Save Button */}
  <div className="relative flex-grow sm:w-[40%]">
    <input
      id="templateName"
      type="text"
      className="border border-gray-300 text-sm w-full rounded-full p-2 pr-[90px]"
      placeholder="Template name"
      value={templatename}
      onChange={(e) => {
        const value = e.target.value;
        setTemplateName(value);
      }}
    />
    <button
      onClick={() => handleFinalSave()}
    // onClick={() => testingsave()}
      className="absolute right-0 top-0 h-full bg-[#1C2A3A] text-white text-sm font-semibold rounded-r-full px-6 border-l border-gray-300"
    >
      Save
    </button>
  </div>

  {/* Delete Button */}
  {(flow === 'ind' || isShowPreview) && default_template !== name && (
    <button
      onClick={() => setDeletemodal(true)}
      className="flex items-center justify-center gap-2 h-10 px-4 text-white text-xs font-semibold rounded-lg bg-red-600"
    >
      Delete
    </button>
  )}

  {/* Show Preview Button */}
  {(flow === 'ind' || isShowPreview) && !ispdfshow && (
    <button
      onClick={() => handleShowpdf()}
      disabled={pdfloading}
      className="flex items-center justify-center gap-2 h-10 px-4 text-white text-xs font-semibold rounded-lg bg-[#1C2A3A] hover:bg-[#173041]"
    >
      {pdfloading ? 'Getting data' : 'Show Preview'}
    </button>
  )}
</div>


{historyTexts.map(({ id, title, text, isNested },index) => (
                                    <div key={id} 
                                    draggable={true} // Only top-level items are draggable
                                                onDragStart={(e) =>handleDragStart(e, index)}
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={(e) => handleDrop(e, index)}
                                    className="flex flex-col mt-4">
                                        <div className="flex items-center justify-between">

                                            <div className="flex items-center w-full">
                                                                                       {editingId === id ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={title}
                                                            onChange={(e) => handleTitleChange(id, e)}
                                                            className="border border-gray-300 rounded-md p-2 w-full "
                                                            autoFocus
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={faSave}
                                                            className="ml-2 text-green-500 cursor-pointer"
                                                            onClick={handleSave}
                                                        />
                                                    </>
                                                ) : (
                                                    <div className="w-full flex items-center justify-between h-10 p-2 border border-gray-300 rounded-md">
                                                        <div 
                                                          style={{ userSelect: 'none' }}
                                                        className="text-sm font-medium text-black tracking-[0] leading-[normal] whitespace-nowrap text-start no-select">
                                                            {title}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex gap-4">
                                            {isNested && (
                                                    <FontAwesomeIcon
                                                        icon={faChevronDown}
                                                        className={`cursor-pointer ml-2 text-gray-500 ${expandedFields.includes(id) ? 'rotate-180' : ''}`}
                                                        onClick={() =>{

                                                            toggleFieldExpansion(id)                                                        
                                                        }}
                                                    />
                                                )}
                                             <FontAwesomeIcon
    icon={faPen}
    className={`text-gray-500 cursor-pointer ${!isNested ? 'ml-2' : ''}`}
    onClick={() => toggleEdit(id)}
/>

                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="text-red-500 cursor-pointer"
                                                    onClick={() => handleDelete(id)}
                                                />
                                               <FontAwesomeIcon 
                                               
                                               className='text-gray-500 cursor-pointer'
                                               icon={faGripVertical} />
                                            </div>
                                        </div>
                                        {expandedFields.includes(id) && isNested && (
    <div className="ml-4 mt-2">
        {Object.keys(text).map((nestedKey,nestedindex) => (
            <div key={nestedKey} className="flex items-center justify-between mb-2">
                {editingId === `${id}-${nestedKey}` ? (
                    <>
                        <input
                            type="text"
                            value={isEdit?currentfield:nestedKey} // Now editing the key (nestedKey)
                            onChange={(e) => {
                                // handleNestedFieldChange(id, nestedKey, e,nestedindex)
                            setcurrentField(e.target.value)
                            }} // Update the key
                            className="border border-gray-300 rounded-md p-2 w-full"
                            autoFocus
                        />
                        <FontAwesomeIcon
                            icon={faSave}
                            className="ml-2 text-green-500 cursor-pointer"
                            onClick={() => {
                               
                                handleNestedFieldChange(id, nestedKey, currentfield,nestedindex)
                                // handleSaveNestedField(id, nestedKey)
                            }}
                        />
                    </>
                ) : (
                    <div className="w-full flex items-center justify-between h-10 p-2 border border-gray-300 rounded-md">
                        <div className="text-sm font-medium text-black tracking-[0] leading-[normal] whitespace-nowrap text-start">
                            <strong>{nestedKey}</strong>
                             </div>
                        <FontAwesomeIcon
                            icon={faPen}
                            className="ml-2 text-gray-500 cursor-pointer"
                            onClick={() => {
                                setcurrentField(nestedKey)
                                setIsEdit(true)
                                toggleEditNested(id, nestedKey)
                            }} 
                        />
                    </div>
                )}
            </div>
        ))}
    </div>
)}



                                    </div>
                                ))}
                        <div  className='w-full flex items-center justify-start mt-4 gap-2 cursor-pointer'>
<AddIcon onClick={handleAddField} />
<div onClick={handleAddField} className='text-gray-600'>Add Field</div>

                        </div>
                    </div>
                        </div>
                    </div>

                    {/* <div className="w-full sm:w-[605px] mb-2 ml-4">
                        <h3 className="text-lg font-semibold mb-2 opacity-55">Preview</h3>
                        <div className="border border-gray-300 rounded-md shadow-[0px_2px_13px_#00000040] p-4 h-full min-h-[80vh]">
                            <DocPreview data={historyTexts}/>
                         
                        </div>

                    </div> */}
                   {ispdfshow? <div className="w-full sm:w-[605px] mb-2 ml-4">
                    <h3 className="text-lg font-semibold mb-2 opacity-55">Preview</h3>
                    <PdfView pdfBlob={pdfblob}/>
                    </div>:<></>}
                </div>
            )}
        </div>
        {/* <ShowPdf ispdfshow={ispdfshow} setIspdfShow={setIspdfShow} pdfBlob={pdfblob}/> */}
        <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
        <Backdrop
  sx={{
    zIndex: (theme) => theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  }}
  open={isSaving}
>
  <div
    className="text-white font-mono text-xl font-bold overflow-hidden whitespace-nowrap border-r-2 border-white"
    style={{
      maxWidth: "28ch", // Match the length of "Generating Prompt .."
      animation: "typing 2s steps(18, end) infinite, blink 0.5s step-end infinite",
    }}
  >
    Generating Sample Note ..
  </div>
  <style>
    {`
      @keyframes typing {
        from {
          width: 0;
        }
        to {
          width: 28ch; /* Matches the length of the text */
        }
      }
      @keyframes blink {
        from, to {
          border-color: transparent;
        }
        50% {
          border-color: white;
        }
      }
    `}
  </style>
</Backdrop>
<WarningModal handleButtons={handleButtons}  isModalOpen={isWarningModal} setIsModalOpen={setIsWarningModal}/>
    <TemplateDeleteModal isModalOpen={deletemodal} setIsDeleteModal={setDeletemodal} text={'Template'} handledelete={handleDel} />
    </>

    );
};

export default  EditTemplate;
