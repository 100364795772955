import React from 'react';

const SignatureModal = ({ signature, isOpen, onClose }) => {
  return (
    <>
      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={()=>onClose(false)}
          ></div>

          {/* Modal Content */}
          <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4 p-4 relative">
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-lg font-bold"
              onClick={()=>onClose(false)}
            >
              &times;
            </button>

            {/* Signature Image */}
            {signature ? (
              <img
                src={signature}
                alt="Signature"
                className="w-full h-auto rounded"
              />
            ) : (
              <p className="text-center text-gray-500">No signature available</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SignatureModal;
