import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import CloseIcon from '@mui/icons-material/Close';


const ShowPdf= ({ ispdfshow, setIspdfShow, pdfBlob,}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const[loading,setLoading]=useState(false)


  const handleZoomIn = () => setZoomLevel(prev => prev + 0.1);
  const handleZoomOut = () => setZoomLevel(prev => Math.max(prev - 0.1, 0.1));

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

 if (!ispdfshow) return null;

  return (
    <div className="fixed flex flex-col inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
  
      <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-3xl relative">
      
        <div className='flex items-center justify-between p-4'
          style={{ background: "#FFF", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.24)" }}
        >
          <div className='text-[#979797] font-sans'>
            SOAP NOTE
          </div>
          <div className="flex items-center gap-4">
            <button onClick={handleZoomOut} className= "px-2">-</button>
            <div className="mx-2 text-[#979797] font-sans">{Math.round(zoomLevel * 100)}%</div>
            <button onClick={handleZoomIn} className="px-2">+</button>
          </div>
          <button
            className="text-gray-600 hover:text-gray-800"
            onClick={() => {
            setIspdfShow(false)}}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="p-4" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={zoomLevel}
                renderTextLayer={false} // Add this line to disable text rendering
              />
            ))}
          </Document>
        </div>
        
      </div>
     
    </div>
  );
};

export default ShowPdf
