import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import blinkscribelogo from '../../../assets/blinkscribe.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import api from "../../../api/api";
import face from '../../../assets/profile.png';
import useModalStore from "zustand/zustandstore";
const LockPage = () => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setError] = useState('');
const[username,setUsername]=useState('')
  const[profilepic,setProfilePic]=useState('')
  const[loading,setLoading]=useState(false)
  const[profileloading,setProfileLoading]=useState(false)
  const {setModalValue}=useModalStore()
const navigate=useNavigate()
const params = new URLSearchParams();

const fetchProfile=async()=>{
  setLoading(true)
  try {
  const data = await api.get('/auth/me'); 
  setUsername(data.username)
  } catch (error) {
    console.log(error)
  }finally{
    setLoading(false)
  }
}
const fetchProfilepic=async()=>{
  setProfileLoading(true)
  try {
    const response = await api.get('/v1/users/profile-pic');
  
    if (response.status === 200) {      
      setProfilePic(`${response.profile_picture_url}?t=${new Date().getTime()}&extraText=lock`);
   
    }else{
      setProfilePic(false)
    }
  } catch (error) {
    console.log(error)
  }finally{
    setProfileLoading(false)
  }
}
const handleUnlock = async() => {
  params.append('username', username);
  params.append('password', password);
  params.append('client_id', '9928'); 
  if(loading) return     
  try {
    setLoading(true)
    const tokenresp = await api.post('/auth/token', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  
    if (tokenresp.status === 200) {
      const { auth, access_token, data,id } = tokenresp;
      
      if(access_token){
        setError('')
        sessionStorage.setItem('auth', access_token);
        localStorage.setItem('user',data[0]?.username)
        const response = await api.get('/auth/me')
        if (response && response.config && response.config.user_type) {
if(response.config.user_type=='BASIC'||response.config.user_type=='PLUS'||response.config.user_type=='BUSINESS'){
setModalValue({usertype:'BASIC'})
  navigate('/app');
}else if(response.config.user_type=='PRO'){
  setModalValue({usertype:'PRO',})
    navigate('/landing');
}


        }else{
          setError("Invalid User Type");
        }

      }else{
        setError("failed to authenticate");

      } 
     
    } else {
      setError("failed to authenticate");
      console.error('Failed to authenticate:', tokenresp.statusText);
    }
  } catch (error) {
    // Handle errors such as network issues or invalid responses
    console.error('An error occurred during authentication:', error);
    setError("somethingwent wrong");
  }
  setLoading(false)
};

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    fetchProfile();
    fetchProfilepic();
  
    const handleBackButton = (event) => {
      event.preventDefault();
      // Push the state to prevent the browser from going back
      window.history.pushState(null, null, '/lock');
    };
  
    // Initially push the '/lock' state
    window.history.pushState(null, null, '/lock');
  
    // Add the 'popstate' listener to intercept back navigation
    window.addEventListener('popstate', handleBackButton);
  
    return () => {
      // Clean up the listener when component unmounts
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
  
  return (<>
    <Box 
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}
    >
      <Card sx={{ maxWidth: 400,   width: { xs: '90%', sm: '400px', md: '400px' }, p: 4 }}>
        <CardContent>
          {/* Logo */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <img
              src={blinkscribelogo} // Add your logo path here
              alt="Logo"
              className="w-32 h-22"
            />
          </Box>

          {/* Profile Picture */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <Box
              sx={{ width: 96, height: 96, borderRadius: '50%', border: '4px solid blue', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
             {!profileloading&&<img
                src={profilepic||face}
                alt="Profile"
                className="w-full h-full object-cover"
              />}
            </Box>
          </Box>

          {/* Username */}
          <Typography align="center" variant="h6" gutterBottom>
            {username}
          </Typography>

          {/* Password Input with Show/Hide Icon */}
          <Box sx={{ position: 'relative', mb: 2 }}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
              style={{ height: '40px', width: '100%' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
         
              type="button"
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                      }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </Box>

          {/* Forgot Password Link */}
          <Typography align="right" sx={{ mb: 2 }}>
            <Button size="small" 
            onClick={()=>navigate('/forgotpassword', { state:{nav:'profile'}})}
            sx={{ textTransform: 'none', color: 'blue' }}>
              Forgot Password?
            </Button>
          </Typography>

          {/* Error Message */}
          {errorMessage && (
            <Typography color="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Typography>
          )}

          {/* Unlock Button */}
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
            
              sx={{background:"#2563eb"}}
              fullWidth
              onClick={handleUnlock}
            >
              Unlock
            </Button>
          </Box>

          {/* Switch User Button */}
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => alert('Switching User...')}
            >
              Switch User
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
     <CircularProgress color="inherit" />
   </Backdrop>
    </>
  );
};

export default LockPage;
