import { useState } from "react";
import Flicking from "@egjs/react-flicking";
import { Perspective } from "@egjs/flicking-plugins";
import ItemView from "./itemview";
import BasicDocView from "../basicdocView";

const FlickingCor = ({ cards, default_template, selectedTemplate, setSelectedTemlate, mydata }) => {
  const plugins = [new Perspective({ rotate: -1, scale: 2, perspective: 1000 })];

  return (
    <div className="w-full ">
      {/* Flicking carousel container */}
      <Flicking
        circular={true}
        plugins={plugins}
        horizontal={true}
        align="center"
        gap={20}
        className="flicking-container"
      >
        {cards.map((card, index) => {
          const documentKey = Object.keys(card)[0];
          const documentData = card[documentKey];

          return (
            <div
              key={index}
              className="flicking-slide flex justify-center items-center flex-col w-[500px] h-full p-4"
            >
              {documentKey === default_template ? (
                <div className="text-xs mb-2 opacity-50">Default</div>
              ) : (
                <div className="text-xs mb-2 opacity-50">
                  {documentKey === "HnP"
                    ? "History & Physical"
                    : documentKey === "soap_note"
                    ? "SOAP Note"
                    : documentKey}
                </div>
              )}

              {mydata.usertype === "BASIC" ? (
                <BasicDocView
                  setSelectedTemlate={setSelectedTemlate}
                  documentKey={documentKey}
                  selectedTemplate={selectedTemplate}
                  data={documentData}
                  defaulttemp={documentKey === default_template}
                />
              ) : (
                <ItemView
                  setSelectedTemlate={setSelectedTemlate}
                  documentKey={documentKey}
                  selectedTemplate={selectedTemplate}
                  data={documentData}
                  defaulttemp={documentKey === default_template}
                />
              )}
            </div>
          );
        })}
      </Flicking>
      <style jsx>{`
        .flicking-container {
          position: relative;
          overflow: hidden;
        }
        .flicking-slide {
          position: relative;
          transform-style: preserve-3d;
        }
      `}</style>
    </div>
  );
};

export default FlickingCor;
