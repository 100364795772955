import { openDB } from 'idb';

// Create or open the IndexedDB database
export const initDB = async () => {
  return await openDB('audioDB', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('audioFiles')) {
        db.createObjectStore('audioFiles', { keyPath: 'id' });
      }
    },
  });
};

// Function to store the audio file in IndexedDB
export const saveAudioToIndexedDB = async (id, audioBlob) => {
  const db = await initDB();
  const tx = db.transaction('audioFiles', 'readwrite');
  const store = tx.objectStore('audioFiles');
  await store.put({ id, audioBlob });
  await tx.done;
};

// Function to delete the audio file from IndexedDB
export const deleteAudioFromIndexedDB = async (id) => {
  const db = await initDB();
  const tx = db.transaction('audioFiles', 'readwrite');
  const store = tx.objectStore('audioFiles');
  await store.delete(id);
  await tx.done;
};
// Function to retrieve the audio file from IndexedDB by id
export const getAudioFromIndexedDB = async (id) => {
  const db = await initDB();
  const tx = db.transaction('audioFiles', 'readonly');
  const store = tx.objectStore('audioFiles');
  const audioFile = await store.get(id);  // Retrieves the object by id
  await tx.done;
  return audioFile?.audioBlob || null;  // Return audioBlob if found, otherwise null
};
