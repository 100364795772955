import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import api from "../../../api/api";
import blinkscribelogo from '../../../assets/blinkscribe 4.svg';
import arrowleft from '../../../assets/arrowleft.png'
import usericon from '../../../assets/user.png'
import keysymbol from '../../../assets/keysymbol.png'
import { useDispatch } from "react-redux";
import { resetSettings } from "../../../redux/store";
import { setProviders } from "../../../redux/store";
import { setVisittype } from "../../../redux/store";
import { resetData } from "../../../redux/store";
import useModalStore from "zustand/zustandstore";
import AppOptions from "general/overlayscreens/appoptions";
import PricingOptions from "general/overlayscreens/pricing";
const UsernamePasswordLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch= useDispatch()
  const params = new URLSearchParams();
  const {setModalValue}=useModalStore()
const handleLogin = async () => {

if(!username){
setErrorMessage('Please enter your username.')
return
}else if(!password){
  setErrorMessage('Please enter your password.')
  return
}

    params.append('username', username);
    params.append('password', password);
    params.append('client_id', '9928');  
    if(loading) return;

localStorage.setItem('mytab','ALL')
dispatch(resetSettings())
dispatch(setProviders([]))
dispatch(setVisittype([]))
dispatch(resetData());

    try {
      setLoading(true);
      const tokenresp = await api.post('/auth/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (tokenresp.status === 200) {
        const { access_token, data, id } = tokenresp;
       
        if(access_token){
          setErrorMessage('');

          if(tokenresp.auth==2){
            setErrorMessage('Password Expired');
            return
          }
          sessionStorage.setItem('auth', access_token);
          localStorage.setItem('user', data[0]?.username);
          localStorage.setItem('bs_id', id);
          localStorage.setItem('dp_id', data[0]?.config?.departmentid);
          // const response = await api.get('/auth/me')
          // if (response && response.config && response.config.user_type) {
          //   if(response.config.user_type=='BASIC'||response.config.user_type=='PLUS'||response.config.user_type=='BUSINESS'){
          //     setModalValue({usertype:'BASIC'})
          //       navigate('/app');
          //     }else if(response.config.user_type=='PRO'){
          //       setModalValue({usertype:'PRO',})
          //         navigate('/landing');
          //     }


          // }else{
          //   setErrorMessage("Invalid User Type");
          // }
navigate('/onboarding')
        
        } else {
          setErrorMessage("Invalid credentials. Please try again.");
        } 
      } else if(tokenresp.status === 201&&tokenresp.locked_status==='I'){
        setErrorMessage('User Locked')

      }else if(tokenresp.status === 201&&tokenresp.auth==3){
        setErrorMessage("Invalid credentials. Please try again.");
        console.error('Failed to Authenticate:', tokenresp.statusText);
      }
    } catch (error) {
      console.error('An error occurred during authentication:', error);
      setErrorMessage("An Error Occurred. Please Contact Admin!");
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
    <div className="flex justify-center items-center h-screen">
      {/* Card Container */}
      <div className="w-full sm:w-[60%] md:w-[60%] lg:w-[40%] max-w-lg md:max-w-2xl p-6 shadow-none sm:shadow-lg md:shadow-lg rounded-lg bg-white h-screen sm:h-[650px] md:h-[500px]">
        <div className="flex flex-col justify-center md:justify-between h-full">
          {/* Back Arrow */}
          <div className="flex items-start ">
            <img
              onClick={() => {
                navigate("/auth1");
             
              }}
              src={arrowleft}
              alt="Down Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          {/* Logo */}
          <div className="flex justify-center md:mb-6 mb-16 ">
            <img src={blinkscribelogo} alt="BlinkScribe Logo" className="h-24 md:h-16 sm:h-16 w-auto" />
          </div>
          {/* Welcome Text */}
          <div className="md:mb-6 mb-16 ">
            <div className="font-semibold text-[#1c2a3a] text-xl leading-9">Hi, Welcome Back!</div>
            <div className="font-normal text-gray-500 text-[13px] leading-[22.5px]">Hope you’re doing fine.</div>
          </div>
          {/* Username Input */}
         {/* Username Input with User Icon */}
<div className="relative mb-4 w-[60%] mx-auto">
  <input
    type="text"
    placeholder="Enter Username"
    className="w-full bg-transparent h-[45px] border-[1px] p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
    value={username}
    onChange={(e) => {
      if(errorMessage){
        setErrorMessage('')
      }
      setUsername(e.target.value)}}
  />
  <img
    src={usericon}
    alt="User Icon"
    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500"
  />
</div>


<div className="relative mb-4 w-[60%] mx-auto">
  <input
    type={showPassword ? "text" : "password"}
    placeholder="Enter Password"
    className="w-full h-[45px] border-[1px] bg-transparent p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
    value={password}
    onChange={(e) => {
      if(errorMessage){
        setErrorMessage('')
      }
      setPassword(e.target.value)}}
  />
  <img
    src={keysymbol}
    alt="Key Symbol"
    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500"
  />
  <button
    type="button"
    onClick={togglePasswordVisibility}
    className="absolute inset-y-0 right-3 flex items-center text-gray-600"
  >
    {showPassword ? <FaEyeSlash /> : <FaEye />}
  </button>
</div>

  
          {/* Forgot Password Link */}
          <div
         
          className="text-right w-[60%] mx-auto mb-4">
            <button  onClick={()=>{navigate('/forgotpassword')}} className="font-semibold text-sm text-gray-600">Forgot Password?</button>
          </div>
  
          {/* Error Message */}
          {errorMessage && (
            <div className="text-red-500 text-sm mb-4 w-[60%] mx-auto">{errorMessage}</div>
          )}
  
          {/* Login Button */}
          <div className="w-[60%] mx-auto mb-4">
            <button
              onClick={handleLogin}
              className={`w-full h-12 bg-[#1C2A3A] text-white text-sm font-semibold rounded-full ${loading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </div>
  
          {/* Help Text */}
          <div className="mb-2 mt-2">
            {/* <div
              className="font-semibold text-base text-center cursor-pointer"
              style={{ color: "#0F172A", fontSize: "12px", fontWeight: "500", lineHeight: "150%" }}
            >
              Need Help?
            </div> */}
          </div>
        </div>
      </div>
    </div>
    {/* <AppOptions/> */}
    {/* <PricingOptions/> */}
  </>
  
  );
};

export default UsernamePasswordLogin;
