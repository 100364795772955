import React, { useState, useEffect, useRef } from 'react';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { getAudioFromIndexedDB, deleteAudioFromIndexedDB } from '../../utils/indexdb'; // Import your IndexedDB functions

const SaveHandleToastModal = ({ reuploadAudio, isModalOpen, setModalOpen, id }) => {
  const [audioUrl, setAudioUrl] = useState(null);   // Store audio URL
  const [audioBlob, setAudioBlob] = useState(null); // Store the audio Blob
  const [isPlaying, setIsPlaying] = useState(false); // Play/pause state
  const audioRef = useRef(null);                     // Audio element reference

  // Fetch the audio file when the modal opens
  useEffect(() => {
    if (isModalOpen && id) {
      const fetchAudio = async () => {
        const audioBlob = await getAudioFromIndexedDB(`audio-${id}`);
        if (audioBlob) {
          const url = URL.createObjectURL(audioBlob);
          setAudioUrl(url);
          setAudioBlob(audioBlob); // Save the Blob for re-uploading
        } else {
          // No audio found, close the modal
          setModalOpen(false);
        }
      };
      fetchAudio();
    }
  }, [isModalOpen, id, setModalOpen]);

  const handlePlayPause = () => {
    if (audioUrl) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleretry = async () => {
    if (audioBlob) {
      await reuploadAudio(audioBlob); // Re-upload the audio file
      setModalOpen(false); // Close the modal after re-uploading
    }
  };

  const handleDelete = async () => {
    //const confirmation = window.confirm('Are you sure you want to delete this record?');
    
    if (true) {
      await deleteAudioFromIndexedDB(`audio-${id}`);
      setModalOpen(false);
    }
  };

  if (!isModalOpen) {
    return null;
  }

  return (
    <>
      {isModalOpen && (
        <div
          id="popup-modal"
          tabIndex="-1"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-lg max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
             
              <div className="p-4 md:p-5 text-center">
                <div>
                  <ReportProblemIcon style={{ width: '60px', height: '60px', color: "#1D2951" }} />
                </div>
                <div className="mb-2 mt-3 font-sans text-lg font-[400]">
                Something went wrong! Your recording may have been saved, please check again.
                </div>
                <div className="flex items-center font-sans font-[400] justify-center" style={{ fontSize: '10px' }}>
                  <ErrorOutlineIcon style={{ fontSize: '10px', marginRight: '4px' }} />
                  We found your Record
                </div>

                {/* Audio play controls */}
                {audioUrl && (
                  <div className="mt-4">
                    {/* Audio element with default controls */}
                    <audio ref={audioRef} src={audioUrl} controls style={{ width: '100%' }} />
                  </div>
                )}
                
                <div className="flex items-center justify-center mt-4 gap-5 w-full">
                  <button 
                  onClick={handleretry}
                    className="w-max p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
                    Submit Record
                  </button>
                  <button
                    onClick={handleDelete}
                    className="w-max p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
                    Delete Record
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SaveHandleToastModal;
