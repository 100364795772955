import api from "api/api"
import AppOptions from "general/overlayscreens/appoptions"
import AuthOverlayCard from "pages/landing/overlayauth/authoverlay"
import OptionWrap from "pages/landing/overlayauth/optionWrap"
import TermsOverlayCard from "pages/landing/overlayauth/termsoverlay"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import useModalStore from "zustand/zustandstore"

const OnBoarding = () => {
    const [getuserLoading, setGetUserLoading] = useState(false)
    const [isverified, setIsVerified] = useState(0)
    const navigate = useNavigate()
    const { setModalValue } = useModalStore()
    const fetchuser = async () => {

        try {
            setGetUserLoading(true)
            const response = await api.get('/auth/me')
            if (response?.bm_user) {
                if (response?.disclaimer === 'PENDING') {
                    // Check if `response.config` has the key `user_type`
                    if (response?.config?.user_type) {
                        // Check if `user_type` is equal to 'BUSINESS'
                        if (response.config.user_type === 'BUSINESS') {
                            setIsVerified(4); //disclaimer page
                        } else {

                            if (response.config.user_type === '') {
                                setIsVerified(2);
                            } else {
                                setIsVerified(4)
                            }
                        }
                    } else {
                        setIsVerified(2);
                    }
                } else {

                    if (response && response.config && response.config.user_type) {
                        if (response.config.user_type == 'BASIC' || response.config.user_type == 'PLUS' || response.config.user_type == 'BUSINESS') {
                            setModalValue({ usertype: response.config.user_type })
                            navigate('/app');
                        } else if (response.config.user_type == 'PRO') {
                            setModalValue({ usertype: 'PRO', })
                            navigate('/landing');
                        }


                    }


                }

            } else {
                //if not password setup
                setIsVerified(1)
            }

        } catch (error) {

        } finally {
            setGetUserLoading(false)
        }
    }
    useEffect(() => {
        fetchuser()
    }, [])
    return (<>

        {isverified === 1 && <AuthOverlayCard handleVerified={fetchuser} isOpen={true} />}
        {isverified === 2 && <OptionWrap handleVerified={fetchuser} />}
        {isverified === 4 && <TermsOverlayCard handleVerified={fetchuser} isOpen={true} />}
    </>)
}

export default OnBoarding;