import logo from '../../assets/blinkscribe.png';

const DocView = ({ data, defaulttemp,selectedTemplate, documentKey,setSelectedTemlate }) => {
    if (!data || typeof data !== 'object' || Array.isArray(data)) {
        return (
            <div className="flex flex-col items-center justify-center w-full p-4 h-[100%]">
                <p className="text-red-500 text-lg font-semibold">
                    Something went wrong. Please provide valid data.
                </p>
            </div>
        );
    }

    return (
        <div
        onClick={()=>setSelectedTemlate(documentKey)}
        className={`flex flex-col items-start w-full max-w-[600px] h-[500px] p-4 border 
          ${defaulttemp 
            ? 'shadow-[0px_0px_8px_8px_rgba(133,_182,_255,_0.35)]' 
            : 'shadow-md'} 
         ${selectedTemplate===documentKey?'border-[#1C2A3A] border-[3px]':"border-gray-300"} rounded-lg overflow-hidden`}
      >
            {/* Header with Logo */}
            <div className="flex items-center justify-center w-full">
                <img src={logo} alt="logo" className="h-6" />
            </div>

            {/* Horizontal Line */}
            <div className="w-full mt-4">
                <hr className="w-full border-t-2 border-gray-300 my-2" />
            </div>

            {/* Scrollable Content */}
            <div className="flex flex-col items-start justify-start w-full mt-4 overflow-y-auto h-auto custom-scrollbar">
                {Object.keys(data).length === 0 ? (
                    <p className="text-gray-500 text-sm text-center">No data available.</p>
                ) : (
                    Object.entries(data).map(([key, value]) => (
                        <div key={key} className="flex  text-start flex-col mb-4 w-full">
                            <h3 className="font-semibold text-xs mb-1 underline">{key.replace(/_/g, ' ')}:</h3>
                            <p className="text-xs text-gray-600 break-words overflow-hidden max-w-full">
                                {typeof value === 'object' && value !== null
                                    ? JSON.stringify(value, null, 2).replace(/^\{+|\}+$/g, '')
                                    : value?.replace(/^\{+|\}+$/g, '') || "No content available"}
                            </p>
                        </div>
                    ))
                )}
            </div>

            {/* Footer */}
            <div className="mt-6 w-full flex flex-col items-center">
                <div className="text-[8px] font-normal [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-center">
                    Generated By
                </div>
                <img src={logo} alt="logo" className="h-5" />
            </div>
        </div>
    );
};

export default DocView;
