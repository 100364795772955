import React, { useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { createElement } from 'react';
import useModalStore from 'zustand/zustandstore';
const ArchiveDeleteToastModal = ({handledelete, isModalOpen,setModalOpen,setPreviousTime}) => {
  const { isOpen, openModal, closeModal, resetModal, setModalValue, recordtime } = useModalStore();
 if(!isModalOpen){
    return
 }
 
  return (
    <>
      {isModalOpen && (
        <div
          id="popup-modal"
          tabIndex="-1"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-lg max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="p-4 md:p-5  text-center">
              <div>
    <DeleteForeverIcon style={{ width: '60px', height: '60px', color: "#1D2951" }} />
                </div>
             
                <div className="mb-2 mt-3  font-sans text-lg font-[400]">
                Are you sure you want to delete this record?
                </div>
                <div className="flex items-center justify-center mt-4 gap-5 w-full">
                  <button 
                  onClick={()=>setModalOpen(false)}
                    className="w-max p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
                    Cancel
                  </button>
                  <button
                    onClick={()=>{
                     handledelete()
                    }
                  }
                    className="w-max p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
                    Delete Record
                  </button>
                </div>
 
               <div>
 
               </div>
               
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
 
export default ArchiveDeleteToastModal;