import React, { useState } from "react";
import { Card, CardContent } from "@mui/material";
import blinkscribelogo from "../../../assets/blinkscribe.png";
import Select from "react-select";
import Flag from "react-world-flags";
import { useNavigate } from "react-router-dom";

import user from '../../../assets/user.png'
import sms from '../../../assets/sms.png'
import arrowleft from '../../../assets/arrowleft.png'

const countries = [
  { code: "US", prefix: "+1", label: "United States", value: "US", minLength: 10 },
  { code: "IN", prefix: "+91", label: "India", value: "IN", minLength: 10 },
  { code: "GB", prefix: "+44", label: "United Kingdom", value: "GB", minLength: 10 },
  { code: "DE", prefix: "+49", label: "Germany", value: "DE", minLength: 11 },
  { code: "FR", prefix: "+33", label: "France", value: "FR", minLength: 9 },
];

const BlinkScribeSignup = () => {
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((country) => country.value === "US")
  );
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [errorMessage, setErrorMessage] = useState(""); // Use a string for error message
  const navigate = useNavigate();

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      fontSize: "8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
  };

  const formatOptionLabel = ({ code, label }) => (
    <div className="flex items-center">
      <Flag
        code={code}
        className="mr-2"
        style={{ width: "20px", height: "15px" }}
      />
      <span>{label}</span>
    </div>
  );

  const handleSignup = () => {
    const { firstName, lastName, email, phone } = formData;
    let errors = "";

    if (!firstName) {
      errors = "First name is required.";
    } else if (!lastName) {
      errors = "Last name is required.";
    } else if (!email) {
      errors = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors = "Please enter a valid email address.";
    } else if (!phone) {
      errors = "Phone number is required.";
    } else if (!/^[0-9]+$/.test(phone)) {
      errors = "Phone number must contain only digits.";
    } else if (phone.length < selectedCountry.minLength) {
      errors = `Phone number should be at least ${selectedCountry.minLength} digits long.`;
    }

    if (errors) {
      setErrorMessage(errors); // Set the error message string to state
      return;
    }

    setErrorMessage(""); // Reset errors if the form is valid
    // Proceed with signup logic
    console.log("Signup successful!", formData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <Card className="w-[100%] md:w-[40%] sm:w-[40%] max-w-lg md:max-w-2xl p-6 shadow-lg h-screen md:h-[600px] sm:h-[600px] rounded-lg bg-white  md:bg-white">
        <CardContent className="h-full">
          <div className="flex items-start ">
            <img
              onClick={() => {
                navigate("/auth1");
              }}
              src={arrowleft}
              alt="Down Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <div className="flex flex-col items-center justify-between h-full">
            <div className="flex justify-center">
              <img
                src={blinkscribelogo}
                alt="BlinkScribe Logo"
                className="h-16 w-auto"
              />
            </div>
            {/* heading */}
            <div>
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#1c2a3a] text-xl tracking-[0] leading-9 whitespace-nowrap">
                Create Account
              </div>
              <div className="[font-family:'Inter-Regular',Helvetica] font-normal text-gray-500 text-[13px] tracking-[0] leading-[22.5px] whitespace-nowrap">
                We are here to help you!
              </div>
            </div>
            <div className="w-full flex flex-col items-center gap-4">
              <div className="w-[100%] md:w-[60%] sm:w-[60%]">
                <input
                  name="firstName"
                  type="text"
                  placeholder="Enter First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                />
              </div>
              <div className="w-[100%] md:w-[60%] sm:w-[60%]">
                <input
                  name="lastName"
                  type="text"
                  placeholder="Enter Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                />
              </div>
              <div className="w-[100%] md:w-[60%] sm:w-[60%]">
                <input
                  name="email"
                  type="text"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                />
              </div>
              <div
                style={{ border: "1px solid #dcdcdc" }}
                className="flex flex-row w-[100%] md:w-[60%] sm:w-[60%] rounded-lg p-1 gap-2"
              >
                <div style={{ width: "80px" }} className="flex items-center">
                  <Select
                    value={selectedCountry}
                    onChange={setSelectedCountry}
                    options={countries}
                    styles={customStyles}
                    formatOptionLabel={formatOptionLabel}
                  />
                </div>
                <div className="flex-1">
                  <input
                    name="phone"
                    type="tel"
                    placeholder="Enter Phone Number"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="w-full bg-transparent h-[42px] md:h-[32px] sm:h-[32px] p-3 rounded-lg outline-none text-gray-800 text-sm font-normal"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                </div>
              </div>
            </div>
            {errorMessage && (
              <div className="text-red-500 text-sm font-medium w-[100%] md:w-[60%] sm:w-[60%] text-center">
                {errorMessage}
              </div>
            )}
            <div className="w-full flex items-center flex-col gap-4">
              <button
                onClick={handleSignup}
                className={`flex w-[100%] md:w-[60%] sm:w-[60%] h-15 md:h-10 sm:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out`}
              >
                <div className="font-semibold text-base">Create Account</div>
              </button>
              <p className="[font-family:'Inter-Regular',Helvetica] font-normal text-transparent text-[15px] text-center tracking-[0] leading-[22.5px]">
                <span className="text-gray-500">Have an account yet?</span>
                <span className="text-gray-500 text-sm leading-[21px]">&nbsp;</span>
                <span
                  onClick={() => navigate('/auth1')}
                  style={{ cursor: "pointer" }}
                  className="[font-family:'Inter-Medium',Helvetica] font-medium text-[#1c64f2] text-sm leading-[21px]"
                >
                  Sign in
                </span>
              </p>
            </div>
            <div>
              <div
                className="font-semibold text-base text-center cursor-pointer"
                style={{ color: "#0F172A", fontSize: "15px", fontWeight: "500", lineHeight: "150%" }}
              >
                Need Help?
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BlinkScribeSignup;
