import React, { useEffect, useState } from "react";
import { Backdrop } from "@mui/material";
import { useDropzone } from "react-dropzone";
import useModalStore from "zustand/zustandstore";
import usericon from '../../assets/user.png';
import api from "api/api";
import CloseIcon from '@mui/icons-material/Close';
const UploadAudio = ({ setIsuploadmodal }) => {
  const [uploadedAudio, setUploadedAudio] = useState(null);
  const[templates,setTemplate]=useState([])
  const [loading, setLoading] = useState(false);
  const [screen,setscreen]=useState(0)
  const [text,setText]=useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [patientid,setPatientId]=useState('')
const [patientName,setPatientName]=useState('')
const mydata=useModalStore()
const [selectedTemplate, setSelectedTemplate] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "audio/webm": [".webm"], // Allow only webm
      "audio/aac": [".aac"],   // Allow only aac
      "audio/mpeg": [".mp3"],  // Allow only mp3
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setUploadedAudio(file);
      }
    },
  });
  const fetchmydata=async()=>{
    const response = await api.get('/auth/me')
    if (response && response.templates && response.templates.default_template) {
      const data = await api.post('/v1/user_templates/get_all_templates', {
        practiceid: `${response.config.practiceid}`,
        departmentid: `${response.config.departmentid}`,
    });
  
    if(data.status==200){
       const allKeys = data?.file_names.map(item => Object.keys(item)[0]);
       setTemplate(allKeys)
    }
   
      setSelectedTemplate(response.templates.default_template)
      
    }
    if (response && response.templates && response.templates.template_names) {
      // setTemplate(response.templates.template_names);
      
  }
  }
  useEffect(()=>{
  fetchmydata()
  },[])
  const handlesubmit = async() => {
    if (!patientName) {
      setErrorMessage('Please enter patient name');
      return;
    }
    
    if (!patientid) {
      setErrorMessage('Please enter patient ID');
      return;
    }
    if (!/^\d+$/.test(patientid)) {
      setErrorMessage('Patient ID should be a number');
      return;
    }

    try {
        setLoading(true)
        const response = await api.get('/auth/me')
        const formData = new FormData();

        if(mydata.usertype=='BUSINESS'){
            formData.append('file', uploadedAudio);
          formData.append('practiceid', response?.config.practiceid);
          formData.append('departmentid', response?.config.departmentid);
          formData.append('patientid', 12241);
          formData.append('notes', patientid);
          formData.append('tags',patientName);
          }else if(mydata.usertype=='PLUS'&&selectedTemplate){
            formData.append('file', uploadedAudio);
          formData.append('practiceid', response?.config.practiceid);
          formData.append('departmentid', response?.config.departmentid);
          formData.append('patientid', patientid);
          formData.append('patientname', patientName);
          formData.append('template_name', selectedTemplate);
          
          }else{
            formData.append('file', uploadedAudio);
            formData.append('practiceid', response?.config.practiceid);
            formData.append('departmentid', response?.config.departmentid);
            formData.append('patientid',  patientid);
            formData.append('patientname',patientName);
           
          }

          const submitted = await api.post('v1/ehr/recording/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          if (submitted.status === 200) {
            setText('Audio file uploaded successfully!');
            setscreen(2);
          } else {
            setText('Failed to upload the audio file. Please try again.');
            setscreen(2);
          }
          
          

    } catch (error) {
        
    }finally{
        setLoading(false)

    }
}

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
<div
  className={`bg-white w-[90%] sm:w-[30%] flex flex-col justify-around rounded-md shadow-lg p-6 ${
    screen === 2 ? 'h-[30%]' : 'h-[60%]'
  }`}
>
        {/* Header */}
        <div className="flex items-end justify-end">
           <button
        onClick={()=>{
          setPatientId('')
          setPatientName('')
          setUploadedAudio(null)
          setscreen(0)
          setIsuploadmodal(false)
          }}
        className=" text-gray-600 hover:text-black"
      >
        <CloseIcon/>
      </button>   
        </div>
      
       {screen==1&&uploadedAudio?<>
        <div className="flex flex-col items-center mt-5 justify-between flex-grow">
        <div className="mb-4 text-center">
          <div className="font-bold text-[#1C2A3A] text-lg sm:text-xl leading-7">Add Patient Details</div>
        </div>
  
        <div className="relative mb-4 w-[80%] mx-auto">
        <input
  type="text"
  value={patientName}
  onChange={(e) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setPatientName(e.target.value);
  }}
  placeholder="Enter Patient Name"
  className="w-full bg-transparent h-[45px] border border-gray-300 p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
/>

          <img src={usericon} alt="User Icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
        </div>
        <div className="relative mb-4 w-[80%] mx-auto">
        <input
  value={patientid}
  onChange={(e) => {
    const value = e.target.value;
    // Allow only numeric characters
    if (/[^0-9]/.test(value)) {
      return; // Do nothing if the input is not a number
    }
    
    if (errorMessage) {
      setErrorMessage('');
    }
    
    setPatientId(value);
  }}
  type="text"
  placeholder="Enter Patient Id"
  className="w-full bg-transparent h-[45px] border border-gray-300 p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
/>

          <img src={usericon} alt="User Icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
        </div>
        {mydata.usertype === 'PLUS' && templates.length > 0 && (
  <div className="relative text-left mb-4 w-[80%] mx-auto">
    <select
      value={selectedTemplate}
      onChange={(e) => setSelectedTemplate(e.target.value)}
      className="w-full text-left bg-transparent h-[45px] border border-gray-300 p-3  rounded-lg outline-none text-gray-800 text-sm font-normal"
    >
        <option value="">Basic</option>
        <option value="HnP">History & Physical</option>
      {templates.map((template, index) => (
        <option className="text-left" key={index} value={template}>
          {template}
        </option>
      ))}
    </select>
  </div>
)}

          {errorMessage && (
          <div className="text-red-500 text-xs sm:text-sm mb-4 w-[80%] mx-auto">
            {errorMessage}
          </div>
        )}
  
               <div className="flex items-center justify-center mt-4 gap-5 w-full">
               <button 
               onClick={()=>{
                setPatientId('')
                setPatientName('')
                 setscreen(0)
            }
            }
                    className="w-[40%] p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
              Back
                   </button>
                  <button 
                 onClick={()=>handlesubmit()}             
                    className="w-[40%] p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
               Save
                  </button>
              
      </div>
      </div>
       
       
       
       </>:(screen===2?<div className="min-h-[80%] flex justify-center items-center flex-col text-2xl font-semibold text-gray-800">
        <div className="mb-2 mt-3  font-sans text-lg font-[400]"> {text}</div>
</div>
:<><div className="mb-4 w-full">
          <div className="font-semibold text-[#1c2a3a] text-lg sm:text-xl leading-7 text-left">
            Upload Audio File
          </div>
        </div>

  
        <div
          {...getRootProps()}
          className="flex flex-col items-center justify-center border border-dashed border-gray-400 h-[40%] p-4 text-center"
        >
          <input {...getInputProps()} />
          <button
            style={{ border: "1px dashed" }}
            className="bg-[#E6E6E6] px-3 py-1 text-[#595959] text-sm rounded-sm ml-1 mr-2 my-1"
          >
            Browse
          </button>
        </div>

        <div className="font-normal text-start text-gray-500 text-xs sm:text-xs leading-[22.5px] mt-2">
          Valid formats: webm, aac, mp3
        </div>

   
        {uploadedAudio && (
  <div
    className="font-normal text-start text-xs sm:text-sm leading-[22.5px] mt-2 break-words"
  >
    File Name:{" "}
    <span className="underline font-semibold text-red-600">
      {uploadedAudio.name}
    </span>
  </div>
)}


        {/* Cancel and Confirm Buttons */}
        <div className="w-full mt-4 mx-auto gap-2 sm:w-full items-end flex justify-end">
          {/* <button
            onClick={() => {
              setUploadedAudio(null);
              setIsuploadmodal(false);
            }}
            className="w-[28%] h-10 bg-[#FFF] text-[#232222] border border-[#CBCBCB] text-sm font-semibold rounded-lg"
          >
            Cancel
          </button> */}
          <button
            disabled={!uploadedAudio}
            onClick={()=>{setscreen(1)}}
            className={`w-[28%] h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-lg ${
              !uploadedAudio ? "opacity-60 cursor-not-allowed" : ""
            }`}
          >
            Next
          </button>
        </div></>)}
      </div>
        
      {/* Loading Backdrop */}
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        open={loading}
      >
        <div
          className="text-white font-mono text-xl font-bold overflow-hidden whitespace-nowrap border-r-2 border-white"
          style={{
            width: "18ch",
            animation:
              "typing 2s steps(18, end) infinite, blink 0.5s step-end infinite",
          }}
        >
         Processing...
        </div>
        <style>
          {`
            @keyframes typing {
              from {
                width: 0;
              }
              to {
                width: 18ch;
              }
            }
            @keyframes blink {
              from, to {
                border-color: transparent;
              }
              50% {
                border-color: white;
              }
            }
          `}
        </style>
      </Backdrop>
    </div>
  );
};

export default UploadAudio;
