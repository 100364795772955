import editrecord from '../../../assets/editrecord.png';
import document from '../../../assets/document.png';
import { useEffect, useState } from 'react';
import UploadFileModal from '../uploadfilemodal';
import { useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress, Tooltip } from '@mui/material';
import api from 'api/api';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ToastMessage from 'utils/muialerts/righttoptoast';
import ShowPdf from '../showpdf';
import arrowleft from '../../../assets/arrowleft.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import { FaCheckCircle } from 'react-icons/fa';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import gridicon from '../../../assets/gridicon.png'
import list from '../../../assets/list.png'
import MenuIcon from '@mui/icons-material/Menu';
import GridViewIcon from '@mui/icons-material/GridView';
import uploadicon from '../../../assets/uploadicon.png'
import DocView from '../docview';
import useModalStore from 'zustand/zustandstore';
import BasicDocView from '../basicdocView';
import PurposeModal from '../modals/purposemodal';
import Corousel from '../corousel/corousel';
import FlickingCor from '../corousel/flickingcor';
const ProManageTemplate = () => {
  // Array of cards with template names
  const [isuploadmodal,setIsuploadmodal]=useState(false)
  const [ispdfshow,setIspdfShow]=useState(false)
  const mydata=useModalStore()
  const[file,setFile]=useState('')
  const[selectedTemplate,setSelectedTemlate]=useState('')
  const [pdffile,setPdffile]=useState()
  const [loading,setLoading]=useState(false)
  const [alertmessage, setAlertMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [default_template,setDefaulttemplate]=useState('')
  const [severity, setSeverity] = useState('success');
  const[extractionResponse,setExtractionResponse]=useState('')
  const [pId,setPId]=useState('')
  const[dId,SetDId]=useState('')
  const [isPurposeModal,setPuroseModal]=useState(false)
  const [isListView,setListView]=useState('corousel')
  const navigate=useNavigate()
  const[cards,setCards]=useState([])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

const fetchBasicData=async()=>{
  try {
  setLoading(true)
    const response=await api.get('/v1/user_templates/default_templates')
    if(response.status==200){
      
      setCards(response.file_names)
              }else{
               
                setCards([])
              }
  } catch (error) {
    console.log(error)
  }finally{
setLoading(false)

  }
}

useEffect(()=>{
 fetchBasicData()
 
},[])
const handleShowpdf = async (filename) => {
  try {
    setLoading(true);

    // Append a timestamp to avoid caching
    const timestamp = new Date().getTime();

    const response = await api.get(
      `/v1/user_templates/default_templates_docs/PDF/${filename}?timestamp=${timestamp}`,
      {
        responseType: "blob",
      }
    );

    const pdfBlob = new Blob([response], { type: "application/pdf" });

    setPdffile(pdfBlob);
    setIspdfShow(true);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

const handleUploadSavedTemplate=async()=>{
  setLoading(true)
  try {
    const  savetemplate=await api.post('/v1/user_templates/save_template',{
      "practiceid": pId,
      "departmentid":dId,
      "filename": file.name,
      "note_json": extractionResponse
    
    })
if(savetemplate.status===200){
  setAlertMessage('Successfully saved the template') 
  setOpen(true)
  setFile('')
}else{
  setAlertMessage('Something Went Wrong, Try Again') 
  setOpen(true)
}
  } catch (error) {
    setAlertMessage('Something Went Wrong, Try Again') 
  setOpen(true)
    console.log(error)
  }finally{
setLoading(false)

  }
}


const markasdefault= async(prop)=>{
  try {
    let response= await api.put('/v1/users/update-templates',{
"default_template":prop
    })
   
    if(response.status===200){
   setSeverity('success')
      setAlertMessage(response.message) 
      setOpen(true)
  
    }else{
        setSeverity('error')
      setAlertMessage(response.message) 
      setOpen(true)
    }
  } catch (error) {
    console.log(error)
  }


}
const handleSelfEdit = () => {
  navigate(`/edit-temp/${file.name}/self`, { state: { extractionresponse:extractionResponse } });
};
const handleUpdate=async(purposes,ids)=>{
try {
    setPuroseModal(false)
    setLoading(true)

   const response=await api.put('/v1/user/set-appointment-template',{
    "templates": {
    "template": selectedTemplate,
    "appointment_type_ids":[...purposes,...ids]
  }
   })
   console.log(response)
   if(response.status===200){   
    setSeverity('success')
    setAlertMessage(response.message) 
    setOpen(true)

  }else{
    setSeverity('error')
    setAlertMessage('Purpose is already selected for other template') 
    setOpen(true)
  }
} catch (error) {
    console.log(error)
    setSeverity('error')
    setAlertMessage('Something went wrong') 
    setOpen(true)
}finally{
setLoading(false)

}
}



  return (
    <div className="flex items-center justify-center w-full h-screen sm:h-auto md:h-auto">
      <div className=" relative w-[95%] sm:w-[80%] min-h-[50px] h-auto md:mt-10 p-8 bg-white rounded-[20px] shadow-[0px_2px_13px_#00000040]">
      <div onClick={()=>navigate(-1)}
              className="flex items-start cursor-pointer">
              <img src={arrowleft} alt="Down Arrow" className="w-4 h-4" />
            </div>
            <div className=" flex w-full justify-center items-center mt-10">
          <div className="text-xl font-semibold [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-center">
            Manage Templates
          </div>  
       
        </div>
        <div className='absolute left-[80%] top-[8%] flex gap-2  items-center justify-center mt-2'>
        <MenuIcon
        onClick={() => setListView('menu')}
        style={{
          color: isListView=='menu' ? 'black' : 'grey', // Change the color based on isGridView
          cursor: 'pointer',
          fontSize:"30px"
        }}
      />
      <ViewCarouselIcon
       onClick={() => setListView('corousel')}
       style={{
         color: isListView==='corousel' ? 'black' : 'grey', // Change the color based on isGridView
         cursor: 'pointer',
         fontSize:"30px"
       }}
      
      />
      {/* Grid View Icon */}
      <GridViewIcon
        onClick={() => setListView('grid')}
        style={{
          color: isListView=='grid'? 'black' : 'grey', // Change the color based on isGridView
          cursor: 'pointer',
           fontSize:"26px"
        }}
      />
</div>
{mydata.usertype==='PLUS'?<div className="mt-4 w-full mb-4 flex justify-center">
  <div
    className="flex flex-col items-center cursor-pointer"
    onClick={() => setIsuploadmodal(true)}
  >
    <ControlPointIcon sx={{ fontSize: "40px", }} />
    <span className="text-sm font-medium text-[#686868] mt-2">Add New Template</span>
  </div>
</div>:<div className='mt-4 w-2 h-2'></div>}
  {selectedTemplate?<>
    <div className="mt-2 mb-12 flex justify-center space-x-4">
    <button
  onClick={()=>{
    setPuroseModal(true)
  }}
  className={`flex items-center justify-center gap-2 w-[40%] sm:w-[10%] h-10 text-white text-xs font-semibold rounded-lg ${
    selectedTemplate
      ? 'bg-[#1C2A3A] hover:bg-[#173041]' // Active styles
      : 'bg-[#1C2A3A] cursor-not-allowed' // Disabled styles
  }`}>
    Update
  </button>
  
  <button
   onClick={(e)=>
    {
      e.stopPropagation()
      handleShowpdf(selectedTemplate)}} 
  
      className={`flex items-center justify-center gap-2 w-[40%] sm:w-[10%] h-10 text-white text-xs font-semibold rounded-lg ${
        selectedTemplate
          ? 'bg-[#1C2A3A] hover:bg-[#173041]' // Active styles
          : 'bg-[#1C2A3A] cursor-not-allowed' // Disabled styles
      }`}
    >
     View Template
    </button>
  
  </div>
  </>:<></>}
  

  {isListView=='menu'?
  <div className="mt-4 h-[45vh] overflow-y-auto custom-scrollbar">
        {(cards.length)?cards.map((file, index) => {
  // Extract the key of the template, which is "1st_template" in your case
  const templateName = Object.keys(file)[0];
   return (<div className='flex items-center justify-center'>
    <div className='w-full flex items-center gap-4  sm:w-[50%]'>
      {/* <div className='h-[60px] py-2 mb-4 flex items-center'>
       <div
  className="w-4 h-4 border-2 border-[#2563EB] rounded-full  flex items-center justify-center"
  
>
  {templateName===default_template?<div className="w-2 h-2 bg-[#2563EB] rounded-full" />:<></>}
</div>   
      </div> */}
 

 <div
  key={index}
  className={`w-full min-h-[60px] cursor-pointer mx-auto border ${
    templateName === selectedTemplate ? 'border-[#1C2A3A] border-[3px]' : 'border-[#D1D1D1]'
  } rounded-md px-4 py-2 mb-4 flex items-center justify-between`}
  // onClick={() => handleShowpdf(templateName)}
  onClick={(e) => {
    e.stopPropagation();
    setSelectedTemlate(templateName);
    // markasdefault(templateName)
  }}
>
      <div className="flex items-center gap-4">
        {/* <img src={document} 
        onClick={()=>handleShowpdf(templateName)}
        className="w-6 h-6 cursor-pointer" alt="document" /> */}
    <div className="[font-family:'Poppins-Regular',Helvetica] font-semibold text-start text-[#262629] text-[13px] tracking-[0] leading-[normal]">
  {templateName === 'HnP' 
    ? 'History & Physical' 
    : templateName === 'soap_note' 
    ? 'SOAP Note' 
    : templateName}
</div>

      </div>
      {templateName===default_template ? (
        <div className="text-xs opacity-50 ml-2">Default</div>
      ) : (
        <div className="text-xs opacity-50 ml-2"></div>
      )}



{/* <div
  onClick={(e) => {
    e.stopPropagation();
    setSelectedTemlate(templateName);
    // markasdefault(templateName)
  }}
  className={`font-medium h-[40px] text-xs py-1 px-2 rounded-sm transition duration-200 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] cursor-pointer flex items-center justify-center ${
    templateName === selectedTemplate ? 'bg-[#1C2A3A] text-white' : 'bg-white text-[#686868]'
  }`}
>
  Select Template
</div> */}


      {/* {templateName===default_template ?<EditIcon  onClick={() => navigate(`/edit-temp/${templateName}/ind`)} className="w-6 h-6 text-[#9CA3AF] cursor-pointer" alt="edit" />:<></>} */}
      {/* <RemoveRedEyeIcon  onClick={(e)=>
        {
          e.stopPropagation()
          handleShowpdf(templateName)}} className="w-6 h-6 text-[#9CA3AF] cursor-pointer" /> */}
    </div>
 </div></div> );
}):<>{loading?<>Getting Data</>:"No Data Available"}</>}

        </div>:(isListView==='corousel'?<>
          <div className="flex p-4 w-full  justify-center  gap-4 overflow-y-auto custom-scrollbar flex-wrap mt-2">
          {/* <Corousel
        cards={cards}
        default_template={default_template}
        selectedTemplate={selectedTemplate}
        setSelectedTemlate={setSelectedTemlate}
        mydata={mydata}
    /> */}
    <FlickingCor
     cards={cards}
     default_template={default_template}
     selectedTemplate={selectedTemplate}
     setSelectedTemlate={setSelectedTemlate}
     mydata={mydata}
    />
        </div>
        </>:<>
       <div className="flex p-4 w-full justify-center max-h-[70vh] gap-4 overflow-y-auto custom-scrollbar flex-wrap mt-2">
  {cards.map((card, index) => {
    const documentKey = Object.keys(card)[0]; // Get the first key (e.g., "JPG.jpg" or "PNG.png")
    const documentData = card[documentKey]; // Access the inner object

    return (
      <div
        key={index}
        className=" min-w-[300px] w-[40%]  p-4 h-full min-h-[350px]"
      >
           {documentKey===default_template ? (
        <div className="text-xs mb-2 opacity-50 ml-2">Default</div>
      ) : (
        <div className="text-xs mb-2 opacity-50 ml-2">{documentKey === 'HnP' 
          ? 'History & Physical' 
          : documentKey === 'soap_note' 
          ? 'SOAP Note' 
          : documentKey}</div>
      )}
       
       <BasicDocView 
         setSelectedTemlate={setSelectedTemlate}
         documentKey={documentKey}
         selectedTemplate={selectedTemplate}
        data={documentData} defaulttemp={documentKey===default_template} />
      </div>
    );
  })}
</div>

       
        </>)}


      </div>
      <PurposeModal selectedTemplate={selectedTemplate} handleUpdate={handleUpdate} isPurposeModal={isPurposeModal} setPuroseModal={setPuroseModal}/>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" size={40} />
      </Backdrop>
      <ShowPdf ispdfshow={ispdfshow} setIspdfShow={setIspdfShow} pdfBlob={pdffile}/>
      <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
      {isuploadmodal?<UploadFileModal 
      setAlertMessage={setAlertMessage}
      
      setExtractionResponse={setExtractionResponse}
      setOpen={setOpen}
      setfile={setFile} 
      setIsuploadmodal={setIsuploadmodal}/>:<></>}
    </div>
  );
};

export default ProManageTemplate;
