import MusicWaves from "./musicwaves";
import redcircle from "../../assets/redcircle.png";
import pause from "../../assets/Pause.png";
import play from "../../assets/play.png";
import { useRef, useState, useEffect, useImperativeHandle } from "react";
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteToastModal from "alerts/recordingscreen/deletetoast";
import useModalStore from "zustand/zustandstore";

const RecordingPlayer = ({ setBackScreen,setPreviousTime, submitAudio,restrictSave, setToastAlert, previoustime, setToastObject , myref}) => {
  const [isRecording, setIsRecording] = useState(false);
  const{setModalValue }=useModalStore()
  const [isPaused, setIsPaused] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [recordingTime, setRecordingTime] = useState("00:00"); 
  const [isDelete, setIsDelete] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
const[persistRecordTime,setPersistRecordTime]=useState('')
const[showsavedtime,setSavedTime]=useState(false)
const mydata = useModalStore();
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const startTimeRef = useRef(null); // For when recording started
  const elapsedTimeRef = useRef(0); // To store total elapsed time
  const animationFrameRef = useRef(null); 

  const convertToMilliseconds = (timeString) => {
    const [minutes, seconds] = timeString.split(':').map(Number);
    const totalSeconds = minutes * 60 + seconds;
    return totalSeconds * 1000; // Convert seconds to milliseconds
  };

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    setMediaStream(stream); 
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunks.current.push(event.data);
      }
    };
    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });    
   
      submitAudio(audioBlob);
            
    const reader = new FileReader();
  reader.onloadend = () => {
    const base64data = reader.result;
     sessionStorage.setItem('audioBlob', base64data);
  };
  reader.readAsDataURL(audioBlob);
      audioChunks.current = [];
    };    
    mediaRecorderRef.current.start();
       if (previoustime) {
     const time=mydata.overlayispaused?mydata.overlaypausetime:previoustime
      const existingSeconds = convertToMilliseconds(time);
      elapsedTimeRef.current = existingSeconds; // Set elapsed time to previous time

  } else {

   
    const time=mydata.overlayispaused?mydata.overlaypausetime:false

    const existingSeconds = convertToMilliseconds(time?time:previoustime);
      elapsedTimeRef.current = time?existingSeconds:0; // Initialize to 0 if no previous time
  }

    startTimeRef.current = Date.now(); // Record start time

    setIsRecording(true);
    setIsPaused(false);
    updateRecordingTime(); // Start timer
    if (mydata.overlayispaused) {   
   setModalValue({ ispaused: true });  //added-- 
    setModalValue({overlayispaused:false})
   pauseRecording()
   setModalValue({pausetime:mydata.overlaypausetime})   //added 
    setPersistRecordTime(mydata.overlaypausetime)
   setSavedTime(true)    
}
    
  };
useEffect(()=>{
updateRecordingTime()
},[])

  const stopRecording = () => {
 if (!isRecording) return;  

 setModalValue({recordtime:recordingTime})
    mediaRecorderRef.current.stop();
     setIsRecording(false);
    setIsPaused(false);
    setBackScreen(false);
    cancelAnimationFrame(animationFrameRef.current); 
    return 
  };

  const pauseRecording = () => {
    if (!mediaRecorderRef.current) return;

    if (mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.pause();
      
      setIsPaused(true);
      elapsedTimeRef.current += Date.now() - startTimeRef.current;
      setModalValue({pausetime:recordingTime})//pausefunction
      setPersistRecordTime(recordingTime)
      setSavedTime(true)
      // Update total elapsed time
      cancelAnimationFrame(animationFrameRef.current); // Stop timer
    }
  };

  const resumeRecording = () => {
    if (!mediaRecorderRef.current) return;
    if (mediaRecorderRef.current.state === "paused") {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimeRef.current = Date.now(); // Reset start time
      
      setSavedTime(false)
      updateRecordingTime(); // Resume timer
    }
  };

  const updateRecordingTime = () => {
    const now = Date.now();
    const elapsedTime = Math.floor((now - startTimeRef.current + elapsedTimeRef.current) / 1000); // Add elapsed time to current time
    const minutes = Math.floor(elapsedTime / 60);
    const seconds = elapsedTime % 60;
    setRecordingTime(
      `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
    );

    animationFrameRef.current = requestAnimationFrame(updateRecordingTime); // Continue updating timer
  };

  useEffect(() => {
    if (!isRecording) {
      startRecording();
    }

    return () => {
      cancelAnimationFrame(animationFrameRef.current); // Clean up timer on component unmount
    };
  }, [isRecording]);

  const handleDelete = () => {
    sessionStorage.removeItem('audioBlob');

    setToastObject({
      icon: CancelIcon,
      text1: "Your Recording is Successfully Cancelled!",
      text2: "",
    });
    setToastAlert(true);
    setBackScreen(false);
  };
  useImperativeHandle(myref, () => ({
    startRecording: () => setIsRecording(true),
    stopRecording,
  }));
  
  return (
    <>
      <div className="mt-[7%] gap-5 flex flex-col justify-center items-center">
        <div className="h-28 overflow-hidden">
            <MusicWaves  mediaStream={mediaStream} isPaused={isPaused} /> 
        </div>
     
        {isPaused && (
          <div className="flex items-center justify-center w-[50%]">
            <p className="[font-family:'DM_Sans-Regular',Helvetica] font-normal text-black text-sm text-center tracking-[0] leading-4">
              Remember to tap ‘Save’ to keep your recording
            </p>
          </div>
        )}
        <div className="flex flex-col gap-2 items-center justify-center">
          <div className="[font-family:'Poppins-SemiBold',Helvetica] font-semibold text-black text-base text-center tracking-[0] leading-[normal]">
            {!isPaused ? "Recording..." : "Paused..."}
          </div>
          <div className="flex flex-row mt-4 gap-4 justify-between items-center">
            <button
              onClick={() => {
                if (!isPaused) {
                  setIsDelete(true);
                  pauseRecording();
                  setIsDelete(false);
                }            
                setDeleteModal(true);
            
              }}
              className="bg-[#1c2a3ae6] rounded-lg p-2 "
            >
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-xs text-center tracking-[0] leading-[normal] whitespace-nowrap">
                Delete
              </div>
            </button>
            <div
              className="relative cursor-pointer"
              onClick={() => {
                if (isPaused) {
                  resumeRecording(); // Call resumeRecording when paused
                  setModalValue({ ispaused: false }); // Update modalValue to false
                } else {
                  pauseRecording(); // Call pauseRecording when not paused
                  setModalValue({ ispaused: true }); // Update modalValue to true
                }
              }}
              
            >
              <img
                src={redcircle}
                alt="Red Circle"
                className="w-[80px] h-[80px] cursor-pointer"
              />
              <img
                src={!isPaused ? pause : play}
                alt="Pause/Play"
                className="absolute inset-0 m-auto w-[34px] h-[34px]"
              />
            </div>
            <button
              onClick={()=>{
                restrictSave.current=true
                stopRecording()
              }}
              className="bg-[#1c2a3ae6] rounded-lg p-2"
            >
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-xs text-center tracking-[0] leading-[normal] whitespace-nowrap">
                Save
              </div>
            </button>
          </div>
          <div className="[font-family:'Inter-Regular',Helvetica] font-normal text-[#1c2a3a] mt-8 text-lg text-center tracking-[0] leading-[30px] whitespace-nowrap">
  {recordingTime.length !== 5 ? "00:00" : (showsavedtime ? (showsavedtime ? persistRecordTime : recordingTime) : recordingTime)}

</div>

        </div>
      </div>
      <DeleteToastModal
        handledelete={handleDelete}
        isModalOpen={deleteModal}
        setPreviousTime={setPreviousTime}
        setModalOpen={setDeleteModal}
      />
    </>
  );
};

export default RecordingPlayer;
