// store.js
import { configureStore, createSlice } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    savedload: {}
  },
  reducers: {
    setSavedload: (state, action) => {
      state.savedload = action.payload;
    },
    reset: (state) => {
      state.savedload = {};
    }
  }
});

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    providers: [],
    visittype: []
  },
  reducers: {
    setProviders: (state, action) => {
      state.providers = action.payload;
    },
    setVisittype: (state, action) => {
      state.visittype = action.payload;
    },
    reset: (state) => {
      state.providers = [];
      state.visittype = [];
    }
  }
});

const dashboardTabSlice = createSlice({
  name: 'dashboardTab',
  initialState: {
    selectedTab: null
  },
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    reset: (state) => {
      state.selectedTab = null;
    }
  }
});

export const { setSavedload, reset: resetData } = dataSlice.actions;
export const { setProviders, setVisittype, reset: resetSettings } = settingsSlice.actions;
export const { setSelectedTab, reset: resetDashboardTab } = dashboardTabSlice.actions;

const persistConfig = {
  key: 'root',
  storage,
};

const persistedDataReducer = persistReducer(persistConfig, dataSlice.reducer);
const persistedSettingsReducer = persistReducer(persistConfig, settingsSlice.reducer);
const persistedDashboardTabReducer = persistReducer(persistConfig, dashboardTabSlice.reducer);

export const store = configureStore({
  reducer: {
    data: persistedDataReducer,
    settings: persistedSettingsReducer,
    dashboardTab: persistedDashboardTabReducer
  }
});

export const persistor = persistStore(store);

// Add logging to check if the state is persisting
store.subscribe(() => {
  console.log('');
});
export const clearPersistedData = () => {
  persistor.purge();
  console.log('All persisted data has been cleared.');
};
