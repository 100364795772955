import React, { useEffect, useState, useRef } from 'react';
import profilering from '../../assets/profilering.png';
import face from '../../assets/profile.png';
import { useNavigate } from 'react-router-dom';
import arrowleft from '../../assets/arrowleft.png';
import api from '../../api/api';
import { CircularProgress, TextField, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import editIcon from '../../assets/edit.png';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import UploadSignatureModal from './uploadsignaturemodal';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ToastMessage from 'utils/muialerts/righttoptoast';
import { set } from 'date-fns';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import SignatureModal from 'alerts/recordingscreen/signature';
import useModalStore from 'zustand/zustandstore';
const ProfileDetails = ({ screenhandler }) => {
  const [showdp,setShowdp]=useState(false)
  const navigate = useNavigate();
  const[fetchimg,setFetchimg]=useState(false)
  const [showUploadSignature, setShowUploadSignature] = useState(false);
  const[signaturefile,setSignatureFile]=useState('')
  const [data, setData] = useState({
    username: '',
    email: '',
    phone: '',
    bm_user:false,
    signature_picture:''
  });
  const [loading, setLoading] = useState(true);
  const [profilePic, setProfilePic] = useState(false);
  const fileInputRef = useRef(null); // Ref for the file input
  const [open, setOpen] = useState(false);
  const[usertype,setUserType]=useState('')
  const mydata=useModalStore()
  const [showsignature,setShowSignature]=useState(false)
  const [message, setMessage] = useState('');
 const [severity, setSeverity] = useState('success');
 const[signature,setSignature]=useState('')
  const uploadProfile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await api.put('/v1/users/upload-profile-pic', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Assuming the new image URL is in response.data.profile_picture_url
      const newProfilePicUrl = response.data.profile_picture_url;
  
      // Update the profilePic with the new URL and add a timestamp to avoid caching issues
      setProfilePic(`${newProfilePicUrl}?t=${new Date().getTime()}`);
      
      console.log('Profile picture uploaded:', response.data);
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    } finally {
      fetchProfile();  // Optionally fetch the profile again to ensure consistency
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};
  const fetchProfile = async () => {
    try {
      setFetchimg(true);
      const response = await api.get('/v1/users/profile-pic');
  
      if (response.status === 200) {
        
        setProfilePic(`${response.profile_picture_url}?t=${new Date().getTime()}`);
        setShowdp(true);
      }
    } catch (error) {
      setProfilePic(face); // Fallback to default face image
      setShowdp(false);
      console.error('Error fetching profile picture:', error);
    } finally {
      setFetchimg(false);
    }
  };
  const fetchsignature = async () => {
    try {
      setSignature(''); // Reset signature to clear previous image
  
      // Fetch the image as an ArrayBuffer
      const response = await api.get(`/v1/users/signature-pic?t=${Date.now()}`, {
        responseType: 'arraybuffer',
      });
  
      // Create a Blob from the ArrayBuffer
      const blob = new Blob([response], { type: 'image/png' });
      const imageUrl = URL.createObjectURL(blob); // Create a new Blob URL
  
      // Update state with the new signature
      setSignature(imageUrl);
      setShowSignature(true);
  
      // Cleanup the old Blob URL if it exists
      if (signature) {
        URL.revokeObjectURL(signature);
      }
    } catch (error) {
      console.log(error);
      setShowSignature(false);
    }
  };
  
  // Cleanup Blob URL on component unmount or when signature changes
  useEffect(() => {
    return () => {
      if (signature) {
        URL.revokeObjectURL(signature);
      }
    };
  }, [signature]);
  
  
  const getUser = async () => {
    try {
      const response = await api.get('/auth/me');      
      setData({
        username: response.username,
        email: response.email,
        phone: response.mobilephone,
        fullname: response.firstname + " " + response.lastname,
        bm_user:response.bm_user,
        signature_picture:response.signature_picture
      });
      setUserType(response?.config?.user_type)
    } catch (error) {
      console.error('Error fetching user data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      
      uploadProfile(file); 
    }
  };
  

  const handleEditClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  useEffect(() => {
    getUser();
    fetchProfile();
  
  }, []);
const handleDeleteSignature=async()=>{
try {
  const response=await api.delete('/v1/users/signature')
  
} catch (error) {
  
}finally{
  getUser()
}

}
  const handleNavigate = (label) => {
    screenhandler(label, data.username, data.phone, data.email);
  };
  const uploadSignature = async (file) => {
    try {
           setFetchimg(true);
      setShowUploadSignature(false);
     const formData = new FormData();
      formData.append('file', file);   
          const response = await api.put('/v1/users/upload-signature', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }); 
      if(response.status===200){
        setMessage(response.message);
        setSeverity('success'); // Change severity as needed
        setOpen(true);
      }    
  
    } catch (error) {
      setMessage('something went wrong');
      setSeverity('error'); // Change severity as needed
      setOpen(true); 
      console.error('Error uploading signature:', error);
    }finally{
      getUser()
      setFetchimg(false);
    }
  };
  
  return (
    <>
      {loading||fetchimg && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <CircularProgress color="inherit" />
        </div>
      )}
      <div className={`w-full h-auto bg-white mb-4 sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-0 md:p-6 sm:p-6 shadow-none sm:shadow-lg md:shadow-lg md:bg-white sm:bg-white  sm:min-h-[600px] md:min-h-[540px] sm:rounded-[25px] md:rounded-[25px]`}>
        <div className='flex gap-8'>
          <div className="flex items-start">
            <img
              onClick={() => {
                if(mydata.usertype==='PRO'){
                  navigate('/landing')
               }else{
                 navigate('/app') 
               }}}
              src={arrowleft}
              alt="Back Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <div className="flex items-center justify-start gap-2">
            {!fetchimg&&(showdp?<div className="relative flex items-center justify-center">
              <img src={profilering} alt="Profile Ring" className="w-[71px] h-[73px]" />
              <img src={profilePic || face} alt="Face" className="absolute w-[63px] h-[63px] left-1 top-1 rounded-full" />
              <IconButton onClick={handleEditClick} className="absolute bottom-0 right-6 top-5">
                <img src={editIcon} alt="Edit" className="w-[20px] h-[20px]" />
              </IconButton>
              {/* Hidden input for selecting file */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>:<div className="relative flex items-center justify-center">
              <img src={profilering} alt="Profile Ring" className="w-[75px] h-[75px]" />
              <img src={profilePic || face} alt="Face" className="absolute w-[60px] h-[61px] left-2 top-3 rounded-full" />
              <IconButton onClick={handleEditClick} className="absolute bottom-0 right-6 top-5">
                <img src={editIcon} alt="Edit" className="w-[20px] h-[20px]" />
              </IconButton>
              {/* Hidden input for selecting file */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>)}
            <div className='flex flex-col text-start'>
              <div className="font-semibold text-black text-lg">{data.username}</div>
              <div className="opacity-50 text-black text-sm">{data.email}</div>
            </div>
      
          </div>
        </div>
        <hr className="border-t border-[#D0D0D0] w-full m-4" />
        <div className='flex w-full items-center justify-center'>
          <div className='flex flex-col w-[60%] mb-4 gap-6'>
            {[
              { label: "Full Name", type: "text", placeholder: "", value: data.fullname },
              { label: "Email", type: "email", placeholder: "", value: data.email },
              { label: "Phone Number", type: "tel", placeholder: "Enter phone number", value: data.phone },
              { label: "Current Password", type: "password", placeholder: "********", value: '', onChange: () => {} },
            ].map((inputField, index) => (
              <div key={index} className='flex flex-col w-full items-start gap-3'>
                <label className="opacity-90 text-black">{inputField.label}</label>
                <div className="relative w-full">
                  <TextField
                    type={inputField.type}
                    placeholder={inputField.placeholder}
                    value={inputField.value}
                    onChange={inputField.onChange}
                    InputProps={{
                      style: {
                        height: '46px', // Adjust the height here
                        fontSize: '14px', // Adjust font size if needed
                        padding: '0 14px', // Adjust padding to fit the height
                        backgroundColor: '#f9f9f9',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    disabled
                    className="bg-[#f9f9f9]  pr-10" // Padding to the right to make space for the icon
                  />
                  {(inputField.label === "Email" || inputField.label === "Current Password") && (
                    <span 
                      onClick={() => handleNavigate(inputField.label)}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <FontAwesomeIcon icon={faEdit} className="text-gray-600 cursor-pointer" />
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
         
        </div>
        <div className='flex w-full mb-8 items-center justify-center'>
<div className='w-[60%] flex flex-col'>
<label className="opacity-90 text-black text-start">My Plan</label>
  <div  className='flex flex-wrap mt-2 gap-7 justify-around'>
<div className='flex items-center gap-4'>
<div className='flex w-9 rounded-full h-9 items-center justify-center bg-[#c9d9f7] '>

 <RequestQuoteIcon sx={{color:""}}/>
</div>
<div className='flex flex-col'>

<label className="opacity-90 text-black text-start">Experience</label>
<div className="h-4  [font-family:'Inter-Regular',Helvetica] font-normal text-[#b1b1b1] text-xs tracking-[0] leading-4 whitespace-nowrap">
{usertype==='BUSINESS'?"Meeting":"Medical"}
      </div>

</div>

</div>

<div className='flex items-center gap-4'>
<div className='flex w-9 rounded-full h-9 items-center justify-center bg-[#c9d9f7] '>

 <RequestQuoteIcon sx={{color:""}}/>
</div>
<div className='flex flex-col'>

<label className="opacity-90 text-black text-start">Tier</label>
<div className="h-4  [font-family:'Inter-Regular',Helvetica] font-normal text-[#b1b1b1] text-xs tracking-[0] leading-4 whitespace-nowrap">
      {usertype}
      </div>

</div>

</div>
<button
      onClick={()=>navigate('/plans')}  
        className={`w-[30%] sm:w-[30%] md:w-[28%] h-10 text-white text-sm font-semibold rounded-lg bg-[#1C2A3A] hover:bg-[#173041]`}
          
          >
        Manage Plan
          </button>
  </div>
</div>
          
        </div>



        {mydata.usertype!=='BUSINESS'?(data?.signature_picture ? (
  
    <div className="flex gap-3 mt-4 items-center justify-center">
      <div className="font-semibold text-[#1c2a3a] text-[17px] cursor-pointer leading-7">
        {data?.fullname + '.png'}
      </div>
      <CheckCircleIcon
        onClick={() => fetchsignature()}
        style={{ color: "green" }}
      />
      <span>
        <FontAwesomeIcon
          onClick={() => setShowUploadSignature(true)}
          icon={faEdit}
          className="text-gray-600 cursor-pointer"
        />
      </span>
      <FontAwesomeIcon
onClick={()=>handleDeleteSignature()}
      icon={faTrashCan} />
      <FontAwesomeIcon
        onClick={() => fetchsignature()}
      icon={faEye} style={{ fontSize: "16px", marginRight: "4px" }} />
 
    </div>
  )
 : (
  <div
    onClick={() => setShowUploadSignature(true)}
    className="font-semibold text-[#1c2a3a] text-[17px] cursor-pointer leading-7"
  >
    <FontAwesomeIcon icon={faCloudArrowUp} /> Upload your Signature
  </div>
)):<></>}


      </div>
      {showUploadSignature && (
        <UploadSignatureModal 
        
        setShowUploadSignature={setShowUploadSignature}
        setSignatureFile={setSignatureFile}
        uploadSignature={uploadSignature}
        />
      )}
      <SignatureModal signature={signature}  isOpen={showsignature}   onClose={setShowSignature} />
       <ToastMessage
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
    </>
  );
};

export default ProfileDetails;
