
//audio-

import api from "../api/api";
export const Combinemyaudio= async(audio,mydata)=>{
try {
     
      const formData = new FormData();
      formData.append("file", audio);
      formData.append("departmentid", mydata.depId);
      formData.append("patientid", mydata.patId);
      formData.append("appointmentid", mydata.appId);
      formData.append("category", "combine");
      formData.append("tags", 'tagsdata'); 
      formData.append("notes", "testing data");
      formData.append("practiceid",mydata.practId);
      formData.append("filename", mydata?.filename);
      formData.append("patientname", mydata.patName);
      formData.append("rowid", mydata.rId);
  
      const response = await api.post('v1/ehr/recording/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          
        },
      }); 
     return response
  
  
  } catch (error) {    
  console.log(error)
  }
 
  }

export const submitmyaudio= async(audio,mydata)=>{

try {
   
    const formData = new FormData();
    formData.append("file", audio);
    formData.append("departmentid", mydata.depId);
    formData.append("patientid", mydata.patId);
    formData.append("appointmentid", mydata.appId);
    formData.append("notes", "testing data");
    formData.append("practiceid",mydata.practId);

    const response = await api.post('v1/ehr/recording/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        
      },
    }); 
   return response


} catch (error) {    
console.log(error)
}

}
