import React from "react";
import { Card, CardContent, Checkbox, FormControlLabel } from "@mui/material";
import arrowleft from "../../../assets/arrowleft.png";
import blinkscribelogo from "../../../assets/blinkscribe.png";
import usericon from "../../../assets/user.png";
import StripeCheckout from "react-stripe-checkout";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const PaymentOptions = () => {
  const handleToken = async (token, addresses) => {
    try {
      const res = await axios.post("https://ed6l3.sse.codesandbox.io/checkout", {
        token,
      });
      const { status } = res.data;
      if (status === "success") {
        toast.success("Success! Check your email for details.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error("Payment processing failed. Please try again later.");
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <Card className="w-[100%] md:w-[40%] sm:w-[40%] max-w-lg md:max-w-2xl p-6 shadow-lg h-screen md:h-[600px] sm:h-[600px] rounded-lg bg-white">
          <CardContent className="h-full">
            <div className="flex items-start">
              <img
                src={arrowleft}
                alt="Down Arrow"
                className="w-5 h-5 cursor-pointer"
              />
            </div>

            <div className="flex flex-col items-center justify-between h-full">
              <div className="flex justify-center">
                <img
                  src={blinkscribelogo}
                  alt="BlinkScribe Logo"
                  className="h-16 w-auto"
                />
              </div>

              <div className="w-full flex flex-col items-center gap-4">
                <div className="relative mb-4 w-[60%] mx-auto">
                  <input
                    type="text"
                    placeholder="Email"
                    className="w-full bg-transparent h-[45px] border-[1px] p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
                  />
                  <img
                    src={usericon}
                    alt="User Icon"
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500"
                  />
                </div>

                <div className="relative mb-4 w-[60%] mx-auto">
                  <input
                    type="text"
                    placeholder="Practice ID (optional)"
                    className="w-full bg-transparent h-[45px] border-[1px] p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
                  />
                  <img
                    src={usericon}
                    alt="User Icon"
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500"
                  />
                </div>

                <div className="w-[60%] flex items-center justify-start">
                  <FormControlLabel
                    control={<Checkbox size="small" />}
                    className="text-gray-800"
                  />
                  <span className="text-sm text-start">
                    I have reviewed and signed the authorization consent form{" "}
                    <a href="#" className="text-blue-600 underline">
                      link
                    </a>.
                  </span>
                </div>
              </div>

              <div className="w-full flex items-center flex-col gap-4">
                <StripeCheckout
                  stripeKey="pk_test_51JGNLWBVnEa8wQ1y8ZGMn9tw57qHCROwaNVr5eplb1UvQsN410gJpXPyNW8yFgNQZeM7twAoAjZ7LosccszLnDMz00pIIh0lL0"
                  token={handleToken}
                  billingAddress
                  shippingAddress
                  amount={50 * 100}
                  name="Annual Subscription"
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* ToastContainer for Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default PaymentOptions;
