import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useModalStore from "zustand/zustandstore";

const WarningModal = ({ isModalOpen, setIsModalOpen,handleButtons}) => {
  const { setModalValue } = useModalStore();

  if (!isModalOpen) {
    return null; // Return null if modal is not open
  }

  return (
    <div
      id="popup-modal"
      tabIndex="-1"
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
    >
      <div className="relative p-4 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg max-h-full mx-4">
        <div className="relative bg-white rounded-lg shadow-md dark:bg-gray-800">
          <div className="p-4 md:p-6 text-center">
            <div>
              <DeleteForeverIcon
                style={{ width: "60px", height: "60px", color: "#1D2951" }}
              />
            </div>
            <div className="mb-2 mt-3 font-sans text-lg font-medium text-gray-800 dark:text-gray-200">
              Unsaved Changes Detected
            </div>
            <p className="mb-4 text-sm text-gray-600 dark:text-gray-300">
              You have made changes that haven’t been saved. Leaving this page
              will discard your changes. Would you like to save them before
              exiting?
            </p>
            <div className="flex flex-wrap items-center justify-center mt-4 gap-4 w-full">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-2 py-2 w-auto  bg-gray-500 text-white text-sm font-semibold rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                    handleButtons('save')
                 
                }}
                className="px-2 py-2 w-auto  bg-[#1c2a3a] text-white text-sm font-semibold rounded-lg"
              >
                Save Changes
              </button>
              <button
                onClick={() => {
                    handleButtons('unsave')
                  setIsModalOpen(false);
                }}
                className="px-2 py-2 w-auto  bg-red-500 text-white text-sm font-semibold rounded-lg"
              >
                Leave Without Saving
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
