import { useState } from "react";
import TermsOverlayCard from "./termsoverlay";
import AppOptions from "general/overlayscreens/appoptions";
import PricingOptions from "general/overlayscreens/pricing";
import ForceLogoutPopup from "./forcelogout";

import { useDispatch } from "react-redux";
import { resetData, resetSettings, setProviders, setVisittype } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import PaymentOptions from "./paymentoptions";

const OptionWrap=({handleVerified})=>{
    const [isPopupOpen, setPopupOpen] = useState(false);
    const dispatch= useDispatch()
    const navigate = useNavigate();
    const handleClosePopup = () => {
        dispatch(resetSettings())
        dispatch(setProviders([]))
        dispatch(setVisittype([]))
        dispatch(resetData());
        localStorage.removeItem('mytab')
        sessionStorage.removeItem('auth');
        navigate('/auth');

      setPopupOpen(false);
     
    };
const [screen,setScreen]=useState(0)
    return(<>
    {screen==0&&<AppOptions
    setPopupOpen={setPopupOpen}
    handleVerified={handleVerified}
    setScreen={setScreen}/>}
    {screen==1&&<PricingOptions
     setPopupOpen={setPopupOpen} 
    handleVerified={handleVerified}
    setScreen={setScreen}/>}

    {/* {screen==4&&<PaymentOptions/>} */}
          <ForceLogoutPopup isOpen={isPopupOpen} onClose={handleClosePopup}/>
    </>)
    
}
export default OptionWrap