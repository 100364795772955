import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';

const UploadSignatureModal = ({ setShowUploadSignature,setSignatureFile,uploadSignature }) => {
  const [uploadedSignature, setUploadedSignature] = useState(null);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg']
    },
    onDrop: (acceptedFiles) => {
      setUploadedSignature(acceptedFiles[0]);
      setSignatureFile(acceptedFiles[0])
    }
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-[90%] sm:w-[30%] h-[60%] flex flex-col justify-around  rounded-md shadow-lg p-6">
        {/* Adjusting text alignment to start from the left */}
        <div className="mb-4 w-full">
          <div className="font-semibold text-[#1c2a3a] text-lg sm:text-xl leading-7 text-left">Upload Signature</div>
          <div className="w-full mt-2 bg-[#FFF] border-b border-[#E3E3E3] rounded-t-lg h-1"></div>

        </div>

        {/* Signature Upload Section */}
        <div {...getRootProps()} className="flex flex-col items-center justify-center border border-dashed border-gray-400 h-[40%] p-4 text-center">
          <input {...getInputProps()} />
          <div>
            <FontAwesomeIcon icon={faCloudArrowUp} />
          </div>
          <div className="font-normal text-gray-500 text-xs sm:text-xs leading-[22.5px]">
            {isDragActive ? "Drop the file here..." : "Click or drag file to this area to upload your signature"}
          </div>
        </div>

        {/* Accepted formats */}
        <div className="font-normal text-start text-gray-500 text-xs sm:text-xs leading-[22.5px] mt-2">
          Formats accepted are .png and .jpeg
        </div>
        <div className="w-full mt-2 bg-[#FFF] border-b border-[#E3E3E3] rounded-t-lg h-1"></div>

        {/* Uploaded Signature File Name */}
        {uploadedSignature && (
          <div className="font-normal text-start text-gray-500 text-xs sm:text-xs leading-[22.5px] mt-2">
            File Name: {uploadedSignature.name}
          </div>
        )}

        {/* Cancel and Confirm Buttons */}
        <div className="w-full mt-4 mx-auto gap-2 sm:w-full items-end flex justify-end">
          <button
            onClick={() => {
              setUploadedSignature(null);
              setSignatureFile('')
              setShowUploadSignature(false);
            }}
            className="w-[28%] h-10 bg-[#FFF] text-[#232222] border border-[#CBCBCB] text-sm font-semibold rounded-lg"
          >
            Cancel
          </button>
          <button
            disabled={!uploadedSignature}
            onClick={() => {
                uploadSignature(uploadedSignature)
               }}
            className={`w-[28%] h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-lg ${!uploadedSignature ? 'opacity-60 cursor-not-allowed' : ''}`}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadSignatureModal;
