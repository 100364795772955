import React, { useState, useRef, useEffect } from "react";
import filterIcon from "../../assets/filter.png";
import downArrow from "../../assets/arrow-down.png";
import calendarIcon from "../../assets/calendaricon.png";
import rightArrow from "../../assets/arrowright.png";
import checkmark from "../../assets/checkmark.png";
import summarizedpng from '../../assets/summarized.png'
import ring from "../../assets/ring.png";
import { DateRange } from "react-date-range";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./scribefilter.css";
import CachedIcon from "@mui/icons-material/Cached";
import { useNavigate } from "react-router-dom";
import { decodeTimeToISO } from "utils/date";
import { formatDateToYMD } from "utils/date";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Ring from "./ring";
import { useDispatch, useSelector } from "react-redux";
import { setSavedload, setProviders, setVisittype, resetSettings, resetData, clearPersistedData } from "../../redux/store";
import { Button, Chip, Stack, Tooltip } from "@mui/material";
import { currentformatedDate } from "utils/date";
import ClearIcon from '@mui/icons-material/Clear';
import { toZonedTime } from 'date-fns-tz';
import useModalStore from "zustand/zustandstore";
function valuetext(value) {
  return `${value}°C`;
}

const storedDate = (x) => {
  let normaldate = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ];
  const dateFilter = x?.filter_array?.date_filter;

  if (
    !dateFilter ||
    !dateFilter.start_date ||
    !dateFilter.end_date ||
    !dateFilter.start_time ||
    !dateFilter.end_time
  ) {
    return normaldate;
  } else {
    let changed = [
      {
        startDate: new Date(dateFilter.start_date),
        endDate: new Date(dateFilter.end_date),
        key: "selection",
      },
    ];
    return changed;
  }
};


const formatChipDate = (date) => {
  // Check if the input is a valid Date
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return false; // Return false for invalid dates
  }

  // Convert the date to the desired timezone
  const timezone = 'Asia/Kolkata'; // Adjust the timezone as needed
  const zonedDate = toZonedTime(date, timezone);
  const today = toZonedTime(new Date(), timezone); // Get today's date in the same timezone

  // Normalize both dates to remove time components
  const isSameDay = zonedDate.getFullYear() === today.getFullYear() &&
    zonedDate.getMonth() === today.getMonth() &&
    zonedDate.getDate() === today.getDate();

  if (isSameDay) {
    return false; // Return false if the date is today
  }

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const dayOfMonth = zonedDate.getDate();
  const month = months[zonedDate.getMonth()];
  const year = zonedDate.getFullYear();

  // Format the date as "2 Sep, 2024"
  return `${dayOfMonth} ${month.slice(0, 3)}, ${year}`; // Shortened month name
};




const formatTime = (val) => {
  const hours = Math.floor(val / 60);
  const minutes = val % 60;
  const formattedMinutes =
    minutes === 0 ? "00" : minutes.toString().padStart(2, "0");
  return `${hours % 24}:${formattedMinutes}`; // Format time as HH:MM
};
const RangeSlider = ({ value, setValue }) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: 300 }}>
      <Slider
        getAriaLabel={() => "Time range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(val) => formatTime(val)}
        min={0}
        max={1440} // Total minutes in a day (24 hours * 60 minutes)
        step={10} // Adjust this to set the granularity of the slider (e.g., 10 minutes)
        marks={
          [
            // { value: 0, label: '00:00' },
            // { value: 360, label: '06:00' },
            // { value: 720, label: '12:00' },
            // { value: 1080, label: '18:00' },
            // { value: 1440, label: '24:00' },
          ]
        }
      />
      <div>
        {formatTime(value[0])} - {formatTime(value[1])}
      </div>
    </Box>
  );
};
const convertTimeToValue = (timeString) => {
  if (!timeString) {
    return 420;
  }
  // Extract hours and minutes from the time string
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  // Convert to total minutes
  return hours * 60 + minutes;
};
const ScribeTable = ({
  fetchDashBoardData,
  datetoday,
  filteredData,
  fetchEhr,
  isToSyc,
  bypassLoader,
  setoffset,
  originaldata,
  handletimeStamp,
  handleRecordType,
  isFilterOpen,
  setIsFilterOpen,
  data,
  istableloading,
  isfirstload,
  setIsDateOpen,
  isDateOpen,
  handleallreduxclose,
}) => {
  const savedload = useSelector((state) => state.data.savedload);
  const provid = useSelector((state) => state.settings.providers);
  const vis = useSelector((state) => state.settings.visittype);
  const filterRef = useRef(null);
  const [value, setValue] = useState([420, 1140]);
  const [isbuttonClicked, setIsbuttonclicked] = useState(false)
  const mydata = useModalStore();
  const {setModalValue}=useModalStore()
  //420 1140
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = new Date();
  
  const [selectedProviders, setSelectedProviders] = useState(
    provid && provid.length ? provid : []
  );

  const [selectedVisits, setSelectedVisits] = useState(
    vis && vis.length ? vis : []
  );
  const [uniqueVisitType, SetUniqueVisitType] = useState([]);
  const [uniqueProvider, setUniqueProvider] = useState([]);
  const [datestate, setdateState] = useState(storedDate(savedload));
  const handleTableRowSelect=(a,b,c,d)=>{   
    if(mydata.patId==d&&mydata.isOpen){
      // alert('a')
     sessionStorage.setItem("isbacktorecord",'a'); 
       setModalValue({ continueSave :true})        
    }else{
      // alert('b')
      sessionStorage.setItem("isbacktorecord", 'b');   
    }
 navigate(`/record/${a}/${b}`, {
                        state: { ts: c, patientid: d },
                      });
  }

  const handledateChange = (value) => {
    setdateState(value);
  };

  const handleProviderClick = (provider) => {
    setSelectedProviders((prev) =>
      prev.includes(provider)
        ? prev.filter((item) => item !== provider)
        : [...prev, provider]
    );
  };
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  function isWeekend(dateString) {
    const date = new Date(dateString);
    const day = date.getDay();  // getDay() returns 0 for Sunday, 6 for Saturday

    return day === 0 || day === 6; // Returns true if it's Sunday or Saturday
  }
  useEffect(() => {
    SetUniqueVisitType([
      ...new Set(filteredData.map((record) => record?.purpose.toUpperCase())),
    ]);
    setUniqueProvider([
      ...new Set(
        filteredData.map((record) => record?.providername.toUpperCase())
      ),
    ]);
  }, [filteredData]);

  useEffect(() => {
    if (savedload.filter_array?.date_filter.start_time) {
      setValue([
        convertTimeToValue(savedload.filter_array?.date_filter.start_time),
        convertTimeToValue(savedload.filter_array?.date_filter.end_time),
      ]);
    }
  }, [savedload]);

  const handleVisitClick = (visit) => {
    setSelectedVisits(
      (prev) =>
        prev.includes(visit)
          ? prev.filter((item) => item !== visit)
          : [...prev, visit] // Add if not selected
    );
  };

  //handle datefilter close
  const dateOnCloseHandler = () => {
    setIsFilterOpen(false);
    if (isDateOpen) {
      handletimeStamp({
        startDate: formatDateToYMD(datestate[0].startDate),
        endDate: formatDateToYMD(datestate[0].endDate),
        startTime: decodeTimeToISO(value[0]),
        endTime: decodeTimeToISO(value[1]),
      });
    }
    setIsDateOpen(!isDateOpen);
  };

  useEffect(() => {
    setdateState(storedDate(savedload));
  }, []);
  useEffect(() => {
    setSelectedProviders(provid && provid.length ? provid : [])
    setSelectedVisits(vis && vis.length ? vis : [])
  }, [provid, vis])
  const handleproviderchipDelete = (x) => {
    setSelectedProviders((prevProviders) => {
      const updatedProviders = prevProviders.filter(provider => provider !== x);
      handleRecordType(updatedProviders, selectedVisits, true);
      return updatedProviders; // Update the state with the new array
    });
  };
  const handleVisitschipDelete = (x) => {

    const updatedVisits = selectedVisits.filter(visits => visits !== x);
    setSelectedVisits(updatedVisits)
    handleRecordType(selectedProviders, updatedVisits, true);

  };


  const handleDateChip = () => {

    handletimeStamp({
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      startTime: decodeTimeToISO(value[0]),
      endTime: decodeTimeToISO(value[1]),
    });

  }
  const handleOnclear = () => {
    // dispatch(setProviders([]))
    // dispatch(setVisittype([]))  
    clearPersistedData();
    // handletimeStamp({
    //   startDate:new Date().toISOString().split("T")[0],
    //   endDate: new Date().toISOString().split("T")[0],
    //   startTime: decodeTimeToISO(value[0]),
    //   endTime: decodeTimeToISO(value[1]),
    // });
    window.location.reload()
    //  fetchDashBoardData()
  }


  return (
    <div className="w-full bg-white rounded-[14px] p-4 relative">
      <div className="flex flex-wrap gap-4 mb-4 items-center relative">
        {(selectedProviders.length || selectedVisits.length) ? <>
          <Stack direction="row" spacing={1} className="flex flex-wrap">
            {/* providers */}
            {selectedProviders.length ? <>
              {selectedProviders?.map((item, index) => {
                return (<>
                  <Chip label={item}
                    onDelete={() => { handleproviderchipDelete(item) }}
                    style={{
                      margin: "4px", color: "#FFF", fontStyle: "normal", background: "#B4B4B4",
                    }}
                    deleteIcon={<div style={{ color: "white" }}><ClearIcon /></div>}

                  />
                </>)
              })}

            </> : <></>}
            {selectedVisits.length ? <>
              {selectedVisits?.map((item, index) => {
                return (<>
                  <Chip
                    label={item}
                    deleteIcon={<div style={{ color: "white" }}><ClearIcon /></div>}
                    onDelete={() => { handleVisitschipDelete(item) }}
                    style={{
                      margin: "4px", color: "#FFF", fontStyle: "normal", background: "#B4B4B4",

                    }}
                  />
                </>)
              })}

            </> : <></>}



          </Stack>
        </> : <></>
        }
        <Stack direction="row" spacing={1} className="flex flex-wrap">
          {formatChipDate(storedDate(savedload)[0].startDate) ? <Chip label={formatChipDate(storedDate(savedload)[0].startDate)}
            onDelete={handleDateChip}
            deleteIcon={<div style={{ color: "white" }}><ClearIcon /></div>}

            style={{
              margin: "4px", color: "#FFF", fontStyle: "normal", background: "#B4B4B4",
            }}
          /> : <></>}</Stack>

        {(selectedProviders.length || selectedVisits.length || formatChipDate(storedDate(savedload)[0].startDate)) ?
          <div style={{ color: "#7B7575" }} onClick={() => {

            handleOnclear()
            //handleallreduxclose()
          }}>  <ClearIcon style={{ fontSize: '33px' }} />
          </div> : <></>}

        {/* Filter button */}

        {/* <div
  ref={filterRef}
  className={`flex items-center justify-between w-[100px]  sm:w-[122px] md:w-[122px] h-7 rounded-md border border-solid border-black p-2 cursor-pointer 
  ${selectedProviders.length > 0 || selectedVisits.length > 0 ? 'bg-[#1c2a3a] text-white' : ''}`}
  onClick={() => {
    setIsDateOpen(false);           
    // handleRecordType(selectedProviders, selectedVisits, isFilterOpen);
    if(isFilterOpen){
      return
    }
    setIsFilterOpen(!isFilterOpen);
  }}
>
          <img src={filterIcon} alt="Filter Icon" className="w-3 h-3" />
          <div className="[font-family:'Inter-Medium',Helvetica] font-medium  text-sm tracking-[0] leading-[normal] whitespace-nowrap">
            Filter
          </div>
         
        <KeyboardArrowDownIcon/>
        </div> */}

        {/* Calendar button */}
        {/* <div
  className={`flex items-center justify-between w-[100px] sm:w-[122px] md:w-[122px] h-7 rounded-md border border-solid border-black p-2 cursor-pointer
    ${
      (datestate[0].startDate.getTime() !== datestate[0].endDate.getTime() || 
      datestate[0].startDate.toDateString() !== today.toDateString() || 
      datestate[0].endDate.toDateString() !== today.toDateString()) 
      ? 'bg-[#1c2a3a] text-white'  // Apply this class when the dates are different
      : ''
    }`}
onClick={()=>{
  if(!isDateOpen){
    setIsFilterOpen(false)
    setIsDateOpen(true)
  }
}}
>
  <img src={calendarIcon} alt="Calendar Icon" className="w-3 h-3" />
  <div className={`[font-family:'Inter-Medium',Helvetica] font-medium  text-sm tracking-[0] leading-[normal] whitespace-nowrap`}>
    Date
  </div>
  <KeyboardArrowDownIcon/>
</div> */}

        {isDateOpen && (
          <div className="absolute top-[110%] sm:top-[110%] lg:top-[150%] left-[0%] lg:left-[13%] z-20 bg-white p-4 md:p-2 sm:p-2 rounded-lg shadow-lg">
            {/* Date Picker */}
            <div>
              <div className="w-full flex justify-between  ">
                <div className="[font-family:'Poppins-Medium',Helvetica] font-bold text-black text-base text-center tracking-[0.16px] leading-[normal]">
                  Calender
                </div>
                {/* cancel button  */}
                <div onClick={() => dateOnCloseHandler()}>
                  {" "}
                  <CloseIcon style={{ color: "#B4B4B4", cursor: "pointer" }} />
                </div>
              </div>
            </div>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => handledateChange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={datestate}
              className="text-xs"
            />
            <div className="flex justify-center">
              <RangeSlider value={value} setValue={setValue} />
            </div>
          </div>

        )
        }


        {isFilterOpen && (
          <div className="flex flex-col lg:gap-8 md:gap-8 sm:gap-8 gap-4 items-start absolute top-10 left-0 w-full max-w-[450px] bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
            <div className="flex justify-between w-full items-center">
              <div className="[font-family:'Poppins-Medium',Helvetica] font-bold text-black text-base text-center tracking-[0.16px] leading-[normal]">
                Filter
              </div>
              <div>
                {/* cancel button  */}
                <div
                  onClick={() => {
                    // handleRecordType(selectedProviders, selectedVisits, isFilterOpen);
                    setIsFilterOpen(false);
                  }}
                >
                  <CloseIcon style={{ color: "#B4B4B4", cursor: "pointer" }} />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col items-start justify-start">
              <div
                style={{ fontWeight: 550, fontStyle: "Tahoma" }}
                className="  text-black text-[14px] tracking-[0.16px] leading-[normal]"
              >
                Provider
              </div>

              {originaldata.length ? (
                <div className="flex flex-wrap gap-2 lg:mt-8 md:mt-8 sm:mt-8 mt-4">
                  {uniqueProvider.map((provider, index) => (
                    <button
                      key={index}
                      onClick={() => handleProviderClick(provider)}
                      className={`w-24 p-2 h-8 shadow-lg rounded-[14px] text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 ${selectedProviders.includes(provider)
                          ? "bg-black text-white"
                          : "bg-white text-black border border-gray-300"
                        }`}
                    >
                      {provider}
                    </button>
                  ))}
                </div>
              ) : (
                <>Loading ...</>
              )}
            </div>

            <div className="w-full flex flex-col items-start justify-start">
              <div
                style={{ fontWeight: 550, fontStyle: "Tahoma" }}
                className=" font-medium text-black text-[14px] tracking-[0.16px] leading-[normal]"
              >
                Visit Type
              </div>

              {originaldata.length ? (
                <div className="flex flex-wrap gap-2 lg:mt-8 md:mt-8 sm:mt-8 mt-4">
                  {uniqueVisitType.map((visit, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        handleVisitClick(visit);
                      }}
                      className={`w-auto p-2 h-8 rounded-[14px]  shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 ${selectedVisits.includes(visit)
                          ? "bg-black text-white"
                          : "bg-white text-black border border-gray-300"
                        }`}
                    >
                      {visit}
                    </button>
                  ))}
                </div>
              ) : (
                <>Loading ...</>
              )}
              <div className="flex mt-5 items-center justify-center w-full">
                <button
                  className={`p-2 h-8 w-[40%] rounded-[14px] shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 bg-white text-black border border-gray-300`}
                  onClick={(e) => {
                    e.target.classList.toggle("bg-black");
                    e.target.classList.toggle("text-white");
                    e.target.classList.toggle("bg-white");
                    e.target.classList.toggle("text-black");

                    handleRecordType(
                      selectedProviders,
                      selectedVisits,
                      isFilterOpen
                    );
                    setIsFilterOpen(false);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Table */}
      <div className="w-full max-h-[50vh] md:max-h-[55vh] overflow-y-auto custom-scrollbar">
        <table className="w-full text-left border-collapse">
          <thead className="sticky top-0 bg-white ">
            <tr>
              <th className="py-2 px-4 [-webkit-text-stroke:0.3px_#060606] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                Patient Name
              </th>
              <th className="py-2 px-4 [-webkit-text-stroke:0.3px_#060606] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                Purpose
              </th>
              <th className="py-2 px-4 [-webkit-text-stroke:0.3px_#060606] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                Provider
              </th>

              <th className="py-2 px-4 [-webkit-text-stroke:0.3px_#060606] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                Time
              </th>
              <th className="py-2 px-4 [-webkit-text-stroke:0.3px_#060606] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                Status
              </th>
              <th className="py-2 px-4"> </th>
            </tr>
          </thead>

          <tbody className="max-h-[55vh]">
            {/* {istableloading ? ( */}
            {(isfirstload && istableloading) ? (
              <>
                {" "}
                <tbody>
                  <tr>
                    <td className="py-2 px-4" colSpan="5">
                      Loading ...
                    </td>
                  </tr>
                </tbody>
              </>
            ) : data.length ? (
              data.map((item, index) => (
                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (
                      item.transcriptionstatus === null ||
                      item.transcriptionstatus === ""
                    ) {
                      // navigate(`/record/${item.appointmentid}/${item.patientid}`, {
                      //   state: { ts: item.startdate, patientid: item.patientid },
                      // });
                      handleTableRowSelect(item.appointmentid,item.patientid,item.startdate,item.patientid)
                    } else {
                      // navigate(`/archive/${item.patientid}`
                      // navigate(`/record/${item.appointmentid}/${item.patientid}`, {
                      //   state: { ts: item.startdate, patientid: item.patientid },
                      // });
                      handleTableRowSelect(item.appointmentid,item.patientid,item.startdate,item.patientid)
                    }
                  }}
                  className={`hover:bg-[#A2C6FD26] ${item.status === "Ready" ? "bg-[rgba(162,198,253,0.15)]" : ""
                    } h-16 mb-2`}
                >
                  <td className="py-2 px-4 [font-family:'Poppins-Regular',Helvetica] font-normal text-[#7b7575] text-base tracking-[0] leading-[normal]">
                    {item.patient_name}
                    {` (${item.patientid})`}
                  </td>
                  <td className="py-2 px-4  text-start [font-family:'Poppins-Regular',Helvetica] font-normal text-[#7b7575] text-base tracking-[0] leading-[normal]">
                    {/* {new Date(item.startdate).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })} */}
                    {item.purpose}
                  </td>
                  <td className="py-2 px-4  text-start [font-family:'Poppins-Regular',Helvetica] font-normal text-[#7b7575] text-base tracking-[0] leading-[normal]">
                    {/* {new Date(item.startdate).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })} */}
                    {item.providername}
                  </td>

                  <td className="py-2 px-4 text-start [font-family:'Poppins-Regular',Helvetica] font-normal text-[#7b7575] text-base tracking-[0] leading-[normal]">
                    {new Date(item.startdate).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </td>

                  <td className="py-2 px-4 ">
                    {(() => {
                      if (
                        item.transcriptionstatus === null ||
                        item.transcriptionstatus === ""
                      ) {
                        return "Ready";
                      } else if (item.transcriptionstatus === "Submitted") {
                        return <Ring value={0} />;
                      } else if (item.transcriptionstatus === "Processing" &&
                        item.handling_time == null) {
                        return <Ring value={0} />;
                      } else if (
                        item.transcriptionstatus === "Processing" &&
                        item.handling_time != null
                      ) {
                        const handlingTimeInt = Math.round(item.handling_time);
                        return <Ring value={handlingTimeInt} />;
                      } else if (item.transcriptionstatus === "Summarized") {
                        return (
                          <Tooltip title="Pending Review">
                          <img
                            src={summarizedpng}
                            alt="Completed"
                            className="w-6 h-6"
                          /></Tooltip>
                        );
                      }else if (item.transcriptionstatus === "APPROVED") {
                        return (
                          <Tooltip title="Completed">
                          <img
                            src={checkmark}
                            alt="Completed"
                            className="w-6 h-6"
                          /></Tooltip>
                        );
                      } else {
                        return null;
                      }
                    })()}
                  </td>

                  <td className="py-2 ">
                    <img
                      src={rightArrow}
                      alt="Right Arrow"
                      className="w-6 h-6"
                    />
                  </td>
                </tr>
              ))
            ) : <>
              {isToSyc ? (
                <tr>
                  <td className="py-2 px-4" colSpan="5" style={{ textAlign: 'center' }}>

                    <button
                      className={`w-[200px] bg-[#F7BE38] text-black mt-5 h-10 text-sm text-center font-semibold rounded-full cursor-pointer`}
                      onClick={() => {
                        setIsbuttonclicked(true)
                        fetchEhr()
                      }

                      }   >
                      Sync Appointments
                    </button>
                    {/* <Button
        variant="contained"
        onClick={fetchEhr}
        sx={{
          background: "#2563eb",
          width: "200px", 
          margin: "0 auto", 
        }}
      >
        Sync Appointments
      </Button> */}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className="py-2 px-4" colSpan="5">
                    No Data Available
                  </td>
                </tr>
              )}

            </>


            }
          </tbody>

          {/* <tbody>
              <tr>
                <td className="py-2 px-4" colSpan="5">
                  No data available
                </td>
              </tr>
            </tbody> */}
        </table>
      </div>
    </div>
  );
};

export default ScribeTable;
