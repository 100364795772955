import { useState } from "react";
import ProfileDetails from "./manageprofile";
import UpdatePassword from "pages/auth/screens/updatepassword";
import PasswordUpdate from "./passwordupdate";
import MobileNumberUpdate from "./mobilenumberupdate";
import EmailUpdate from "./emailupdate";


const ManageProf = () => {
    const [screen, setScreen] = useState(0);
    const[username,setUsername]=useState('')
    const [email,setEmail]=useState('')
    const[mobilenumber,setMobilenumber]=useState('')
    const screenhandler=(label,name,mobilenumber,email)=>{
        setUsername(name)
        setMobilenumber(mobilenumber)
        setEmail(email)
if(label==='Current Password'){
    setScreen(1)
}else if(label==='Phone Number'){
    setScreen(2)
}else if(label==='Email'){
    setScreen(3)
}

    }

    const renderScreen = () => {
        switch (screen) {
            case 0:
                return <ProfileDetails screenhandler={screenhandler}/>;
                case 1:
                    return <PasswordUpdate username={username}email={email} mobilenumber={mobilenumber} setScreen={setScreen} screenhandler={screenhandler}/>;
                 case 2:
                return <MobileNumberUpdate username={username} mobilenumber={mobilenumber}  email={email} setScreen={setScreen} screenhandler={screenhandler}/>;
                case 3:
                    return <EmailUpdate username={username} email={email} setScreen={setScreen} mobilenumber={mobilenumber} screenhandler={screenhandler}/>;
                             
            default:
                return <ProfileDetails screenhandler={screenhandler} />;
        }
    };

    return (
        <>
            <div className="flex items-center justify-center min-h-[90vh] w-full">
                {renderScreen()}
            </div>
          
        </>
    );
};

export default ManageProf;
