// modalStore.js
import {create} from 'zustand';

const useModalStore = create((set) => ({
  isOpen: false,
  isCancel:false,
  isCombine:false,
  type: '',
  file: [],
  depId: null,
  patId: null,
  patName: '',
  patientname:"",
  appId: null,
  notes: '',
  practId: null,
  filename: '',
  rId: null,
  tags: [],
  isRunning:true,
  recordtime:"00:00",//actual record time
  isbacktorecord:false,
  continueSave:false,
  resrictNavgations:false, //used to restrict record audio while already recording in progress
  triggerHome:false, //used to go back through home icon in header
   ispaused:false,
   pausetime:'00:00', // when we go back from recording screen through pause audio time is captured in pause function 
   overlaypausetime:'00:00',// reverse above scenario
   overlayispaused:false,
   trigger:false, //used to call dashboarddata afterrecording
   usertype:null,
   //basic app
   isrecordingmodal:false,
   note:'',
   name:"",
  openModal: (payload) => set((state) => ({
    isOpen: true,
    ...payload,
  })),
  
  closeModal: () => set({ isOpen: false }),

  resetModal: () => {
    console.log("resetting")
    set({
    isOpen: false,
    // type: '',
    // file: null,
    // depId: null,
    // patId: null, 
    // appId: null,
    // notes: '',
    // practId: null,
    // rId: null,
    // tags: [],
    // isRunning:true,
    //  recordtime:"00:00"
  })},

  setModalValue: (updates) => set((state) => ({
    ...state,
    ...updates,
  })),
  
}));

export default useModalStore;
