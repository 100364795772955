import React, { useEffect, useState } from "react";
import api from "api/api";

const PurposeModal = ({ isPurposeModal,selectedTemplate, setPuroseModal, handleUpdate }) => {
  const [purposes, setPurposes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPurposes, setSelectedPurposes] = useState([]);
  const[defaults,setDefaults]=useState([])
  const getdata = async () => {
    try {
      setLoading(true);
      const user = await api.get('/auth/me')
      const response = await api.get("/v1/appointments/appointment-types");

      if (user && user.templates && user.templates.templates && Array.isArray(user.templates.templates)) {
 
        let idss = [];
    
        // Find the matching template and extract appointment_type_ids
        const matchingTemplate = user.templates.templates.find(item => item.template === selectedTemplate);

        if (matchingTemplate) {
            idss = matchingTemplate.appointment_type_ids;                   
            setSelectedPurposes(idss)
            setDefaults(idss)
        }   
     
    }   
      
      if (response.status === 200) {
        setPurposes(response.data);
      }
    } catch (error) {
      console.error("Error fetching purposes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isPurposeModal) {
      getdata();
    } else {
      // Reset states when modal is closed
      setPurposes([]);
      setLoading(true);
      setSelectedPurposes([]);

    }
  }, [isPurposeModal]);

  const togglePurpose = (index) => {
    setSelectedPurposes((prev) =>
      prev.includes(index) ? prev.filter((p) => p !== index) : [...prev, index]
    );
  };

  const handleUpdateWithIds = () => {
    // const selectedIds = selectedPurposes.map((index) => purposes[index].appointmenttypeid);
    // console.log(selectedIds)
    handleUpdate(selectedPurposes,[]);
    setSelectedPurposes([])
  };
useEffect(()=>{
console.log(selectedTemplate,'sele')
},[selectedTemplate])

  if (!isPurposeModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-4 relative">
        {/* Close Icon */}
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-black"
          onClick={() => setPuroseModal(false)}
        >
          ✕
        </button>

        <h2 className="text-xl font-bold mb-4">Select Purposes</h2>

        {loading ? (
          <div className="space-y-2">
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className="h-6 bg-gray-200 rounded animate-pulse"
              ></div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2">
          {purposes.map(({ appointmenttypename, appointmenttypeid }, index) => (
            <button
              key={index}
              className={`py-2 px-4 text-sm rounded min-h-[50px] ${
                selectedPurposes.includes(appointmenttypeid )
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => {             
                  togglePurpose(appointmenttypeid );                
              }}
            
            >
              {appointmenttypename}
            </button>
          ))}
        </div>
        
        )}
<button
  className={`flex items-center justify-center gap-2 w-full sm:w-full mt-2 h-10 text-white text-xs font-semibold rounded-lg ${
    !loading && selectedPurposes.length > 0
      ? 'bg-[#1C2A3A] hover:bg-[#173041]' // Active styles
      : 'bg-[#3E4A59] ' // Disabled styles with a distinct color
  }`}
  onClick={handleUpdateWithIds}
  // disabled={loading || selectedPurposes.length === 0}
>
  Update
</button>


      </div>
    </div>
  );
};

export default PurposeModal;
