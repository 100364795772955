import React, { useEffect, useState } from 'react';
import profilering from '../../assets/profilering.png';
import face from '../../assets/profile.png';
import { useNavigate } from 'react-router-dom';
import arrowleft from '../../assets/arrowleft.png';
import editIcon from '../../assets/edit.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IconButton, Snackbar, CircularProgress } from '@mui/material';
import api from '../../api/api';

const PasswordUpdate = ({ screenhandler, setScreen, username, mobilenumber,email }) => {
    const navigate = useNavigate();
    
    // State for password fields
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    // State for showing/hiding passwords
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const[isSaveDisabled,setIsSaveDisabled]=useState(false)
    // Loader and toast state
    const [loading, setLoading] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const handleSave = async () => {
        if (!currentPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword) {
            setToastMessage('Please fill in all fields and ensure passwords match.');
            setOpenToast(true);
            return; // Prevent save if validation fails
        }

        setLoading(true);
        try {
            const response = await api.put(`/v1/users/${mobilenumber}/password`, {
                "old_password": currentPassword,
                "new_password": newPassword
            });

            if (response.status === 200) {
                setToastMessage('Password updated successfully!');
            } else {
                setToastMessage(response?.message || 'Something went wrong');
            }
        } catch (error) {
            setToastMessage('Failed to update password. Please try again.');
        } finally {
            setLoading(false);
            setOpenToast(true);
            setTimeout(() => {
                setScreen(0);      
            }, 3000);
        }
    };

    const handleCloseToast = () => {
        setOpenToast(false);
    };
useEffect(()=>{
    if(!currentPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword){
        setIsSaveDisabled(true)
    }else{
        setIsSaveDisabled(false)
    }
},[currentPassword,newPassword,confirmPassword])


    return (
        <>
            <div className="w-full bg-white sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-0 md:p-6 sm:p-6 shadow-none sm:shadow-lg md:shadow-lg md:bg-white sm:bg-white h-screen sm:h-[500px] md:h-[600px] sm:rounded-lg md:rounded-lg">
                <div className='flex gap-8'>
                    <div className="flex items-start">
                        <img
                            onClick={() => setScreen(0)}
                            src={arrowleft}
                            alt="Back Arrow"
                            className="w-5 h-5 cursor-pointer"
                        />
                    </div>
                    <div className="flex items-center justify-start gap-2">
                        <div className="relative flex items-center justify-center">
                        <img src={profilering} alt="Profile Ring" className="w-[75px] h-[75px]" />
              <img src={face} alt="Face" className="absolute w-[60px] h-[61px] left-2 top-3 rounded-full" />
              {/* <IconButton className="absolute bottom-0 right-6 top-5">
                <img src={editIcon} alt="Edit" className="w-[20px] h-[20px]" />
              </IconButton> */}
                        </div>
                        <div className='flex flex-col text-start'>
                            <div className="font-semibold text-black text-lg">{username}</div>
                            <div className="opacity-50 text-black text-sm">{email||''}</div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col justify-around h-[80%]'>
                    <label className="font-semibold text-black">Change Password</label>
                    <div className='w-full flex flex-col items-center justify-center gap-6 mt-4'>
                        {/* Current Password Input */}
                        <div className='w-full flex flex-col items-center'>
                            <div className='flex items-start mb-2 w-full sm:w-[50%] md:w-[60%]'>
                                <label className="text-black">Current Password</label>
                            </div>
                            <div className='relative w-full md:w-[60%] sm:w-[60%]'>
                                <input
                                    type={showCurrentPassword ? "text" : "password"}
                                    placeholder="Current Password"
                                    className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                                <span
                                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                    className="absolute right-3 top-3 cursor-pointer"
                                >
                                    <FontAwesomeIcon icon={showCurrentPassword ? faEyeSlash : faEye} />
                                </span>
                            </div>
                        </div>
                        {/* New Password Input */}
                        <div className='w-full flex flex-col items-center'>
                            <div className='flex items-start mb-1 w-full sm:w-[50%] md:w-[60%]'>
                                <label className="text-black">New Password</label>
                            </div>
                            <div className='relative w-full md:w-[60%] sm:w-[60%] mt-2'>
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    placeholder="New Password"
                                    className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <span
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                    className="absolute right-3 top-3 cursor-pointer"
                                >
                                    <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
                                </span>
                            </div>
                        </div>
                        {/* Confirm Password Input */}
                        <div className='w-full flex flex-col items-center'>
                            <div className='flex items-start mb-1 w-full sm:w-[50%] md:w-[60%]'>
                                <label className="text-black">Re-type New Password</label>
                            </div>
                            <div className='relative w-full md:w-[60%] sm:w-[60%] mt-2'>
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Re-type New Password"
                                    className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <span
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    className="absolute right-3 top-3 cursor-pointer"
                                >
                                    <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Buttons for Cancel and Save */}
                    <div className='w-full flex items-center justify-center'>
                        <div className='flex w-[60%] items-center justify-around mt-6'>
                            <button 
                                onClick={() => { setScreen(0); }}
                                className="rounded-full w-[120px] bg-black text-white py-2 px-4 transition duration-300 ease-in-out hover:bg-gray-800">
                                Cancel
                            </button>
                            <button 
                                onClick={handleSave}
                                disabled={isSaveDisabled} // Disable if conditions are not met
                                className={`rounded-full w-[120px] bg-black text-white py-2 px-4 transition duration-300 ease-in-out ${isSaveDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-800'}`}>
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Toast Notification */}
            <Snackbar
                open={openToast}
                autoHideDuration={6000}
                onClose={handleCloseToast}
                message={toastMessage}
            />
        </>
    );
}

export default PasswordUpdate;
