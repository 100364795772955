import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import CloseIcon from '@mui/icons-material/Close';


const PdfView= ({  pdfBlob,}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const[loading,setLoading]=useState(false)


  const handleZoomIn = () => setZoomLevel(prev => prev + 0.1);
  const handleZoomOut = () => setZoomLevel(prev => Math.max(prev - 0.1, 0.1));

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };



  return (
  
  
      <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-3xl relative">
      
       

        <div className="p-4" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={zoomLevel}
                renderTextLayer={false} // Add this line to disable text rendering
              />
            ))}
          </Document>
        </div>
        
      </div>
     
    
  );
};

export default PdfView
