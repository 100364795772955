import { formatCurrentDate } from "utils/date";
import useModalStore from "zustand/zustandstore";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "api/api";

const Basicnav = () => {
  const { usertype, setModalValue } = useModalStore();
  const navigate = useNavigate();
  const plusFeatures = JSON.parse(process.env.REACT_APP_PLUS_FEATURES || '[]');

  const [showTemplateButton, setShowTemplateButton] = useState(false);
  const [isUserTypeLoading, setIsUserTypeLoading] = useState(true);

  // Fetch user type
  const fetchUser = async () => {
    try {
      setIsUserTypeLoading(true);
      const response = await api.get('/auth/me');
      if (response && response.config && response.config.user_type) {
        setModalValue({ usertype: response.config.user_type });
      }
    } catch (error) {
      console.error('Error fetching user type:', error);
      // Handle error appropriately, e.g., show a notification
    } finally {
      setIsUserTypeLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
      
    if ((usertype === 'PLUS'||usertype === 'BASIC') && plusFeatures.includes("TEMPLATES")) {
      setShowTemplateButton(true);
    } else {
      setShowTemplateButton(false);
    }
  }, [usertype, plusFeatures]);

  if (isUserTypeLoading) {
    return <div></div>; // Loading state while fetching
  }

  return (
    <div className="flex flex-col md:flex-row w-full items-center justify-between px-4 py-2">
      {/* Current Date */}
      <div className="text-base text-lg md:text-[20px] [font-family:'Poppins-Medium',Helvetica] font-normal text-black tracking-[0] leading-[normal] whitespace-nowrap mb-2 md:mb-0">
        {formatCurrentDate()}
      </div>

      {showTemplateButton && (
        <button
          onClick={() => { navigate('/temp') }}
          className="w-[90%] sm:w-[230px] md:w-[230px] h-[50px] sm:h-12 md:h-12 bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-medium text-base rounded-lg flex items-center justify-center shadow-md hover:shadow-lg transform hover:scale-105 transition-transform duration-200"
        >
          Manage Templates
        </button>
      )}
    </div>
  );
};

export default Basicnav;
